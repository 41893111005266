.welcomeBox {
  background: linear-gradient(91.22deg, rgba(19, 171, 227, 0.3) 0%, rgba(153, 204, 0, 0.3) 100%);
  padding: 1rem;
  border-radius: 0.5rem;

  position: relative;

  & p {
    font-size: 16px !important;
    line-height: 28px !important;
    margin: 0.5rem 0 !important;

    position: relative;
    z-index: 1;
  }
}

.illustrationWrapper {
  @media screen and (min-width: 975px) {
    height: 95%;
    width: 170px;
    position: absolute;
    bottom: 0;
    right: 0;

    overflow: hidden;

    &::after {
      content: '';
      background: url('https://scholamatch-static.s3.us-west-2.amazonaws.com/admin/dashboard-welcome.svg') no-repeat top
        center;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
    }
  }
}
