@use '../../../sass/themes';

.school-list--header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  h1 {
    font-weight: $medium;
    font-size: 24px;
    margin-bottom: 24px;
    @media (min-width: 7680px) {
      margin-bottom: 0;
    }
  }
  h3 {
    margin-bottom: 16px !important;
    font-size: 28px !important;
    font-weight: 700 !important;
  }

  .button {
    font-size: 14px;
    padding: 14px 14px;

    @media (min-width: 768px) {
      padding: 14px 24px;
    }
    .icon {
      margin-right: 3px;
    }
  }
}
.add-school-export {
  margin-bottom: 24px;

  .button {
    width: 250px;
    height: 38px;
    padding: 12.5px 14px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .button__children {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  label.dropdown {
    margin-bottom: 0;
  }
  .dropdown-select {
    height: 56px !important;
  }

  .button__children {
    line-height: unset;
  }
}
.school-list--search .text-input {
  width: 100%;
  height: 64px !important;
  padding: 16px;
  margin-bottom: 24px;
  font-size: 18px;
  border: none !important;
  border-radius: 2px;
  /* box-shadow: $card-box-shadow; */
}

.school-list--search .dropdown-select {
  width: 100%;
  height: 64px !important;
  padding: 16px;
  margin-bottom: 24px;
  font-size: 18px;
  border: none !important;
  border-radius: 2px;
  /* box-shadow: $card-box-shadow; */
}

.school-list-container {
  margin-top: 4rem;
  .schola-table {
    table-layout: auto;

    .permanentlyClosed {
      color: themes.$dangerous !important;
    }

    .permanentlyOpened {
      color: themes.$primary !important;
    }
  }
}

.school-list--table__edit {
  text-decoration: none;
  .icon {
    transition: all 0.25s ease-in-out;
  }
  &:hover {
    .icon {
      fill: themes.$primary;
    }
  }
}

.school-list--table__claimed {
  .icon {
    transition: fill 0.25s ease-in-out;
  }
  &:hover {
    .icon {
      fill: darken($primary, 10);
    }
  }
}
