.contentScroll {
  margin: 1rem auto;

  max-width: 95%;

  @media screen and (min-width: 767px) {
    max-width: 90%;
  }

  @media screen and (min-width: 1050px) {
    max-width: 1040px;
  }
}

.salesContainer {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-bottom: 7rem;

  & a {
    width: 100%;
  }

  & button {
    background: #006B8F !important;
    width: 100%;
  }
}

.salesBox {
  width: 100%;
  margin-top: 1rem;
  border: 1px solid #CEDFE4;
  border-radius: 8px;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  gap: 1rem;
  text-align: left;
}

.images {
  display: flex;
  align-items: center;

  img {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  img:nth-child(2) {
    margin-left: -1.5rem;
    margin-top: 4px;
  }
}

