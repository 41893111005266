.table {
  width: 100%;
  border-radius: 3.5px;
  border-spacing: 0;
  border-collapse: collapse;
  background-color: #fff;
}

.table th, .table td {
  color: #6C6D70;
  font-size: 12px;
  text-align: left;
  padding: 18px 25px;
}

.table th {
  font-weight: 600;
  text-transform: uppercase;
}

.table td {
  font-weight: 400;
  border-top: 1px solid #DDDFE7;
}

.table .table-cell-button {
  color: $secondary;
  font-size: inherit;
  font-weight: 600;
}

.dashboard-main-content .dashboard-panel.marketing-stats {
  text-align: center;
  margin-bottom: 40px;
}

.dashboard-main-content .dashboard-panel.marketing-stats .stat {
  margin-bottom: 15px;
}

.dashboard-main-content .dashboard-panel.marketing-stats .stat:last-child {
  margin-bottom: 0;
}

.dashboard-main-content .dashboard-panel.marketing-stats .stat .stat-number {
  color: #6c6d70;
  font-size: 30px;
  font-weight: 400;
  margin-top: 20px;
  display: block;
}

.dashboard-main-content .dashboard-panel.marketing-stats .stat .avg-review {
  width: 125px;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard-main-content .dashboard-panel .panel-label {
  color: #6c6d70;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin-bottom: 17px;
}

.dashboard-main-content .dashboard-panel .panel-label span {
  color: #94959a;
  font-size: 10.5px;
  font-weight: 400;
  text-transform: initial;
  margin-left: 6px;
  display: inline-block;
}

.dashboard-main-content .dashboard-panel.marketing-dashboard-panel {
  padding: 0;
  overflow: hidden;
}

.dashboard-main-content .dashboard-panel.marketing-dashboard-panel .marketing-section {
  padding: 15px 25px;
  border-bottom: 1.3px solid #dddfe7;
  position: relative;
}

.dashboard-main-content .dashboard-panel.marketing-dashboard-panel .marketing-section:last-child {
  border-bottom: none;
}

.dashboard-main-content .dashboard-panel.marketing-dashboard-panel .marketing-section .panel-label {
  margin-bottom: 0;
}

.dashboard-main-content .dashboard-panel.live-search-map .filter-controls .filter-controls-button .panel-label {
  margin-bottom: 0;
}

.dashboard-main-content .dashboard-panel.live-search-map .filter-controls .filter-controls-button .panel-label:before {
  content: '\f0b0';
  margin-right: 15px;
}

.dashboard-main-content .dashboard-panel.live-search-map .filter-controls .filter-controls-button .panel-label:after {
  content: '\f104';
  margin-top: 1px;
  float: right;
}

.dashboard-main-content .dashboard-panel.live-search-map .filter-controls .filter-controls-button.expanded .panel-label:after {
  content: '\f107';
}

.dashboard-main-content .dashboard-panel .dashboard-panel-table {
  margin-left: -25px;
  margin-right: -25px;
}

.dashboard-main-content .dashboard-panel .dashboard-panel-table .table thead th {
  font-weight: 500;
  text-transform: initial;
}

.dashboard-main-content .dashboard-panel .dashboard-panel.connections .tab-buttons {
  margin-bottom: 10px;
}

.dashboard-main-content .dashboard-panel .be-featured-school {
  text-align: center;
  margin-top: 40px;
  padding: 22.5px 20px;
  border-radius: 3.5px;
  background-color: #fff;
}

.dashboard-main-content .dashboard-panel .be-featured-school h2 {
  color: #0299d3;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
}

.dashboard-main-content .dashboard-panel .be-featured-school .description {
  color: #6c6d70;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 20px;
}

.dashboard-main-content .dashboard-panel .be-featured-school .price {
  color: #6c6d70;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.67px;
  margin-bottom: 25px;
}

.dashboard-main-content .dashboard-panel.live-search-map .filter-controls .filter-controls-button .panel-label:before, .dashboard-main-content .dashboard-panel.live-search-map .filter-controls .filter-controls-button .panel-label:after {
  content: '';
  color: #d8d8d8;
  font-family: 'FontAwesome';
  display: inline-block;
}

.dashboard-main-content .be-featured-school {
  text-align: center;
  margin-top: 40px;
  padding: 22.5px 20px;
  border-radius: 3.5px;
  background-color: #fff;
}

.dashboard-main-content .be-featured-school h2 {
  color: #0299D3;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
}

.dashboard-main-content .be-featured-school .description {
  color: #6C6D70;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 20px;
}

.dashboard-main-content .be-featured-school .price {
  color: #6C6D70;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.67px;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .dashboard-main-content .dashboard-panel.marketing-stats .stat {
    margin-bottom: 0;
  }

  .dashboard-main-content .be-featured-school {
    height: 340px;
    padding: 45px 20px;
    background-size: 110%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('https://s3-us-west-2.amazonaws.com/scholamatch-static/featured-school-bg.png');
  }

  .dashboard-main-content .be-featured-school h2 {
    font-size: 24px;
    margin-bottom: 25px;
  }

  .dashboard-main-content .be-featured-school .description {
    width: 515px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
  }

  .dashboard-main-content .be-featured-school .price {
    font-size: 30px;
    margin-bottom: 30px;
  }
}

.buttons-tours {
    display: flex !important;
    justify-content:flex-start;
    align-items:center;
    gap: 10px;
    min-height: 57px
}

@media (min-width: 992px) {
  .dashboard-main-content .dashboard-panel {
    padding: 25px;
  }
}

.cancel-tours {
  text-align: center;
  background-color: transparent;
  -moz-border-radius: 3.5px;
  -webkit-border-radius: 3.5px;
  border-radius: 3.5px;
  border: 1px solid #ff0000;
  display: inline-block;
  cursor: pointer;
  color: #ff0000;
  font-size: 10px;
  font-weight: bold;
  padding: 8px 25px;
  text-decoration: none;
  width: 145px;
  margin: 5px;

  &:hover {
    background-color: transparent;
  }

  &:active {
    position: relative;
    top: 1px;
  }
}


.complete-tours {
  text-align: center;
  background-color: #44c767;
  -moz-border-radius: 3.5px;
  -webkit-border-radius: 3.5px;
  border-radius: 3.5px;
  border: 1px solid #18ab29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  padding: 8px 25px;
  width: 145px;
  margin: 5px;
  text-decoration: none;

  &:hover {
    background-color: #5cbf2a;
  }

  &:active {
    position: relative;
    top: 1px;
  }
}
.headers-tours {
	background-color: transparent;
	-moz-border-radius: 20px;
	-webkit-border-radius: 20px;
	border-radius: 20px;
	display: inline-block;
	cursor: pointer;
	color: #808183;
	font-size: 10px;
	padding: 0px 0px;
	text-decoration: none;
	font-weight: 600;
	text-transform: uppercase;
	&:hover {
		background-color: transparent;
	}
	&:active {
		position: relative;
		top: 1px;
	}
}
.settings-dropdown {
	position: relative;
	display: inline-block;
	a {
		&:hover {
			background-color: #ddd;
		}
	}
}


.settings-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0px;

    a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    }
}

.settings-show {
  display: block;
}

.add-note-to-fill-mur {
  color: #0299D3;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.27px;
  line-height: 24px;
  text-align: center;
  padding: 10px 0;
  margin-bottom: 24px
}

.last-update-a-few-s {
  height3: 39px;
  width: 289px;
  color: #6C6D70;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: -0.27px;
  line-height: 20px;
  text-align: center;
}

.note-text-area {
  height: 100px;
  width: 100%;
  border: 0.7px solid #DDDFE7;
  border-radius: 3.5px;
  resize: none;
}


.bnt-cancel-note {
  height: 31px;
  width: 100px;
  color: #FF4949;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.4px;
  line-height: 40px;
  text-align: center;
}

.note-container {
	border: 2px solid #dedede;
	background-color: #f1f1f1;
	border-radius: 5px;
	padding: 10px;
	margin: 12px 0;
	&::after {
		content: "";
		clear: both;
		display: table;
	}
	img {
		float: left;
		max-width: 60px;
		width: 100%;
		margin-right: 20px;
		border-radius: 50%;
		&.right {
			float: right;
			margin-left: 20px;
			margin-right: 0;
		}
	}
}
.darker {
	border-color: #ccc;
	background-color: #ddd;
}
.note-time-right {
	float: right;
	color: #aaa;
	font-size: 10px;
}
.note-time-left {
	float: left;
	color: #999;
	font-size: 12px;
	margin-top: 10px;
	a {
		padding-right: 10px;
	}
}
.modal-confirm {
	button {
		margin-right: 15px;
	}
}
.modalWidth90 {
	width: 90% !important;
}
.archive-tours {
	background-color: transparent;
	-moz-border-radius: 3.5px;
	-webkit-border-radius: 3.5px;
	border-radius: 3.5px;
	border: 1px solid #000;
	display: inline-block;
	cursor: pointer;
	color: #000;
	font-size: 10px;
	font-weight: bold;
	padding: 10px 20px;
	text-decoration: none;
	&:hover {
		background-color: transparent;
	}
	&:active {
		position: relative;
		top: 1px;
	}
}
.archive-options {
	min-height: 42px;
	padding-left: 10px;
}
