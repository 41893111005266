.map-marker__wrapper {
	width: 30px;
	height: 30px;
	position: relative;
	.bubble-marker {
		top: -45px;
		right: -155px;
		z-index: 3;
		min-width: 228px;
		padding: 14px;
		position: absolute;
		border-radius: 2px;
		background-color: #fff;
		box-shadow: 0px 2px 6px rgba(101,107,118,0.2);
		&:after {
			content: '';
			top: 50%;
			right: 100%;
			height: 0;
			width: 0;
			position: absolute;
			border: solid transparent;
			border-right-color: #fff;
			border-width: 8px;
			margin-top: -8px;
		}
		p {
			margin-bottom: 5px;
			b {
				font-weight: 600;
			}
		}
		button {
			width: 100%;
			margin-top: 24px;
		}
		button[disabled] {
			cursor: not-allowed !important;
			opacity: 0.5;
		}
	}
}
