.over {
  height: 100%;
  position: absolute;
  top: 0;
  width: 97%;
  display: grid;
  place-items: center;
  font-size: 12px;
  font-weight: 700;
  color: #00000073;

  & span {
    background: #e6f6fa;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
}
