.select-applies-button {
  background: #fdfdfd;
  border: 1px solid #f5f7f9;
  box-sizing: border-box;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: 1rem;
  width: 176px;
  height: 152px;
  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 1.25rem;
    height: 180px;
  }
}
.button-add-another {
  color: $primary;
  font-size: 14px;
}
.button-remove-another {
  color: $secondary-red;
  font-size: 14px;
  margin-top: 30px;
}
.selected {
  background: #fdfdfd;
  // border: 1px solid $secondary !important;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 3.5px;
}
.form-group {
  label {
    color: #6c6d70;
    font-weight: 500;
    font-size: 12px;
  }
}
input[type='date'],
.dropdown-select {
  border: 1px solid #c6cad1 !important;
}
.form-check {
  .form-check-label {
    font-weight: 500;
    font-size: 13px;
    color: #44464b;
    padding-top: 4px;
  }
  input[type='radio'] {
    height: 15px !important;
    width: 20px;
  }
}
.checkbox-no-aplicable {
  input {
    margin-right: 1rem;
    margin-left: auto !important;
  }
  .form-check-label {
    padding-left: 1.5rem;
  }
}
.radio-check {
  padding: 0.25rem 1.25rem;
}
.button-save {
  width: 188px;
  height: 45px;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.button-remind-me-later {
  width: 188px;
  height: 45px;
  margin-right: 5px;
  background-color: $primary !important;
  @media (max-width: 767px) {
    width: 100%;
  }
}
textarea {
  font-size: 14px;
}
.button-let-us-show {
  width: 100%;
}
.modal-onboarding-confirm {
  .modal__content {
    width: 50% !important;
    .modal__header {
      justify-content: flex-end !important;
    }
    h2 {
      font-weight: bold;
      font-size: 24px;
    }
  }
}
#react-select-2--value {
  & > div {
    &.Select-value {
      left: 10px;
    }
  }
}
span {
  &.radio-label {
    top: -20px;
    position: relative;
  }
}
.errors-alerts-onboarding {
  margin: 1rem 0;
  background: #ffd5d5;
  border: 1px solid $secondary-red;
  padding: 1rem;
  span {
    margin-bottom: 0.5rem;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.check-item-label-onboarding {
  display: inline-flex !important;
}
.check-label-on {
  padding-left: 5px !important;
}
.modal-new-enrollment {
  .modal__content {
    width: 90% !important;
    border-radius: 4px;
    @media (min-width: 768px) {
      width: 700px !important;
    }
  }
  .modal__header {
    display: none;
  }
  .modal__children {
    padding: 0;
  }
  .modal-header {
    padding: 24px;
    border-bottom: 1px solid $fill-border;
  }
  .modal-footer {
    padding: 24px;
    border-top: 1px solid $fill-border;
  }
}
.marketing-efforts {
  input {
    color: #6e7b91;
    font-size: 0.875rem;
    width: 100%;
    height: 35px;
    padding: 0 10px;
    border-radius: 3.5px;
    background-color: #fff;
    border: 0.7px solid #dddfe7;
  }
}
