
.list__search{

 .list__container--main{
    padding: 0px 0px 25px 0px;
    display: flex;            
    display: -webkit-flex;
    flex-direction: column;     
    -webkit-flex-direction: column;
    flex-grow: 0;            
    -webkit-flex-grow: 0;

      .list--container{
          margin-top: 0px;

        .list--row{
            display: flex;           
            display: -webkit-flex;
            flex-direction: row;     
            -webkit-flex-direction: row;
            flex-grow: 0;            
            -webkit-flex-grow: 0;
            flex-wrap: wrap;         
            -webkit-flex-wrap: wrap;
            width: 100%;
            border-bottom: 1px solid #CFD8DC;
            border-collapse: collapse;
            color: $primary;
            padding-left: 32px;
            padding-right: 24px;
            
            &.header {  
              color: $schola-gray;
              padding-top: 0px;
              padding-bottom: 8px;
              opacity: 0.64;
              font-size: 12px;
              .list--cell{
                text-transform: capitalize;
                font-weight: $bold;
              }
            }

            &.data {
              padding-top: 16px;
              padding-bottom: 16px;

            }
        }

        .list--row--container{
              max-height: 285px;
              overflow-y: auto;
        }

        .list--cell {
            display: flex;
            flex-grow: 1;            
            -webkit-flex-grow: 1;
            text-overflow: ellipsis;
            padding-right: 20px;
            vertical-align: middle;
            justify-content: center;
            flex-direction: column;
            text-transform: uppercase;
            overflow-wrap: normal;
            line-height: 24px;
            font-size: 14px;
            font-weight: $medium;
            -webkit-flex:1;
            -webkit-box-flex:1;
            -moz-flex:1;
            -moz-box-flex:1;
            -ms-flex:1;
            flex:1;
            word-wrap: break-word;
            
            .button{
              min-height: 24px;
              padding: 0px;
              vertical-align: top;
              border-radius: 12px;
              text-transform: capitalize;
              font-weight: $bold;

            }

          &.location{
            max-width: 18%;
              span{
                padding-left: 3px;
              }
          }
          &.distance{
            max-width: 10%;
          }
          &.grade{
            max-width: 14%;
          }
          &.top-feature{
            max-width: 16%;
          }
          &.sub-feature{
            max-width: 16%;
          }
          &.connect{
            max-width: 14%;
          }
          &.price{
            max-width: 10%;
            color: #43A047;
            text-align: center;
          }
          &.lightgreen{
                border-left: 10px solid #90ee90;
          }
          &.green{
                border-left: 10px solid green;
          }          
          &.yellow{
            border-left: 10px solid yellow;
          }
          &.red{
            border-left: 10px solid red;
          }

        }
      }

      .no_results{
        text-align: center;
        padding: 10px;
      }
  }
}        

.circle-green{
    width: 15px;
    height: 15px;
    background-color: green;
    position: relative;
    top: 15px;
    border-radius: 10px;  
}
.circle-yellow{
    width: 15px;
    height: 15px;
    background-color: yellow;
    position: relative;
    top: 15px;
    border-radius: 10px;  
}
.circle-red{
    width: 15px;
    height: 15px;
    background-color: red;
    position: relative;
    top: 15px;
    border-radius: 10px;  
}
.circle-text{  
  margin-left: 25px;
  position: relative;
  top: -5px;
  }
.circle-text-header{  
  margin-left: 25px;
  position: relative;
  }  