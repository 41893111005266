.filter-list .filter__heading {
  border-bottom: 1px solid $schola-hr-color;
  padding: 8px 8px 8px 19px;
  display: flex;
}
.filter-list .filter__heading span:nth-child(1) {
  height: 24px;
  width: 24px;
  line-height: 24px;
  color: $primary;
}
.filter-list .filter__heading span:nth-child(2) {
  vertical-align: text-top;
  font-size: 14px;
  line-height: 24px;
  color: $schola-gray;
  font-weight: $bold;
  opacity: 0.72;
  margin-left: 11px;
}
.filter-list .filter__heading button {
  margin-left: auto;
  height: 24px;
  width: 24px;
  line-height: 24px;
  color: $schola-gray;
  margin-right: 8px;
}
.filter-list .filter__type {
  height: 200px;
  overflow-y: scroll;
  padding-top: 8px;
  border-bottom: 1px solid #d8dfe3;
}
.filter-list .filter__type .filter__type--title {
  font-size: 10px;
  color: $schola-gray;
  font-weight: $bold;
  padding: 8px 16px;
}
.filter-list .filter__type .filter__type--list {
  margin-top: 4px;
  margin-left: 24px;
  line-height: 24px;
  color: $schola-gray;
  font-size: 12px;
  font-weight: $bold;
  display: flex;
  flex-flow: column;
}
.filter-list .filter__type .filter__type--list .checkbox {
  line-height: 24px;
}
.filter-list .filter__type .filter__type--list .checkbox-label {
  padding: 4px 7px;
  opacity: 0.88;
}
.filter-list .filter__categories {
  height: 169px;
  overflow-y: scroll;
  padding-top: 8px;
}
.filter-list .filter__categories .filter__categories--title {
  font-size: 10px;
  color: $schola-gray;
  font-weight: $bold;
  padding: 8px 16px;
}
.filter-list .filter__categories .filter__categories--list {
  margin-top: 4px;
  margin-left: 24px;
  line-height: 24px;
  color: $schola-gray;
  font-size: 12px;
  font-weight: $bold;
  display: flex;
  flex-flow: column;
}
.filter-list .filter__categories .filter__categories--list .checkbox {
  line-height: 24px;
}
.filter-list .filter__categories .filter__categories--list .checkbox-label {
  padding: 4px 7px;
  opacity: 0.64;
}
.filter-list .filter__categories .filter__categories--list .subfeature {
  margin-left: 22px;
}
.filter-list .filter__categories .filter__categories--list .subfeature .checkbox-label {
  font-size: 10px;
}
.filter-list .slide-enter {
  transform: translateX(0%);
  transition: transform ease 250ms;
}
.filter-list .slide-enter-active {
  transform: translateX(0);
}
.filter-list .slide-leave {
  transform: translateX(100%);
}
.filter-list .slide-leave-active {
  transform: translateX(0%);
  transition: transform ease 250ms;
}
