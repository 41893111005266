.auth0-lock__container {
    width: 100%;
    height: 100vh;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#auth0-lock {
    box-shadow: $card-box-shadow;
    border-radius: $card-border-radius;
}
