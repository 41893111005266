.settings {
	background: #fff;
	.dashboard-main-content {
		padding: 0;
		width: 100%;
	}
	header {
		box-shadow: 2px 0 6px rgba(101,107,118,0.2);
	}
	h1 {
		&.dashboard-title {
			font-size: 28px;
			margin-bottom: 36px;
		}
	}
	.notification-main-content {
		display: flex;
	}
	.column-grey {
		margin-top: 0.3rem;
		.nav-option {
			padding: 10px 26px;
			font-weight: normal;
			color: #44464b;
			text-decoration: none;
			width: 100%;
			display: flex;
			&:hover {
				background: $fill-disabled;
			}
			.border-bottom-grey {
				padding-bottom: 20px;
			}
		} 
        .navitem {
            display: flex;
            align-items: center;
            &:hover {
                color: $primary;
                font-weight: 600;
            }
            .nav-icon { 
                margin-right: 12px;
                .personal-settings {
                    width: 15px;
                    height: 15px;
                    background: url("https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/icon-personal-settings.svg") no-repeat;
                    &:hover {
                        background: url("https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/notification-center/icon-personal-settings-blue.svg") no-repeat;
                    }
                }
                .manage-school {
                    width: 15px;
                    height: 15px;
                    background: url("https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/icon-manage-school.svg") no-repeat;
                }
                .user-management {
                    width: 15px;
                    height: 15px;
                    background: url("https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/icon-user-management.svg") no-repeat;
                }
                .icon-billing {
                    width: 15px;
                    height: 15px;
                    background: url("https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/icon-billing.svg") no-repeat;
                }
            }
        }
        
	}
    .notification-content {
        background: #fff;
        min-height: calc(100vh - 75px);
        padding: 24px;
        .notificacion-personal-menu {
            ul {
                border-bottom: 1px solid $fill-border;
                padding-bottom: 8px;
                padding-top: 8px;
                li {
                    display: block;
                    margin-bottom: 16px;
                    @media (min-width: 768px){
                        margin-right: 16px;
                        display: inline;
                        margin-bottom: 0;
                    }
                    a {
                        font-weight: 500;
                        text-decoration: none;
                        color: #80c9e6;
                        &.active {
                            color: $primary !important;
                        }
                        &:active {
                            color: $primary !important;
                        }
                    }
                }
            }
        }
        .dashboard-subtitle {
            h2 {
                font-size: 18px;
                font-weight: 600;
                color: $text-primary;
                margin: 24px 0;
            }
        }
        .notification-profile-user {
            display: flex;
            align-items: center;
            .notification-profile-image {
                margin-right: 16px;
                .profile-image {
                    border-top-left-radius: 50% 50%;
                    border-top-right-radius: 50% 50%;
                    border-bottom-right-radius: 50% 50%;
                    border-bottom-left-radius: 50% 50%;
                    width: 75px;
                    height: 75px;
                }
            }
        }
        .notificacion-personal-form,
        .notification-email,
        .notification-password {
            margin: 24px 0;
            padding-bottom: 36px;
            border-bottom: 1px solid $fill-border;
        }
        .button-password-content {
            margin: 24px 0;
        }
    }
    .user-management {
        .notification-content {
            width: 100%;
        }
        .dashboard-subtitle {
            h2 {
                margin-bottom: 8px;
            }
        }
        .school-users__form {
            margin: 24px;
        }
        .form-row {
            padding: 24px 0;
        }
        .tabs-container {
            box-shadow: none;
            padding: 24px 10px;
        }
    }
    .checkbox-column {
        text-align: left !important;
    }
    
}
