
header {
	position: fixed;
	height: 74px;
	background: #fff;
	z-index: 96;
	.navbar {
		.active {
			font-weight: 600;
			border-bottom: 2px solid $green-legacy;
			.nav-link {
				color: rgba(0,0,0,0.9);
			}
		}
		.notification {
			position: relative;
			.notification-red-circle {
				position: absolute;
				top: 8px;
				right: 9px;
			}
			li {
				font-size: 14px;
			}
		}
		a {
			color: #44464b;
		}
	}
	.navbar-v3 {
		display: flex;
		justify-content: space-between;
		margin-right: 20px;
		width: 100%;
		.header-navbarv3-logo-links {
			display: flex;
			align-items: center;
			justify-content: flex-start;
            @media (max-width:768px){
                width: 30%;
            }
		}
        .header-navbarv3-links-rightmenu {
            display: flex;
            justify-content: flex-end;
            @media (max-width:768px){
                width: 60%;
            }
            .nav-links {
                display: none;
                @media(min-width: 769px){
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
            }
            .mobile-menu {
                .mobile-menu-dropdown {
                    width: 300px;
                    background: #fff;
                    box-shadow: 0 4px 12px rgba(101,107,118,0.2);
                    padding: 16px 0;
                    position: fixed;
                    top: 75px;
                    border-radius: 3.5px;
                    z-index: 999;
                    @media (max-width: 768px){
                        width: calc(100%);
                        left: 1px;
                    }
                    .nav-links {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                    }
                    .nav-item {
                        padding: 1rem;
                    }
                }
            }
            button {
                .fa {
                    font-size: 24px;
                    color: rgba(0,0,0,0.5);
                }
            }
        }
        .nav-top-right-wrapper {
            width: 300px;
            background: #fff;
            box-shadow: 0 4px 12px rgba(101,107,118,0.2);
            padding: 16px 0;
            position: absolute;
            top: 55px;
            border-radius: 3.5px;
            z-index: 999;
            right: 35px;
            @media (max-width: 768px){
                width: calc(100%);
                left: 1px;
            }
            hr {
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
            }
            .user-info {
                padding: 16px 26px;
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                .user-image {
                    margin-right: 1rem;
                    .fa {
                        color: #d3d3d3;
                        font-size: 40px;
                    }
                    img {
                        overflow: hidden;
                        max-width: 32px;
                        max-height: 32px;
                        object-fit: cover;
                    }
                }
            }
            .user-image {
                margin-right: 10px;
            }
            .navitem {
                display: flex;
                align-items: center;
                padding: 10px 26px;
                &:hover {
                    background: #f9f9fb;
                    font-weight: 600;
                }
                .fa {
                    color: #adb1bd;
                    margin-right: 0.3rem;
                }
                .nav-icon {
                    margin-right: 12px;
                }
            }
            .nav-option {
                font-weight: 400;
                color: #44464b;
                text-decoration: none;
                width: 100%;
            }
        }
        .button-school-title {
            display: flex;
            align-items: center;
            &:focus {
                outline: none;
            }
            .fa {
                margin-left: 15px;
                margin-top: -10px;
                color: #adb1bd;
            }
        }
        
        
	}
}