.tableHeader {
  border-bottom: 1px solid #e5e7eb;
  background-color: #f9fafb;
}

.tableHeaderCell {
  text-align: left;
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
}
