.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.newValue {
  font-size: 26px;
  font-weight: 600;
}

.performance {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.positive {
  color: #389e0d;
}

.negative {
  color: #ee4266;
}

.dateInterval {
  color: #00000073;
}
