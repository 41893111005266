.timeline {
  height: 140px;
}

:global(.recharts-default-legend) {
  font-size: 12px;
  line-height: 1.2rem;
}

.container {
  position: relative;
}

.noDataLabel {
  margin-bottom: 3rem;
}
