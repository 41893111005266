@mixin grid {
  display: grid;
  gap: 0.5rem;
}

.wrapper {
  @media screen and (max-width: 767px) {
    width: 99%;
  }
}

.main {
  @include grid;
  padding: 0.5rem 0 0.5rem 0.5rem;

  @media screen and (min-width: 767px) {
    grid-template-columns: 70% 30%;
  }
}

.firstgrid {
  @include grid;
  margin: 0.5rem 0;
  grid-template-columns: 1fr 1fr 1fr 30%;
  grid-template-rows: 90px 80px 220px;

  @media screen and (min-width: 900px) {
    grid-template-rows: 90px 50px 220px;
  }

  .spot {
    grid-row: 1 / 3;
    grid-column: 4;
  }

  .pipeline {
    grid-column: 1 / 4;
    grid-row: 2 / 4;
  }

  .map {
    grid-column: 4;
  }
}

.sideLine {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
