.whitelabel-layout__logo-wrapper {
    background: $white;
    box-shadow: $card-box-shadow;
    padding-top: 8px;
    padding-bottom: 8px;

    .whitelabel-layout__logo {
        max-height: 68px;
    }
}

.whitelabel-layout__application-container {
    margin: 32px auto;
}

@media (max-width: $tablet-portrait) {
    .whitelabel-layout__application-container {
        .container {
            background: $white;
            padding: 0;
        }

        .paper {
            box-shadow: none;
        }
    }
}
