
.BlurData {
    position: relative;

    td {
        filter: blur(2px)
    }
    .checkbox-column, .tour_requested--padding, .menu-row-lead, td.warning-click-here{
        filter: blur(0)
    }
    .contact-name {
        filter: blur(2px)
    }
    .actions-points
    td.reach-button {
        filter: none;
    }

    .warning-click-here{
        position: absolute;
        left: 40%;
        margin-top: 15px;
        color: red;
        font-size: 1.5rem;
        font-weight: bold;
        z-index: 9999;
        display: none;

        @media (max-width: $screen-md) {
            top: 30%;
            left: 30%;
            padding-left: 0;
        }
        @media(max-width: $screen-sm) {
            left: 20%;
        }
        @media(max-width: $screen-xs) {
            left: 10%;
        }
    }
}

.school-leads__modal {
    .modal__content {
        overflow-y: unset !important;
    }
    h3 {
        margin-bottom: 12px;
    }

    p {
        margin-bottom: 24px;
    }
}