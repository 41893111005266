.grid {
  display: flex;
  gap: 1rem;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
}

.button {
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s;
  border: 1px solid #f0f0f0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  min-height: 228px;
  justify-content: flex-start;
}

.button:hover {
  background-color: #fafafa;
}

.title {
  font-size: 1rem;
  font-weight: 600;
}

.description {
  font-size: 0.75rem;
  color: #8c8c8c;
  text-align: center;
}
