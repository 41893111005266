.button {
  height: 40px;
  padding: 0.5rem 1rem;
  font-size: 14px;
  box-shadow: none;

  &:hover {
    background-color: darken(#006d8f, 5%);
  }
}
