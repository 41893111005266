@use '../../sass/color-palette' as palette;

$border-color: #e2e8f0;
$card-bg: #ffffff;
$card-foreground: #1a202c;
$muted-foreground: #64748b;
$shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
$border-radius: 0.5rem;
$padding: 1.5rem;

// Mixins para modo oscuro
@mixin darkMode {
  $border-color: #2d3748;
  $card-bg: #1a202c;
  $card-foreground: #f7fafc;
  $muted-foreground: #a0aec0;
}

.card {
  border-radius: $border-radius;
  border: 1px solid palette.$navy-blue-10;
  background-color: $card-bg;
  color: $card-foreground;
  box-shadow: $shadow-sm;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  padding: $padding;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.025em;
  color: $card-foreground;
}

.description {
  font-size: 0.875rem;
  color: $muted-foreground;
}

.content {
  padding: $padding;
  padding-top: 0;
}

.footer {
  display: flex;
  align-items: center;
  padding: $padding;
  padding-top: 0;
}

// Utilidades adicionales que podrían ser necesarias
.flex {
  display: flex;
}

.flexCol {
  flex-direction: column;
}

.itemsCenter {
  align-items: center;
}

// Composición de clases para casos específicos
.headerWithDescription {
  @extend .header;
  gap: 0.5rem;
}

.footerWithBorder {
  @extend .footer;
  border-top: 1px solid $border-color;
}
