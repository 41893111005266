.questionnaires {
	aside {
		.progress-aside {
			padding-top: 0.7rem !important;
			.profile-text {
				border-radius: 5px;
			}
			.square-color-gray-1 {
				color: #213556;
				background: rgba(0,140,255,0.05);
				border: 1px solid rgba(6,69,173,0.15);
				border-radius: 10px;
			}
		}
	}
	h2 {
		font-size: 20px !important;
	}
	p {
		font-size: 14px !important;
	}
	h6 {
		font-weight: 600;
		font-size: 1rem;
		line-height: 150%;
		text-align: center;
		vertical-align: middle;
	}
	.panel {
		min-height: 692px;
	}
	.main-content {
		.row {
			label {
				line-height: 17px;
			}
		}
	}
	.dashboard-panel {
		padding-left: 0;
		padding-right: 0;
		overflow-x: hidden;
	}
	.schedule-button {
		border: 1.5px solid #40afd9;
		box-sizing: border-box;
		border-radius: 10px;
		font-weight: 700;
		margin-bottom: 1rem !important;
		font-size: 14px;
		line-height: 150%;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 0.02em;
		color: #40afd9;
		border-radius: 3.5px;
		&.selected {
			background: #40afd9;
			border-radius: 10px;
			border: none;
			color: #fdfdfd;
			border-radius: 3.5px;
		}
		&.disable {
			color: #d3d3d3;
			background-color: #ebeff4;
			border-color: #ebeff4;
		}
	}
	.button-next {
		width: 188px;
		height: 45px;
	}
	.dropdown-select {
		border: 0.7px solid #dddfe7 !important;
		padding-left: 5px;
	}
	.status {
		align-items: center;
		.progress-content {
			width: 93%;
		}
	}
	.progress {
		height: 4px;
	}
	.progress-bar {
		background: #09c !important;
	}
	.recruiting-steps {
		display: flex;
		align-items: center;
		margin-bottom: 0.7rem;
        h6,
		span {
			background-color: #09c;
			color: #fff;
			padding: 0.3rem;
			border-radius: 100%;
			height: 35px;
			width: 35px;
			margin-right: 1rem;
		}
		p {
			&.font-13 {
				font-weight: 600;
				button {
					font-weight: 400;
				}
			}
		}
	}
	.step-complete {
        h6,
		span {
			background-color: $green;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 3px;
			i {
				color: #fff;
				margin: auto;
				width: 16px;
				margin-top: 6px;
			}
		}
	}
	.step-complete-inactive {
        h6,
		span {
			background-color: #ccc;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 3px;
			i {
				color: #fff;
				margin: auto;
				width: 16px;
				margin-top: 6px;
			}
		}
	}
	.disable-cursor {
        cursor: not-allowed !important;
    }
    .DayPicker {
        @media (max-width: 991px){
            display: flex;
            justify-content: center;
        }
    }
    .DayPicker-wrapper {
        border: 1px solid #efefef;
        border-radius: 10px;
    }
    .DayPicker-Caption {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
    }
    .DayPicker-Weekday {
        abbr[title] {
            color: $secondary !important;
            font-weight: 700;
        }
    }
    .DayPicker-Day--selected {
        &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
            background-color: $secondary;
        }
    }
    @media screen and ( min-width: 1200px ) {
        .DayPicker-Weekday {
            padding: 1rem;
        }
        .DayPicker-Day {
            padding: 1rem !important;
        }
    }
    .selected-dates {
        .form-check-box {
            .form-check-input {
                margin-top: 0;
                position: unset;
            }
        }
    }
    select {
        &.morningStart {
            padding-left: 5px !important;
            padding-right: 5px !important;
        }
    }
    
}
.addDeleteItemsEnrollmentList {
	color: $primary !important;
	margin-right: 11px;
	cursor: pointer;
}
.day-tour-time {
	cursor: pointer;
}
.delete-image-mk {
	color: $secondary;
	float: right;
	cursor: pointer;
}
.container-loading-master {
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	.loading {
		display: flex;
		flex-direction: row;
		.loading__letter {
			font-weight: normal;
			letter-spacing: 4px;
			text-transform: uppercase;
			animation-name: bounce;
			animation-duration: 2s;
			animation-iteration-count: infinite;
			&:nth-child(2) {
				animation-delay: 0.1s;
			}
			&:nth-child(3) {
				animation-delay: 0.2s;
			}
			&:nth-child(4) {
				animation-delay: 0.3s;
			}
			&:nth-child(5) {
				animation-delay: 0.4s;
			}
			&:nth-child(6) {
				animation-delay: 0.5s;
			}
			&:nth-child(7) {
				animation-delay: 0.6s;
			}
			&:nth-child(8) {
				animation-delay: 0.8s;
			}
			&:nth-child(9) {
				animation-delay: 1s;
			}
			&:nth-child(10) {
				animation-delay: 1.2s;
			}
		}
	}
}
@keyframes bounce{
    0%{
        transform: translateY(0px);
    }
    40%{
        transform: translateY(-25px);
    }
    80%,
    100%{
        transform: translateY(0px)
    }
}

.mk-materials-images {
	width: 90%;
}
.inputfile-mk-details {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
label {
	.radio-tempp-1 {
		width: 20px !important;
		height: 13px !important;
	}
}
.switch {
	input {
		&:checked {
			& + .slider {
				background: $primary !important;
			}
		}
	}
}
.summaryBox-plan,
.button-large-secondary,
.checkoutLayout-payment,
.orderSummary,
.toggleContainer .label-true {
	color: #4a4a4a !important;
}
