.layoutv3 {
  .campaign-page {
    padding: 1rem 1.5rem;
  }
}
.campaign-table {
  .table-responsive {
    overflow-x: auto;
    overflow-y: auto;
    /* @media (min-width: 1024px) {
      overflow-x: visible;
      overflow-y: visible;
    } */
    .table-leads {
      min-width: 1200px;
      width: 100%;
    }
  }
}
.main-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.email-basics-connect {
  .dashboard-main-content-v2 {
    .head-title {
      margin-bottom: 3px !important;
      background: #fff;
      box-shadow: 2px 0px 6px rgba(101, 107, 118, 0.2);
      padding: 24px;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    h1 {
      &.dashboard-title {
        margin-bottom: 0;
      }
    }
  }
  .head-title-search {
    display: flex;
    flex-direction: column-reverse;
    @media (min-width: 1440px) {
      align-items: center;
      flex-direction: row;
      font-size: 20px;
    }
  }
  .head-buttons-content,
  .head-buttons {
    align-items: center;
    button {
      min-width: 62px;
      &.font-white {
        color: #fff !important;
      }
    }
    @media (max-width: 767px) {
      margin-top: 24px;
    }
  }
  .breadcrumbs {
    display: flex;
    @media (min-width: 1440px) {
      margin-top: 0.5rem;
      margin-left: 0.5rem;
    }
  }
  .head-buttons-content {
    display: flex;
  }
  .content-bullet-email {
    .panel {
      .input-group {
        .input-group-append {
          @media (max-width: 480px) {
            flex-direction: column;
          }
        }
      }
    }
  }
}
.data-content-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.campagins-report {
  .live-head-title {
    display: block !important;
  }
}
.campaign-data {
  width: 100%;
  padding: 16px;
  display: grid;
  grid-template-columns: 14.5% 14.5% 14.5% 14.5% 14.5% 14.5% 14.5%;
  @media (max-width: 480px) {
    grid-template-columns: auto auto;
  }
  @media (min-width: 481px) and (max-width: 1023px) {
    grid-template-columns: auto auto auto;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (min-width: 1201px) {
    flex-direction: row;
  }
}
.data-info {
  border-bottom: 1px solid $fill-border;
  padding: 16px;
  position: relative;
  &:last-child {
    border-bottom: none;
  }
  .icon-edit-avg {
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;
    i {
      color: $text-placeholder;
      position: absolute;
      top: 3px;
      right: 13px;
    }
  }
  @media (max-width: 767px) {
    width: 50%;
    margin-bottom: 24px;
  }

  @media (min-width: 768px) {
    border-right: 1px solid $fill-border;
    border-bottom: none;
    padding: 0;
    &:last-child {
      border-right: none;
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    margin: 8px 0;
  }
}
.campaign-panel {
  box-shadow: 0px 2px 6px rgba(85, 106, 113, 0.15);
  border-radius: 4px;
  background: #fff;
  min-height: 100px;
}
.campaigns-columns-details {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
  }
}
.campaigns-details-left-content {
  padding: 24px;
  width: 100%;
  margin-bottom: 24px;
  @media (min-width: 768px) {
    width: 40%;
    margin-bottom: 0;
  }
}
.campaigns-details-right-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 768px) {
    margin-left: 24px;
    width: 100%;
  }
  .add-impact-lead-distribution {
    display: flex;
    margin-bottom: 24px;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
    }
    .add-impact {
      width: 100%;
      padding: 24px;
      margin-bottom: 24px;
      @media (min-width: 768px) {
        margin-bottom: 0;
        margin-right: 12px;
        width: 30%;
      }
    }
    .lead-distribution {
      width: 100%;
      padding: 24px;
      @media (min-width: 768px) {
        margin-left: 12px;
        width: 70%;
      }
    }
    .map-live-parent {
      position: relative;
      button {
        position: absolute;
        top: 40%;
        left: 40%;
      }
    }
  }
}
.compaings-details-dates {
  width: 40%;
  margin-right: 24px;
}
.compaings-details-daysleft {
  width: 40%;
}
.scholaboots-content {
  .scholaboots-image {
    min-height: 215px;
    background: #e4f4fa;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .scholaboots-title {
    h6 {
      margin-top: 16px;
      margin-bottom: 8px;
    }
  }
}
.chart-demographic {
  height: 100%;
  padding: 24px;
}
.input-buttons-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .week-select {
    width: 100%;
    @media (min-width: 768px) {
      width: 50%;
    }
    @media (min-width: 900px) {
      width: 300px;
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.calendly-call-modal,
.delete-confirmation-modal,
.new-campaign-modal,
.personal-settings-report-modal,
.campaigns-report-modal,
.campaigns-application-received-modal,
.purchase-marketing-spend-modal,
.modal-avgperpupil-edit,
.campaigns-purchase-scholaboots-modal,
.modal-change-email-layout,
.modal-confirm-create-text-message-based,
.duplicate-campaign-modal,
.email-layouts-modal {
  .modal__content {
    width: 90% !important;
    border-radius: 4px;
  }
  .modal__header {
    display: none;
  }
  .modal__children {
    padding: 0;
  }
  .modal-header {
    padding: 24px;
    border-bottom: 1px solid $fill-border;
  }
  .modal-footer {
    padding: 24px;
    border-top: 1px solid $fill-border;
  }
  .content {
    padding: 10px;
    .ad-impact-about {
      &.title {
        padding: 10px 35px 10px 25px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
      &.content {
        padding: 10px 35px 10px 25px;
        .ad-impact-about {
          &.subtitle {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #44464b;
            border-radius: 3.5px;
          }
          &.info {
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #44464b;
            border-radius: 3.5px;
          }
        }
      }
    }
  }
}
.modal-edit-email-notification,
.delete-confirmation-modal,
.sms-layouts-modal {
  .modal__content {
    max-width: 450px !important;
  }
  .modal__header {
    justify-content: space-between;
  }
  .modal__title {
    color: $schola-gray;
    margin-left: 0;
  }
  .content {
    padding: 1rem;
  }
  .modal__children {
    padding: 0;
  }
  .modal__overlay {
    width: 100%;
  }
}
.modal-confirm-create-text-message-based {
  .modal-header {
    display: flex;
    justify-content: center;
    padding: 0;
    padding-top: 27px;
    border-bottom: 0;
    background: #f2fbff;
  }
  .content {
    padding: 36px;
    padding-bottom: 0;
    .buttons-confirm {
      margin: 36px 0;
    }
  }
}
.campaigns-application-received-modal,
.purchase-marketing-spend-modal,
.modal-avgperpupil-edit,
.campaigns-purchase-scholaboots-modal,
.modal-change-email-layout {
  .modal-body {
    padding: 24px;
    padding-bottom: 0;
    .form-check-inline {
      align-items: unset;
      input {
        margin-top: 0.2rem;
      }
    }
    .form-group {
      margin-bottom: 0;
    }
  }
  .modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    padding-top: 12px;
  }
}
.modal-avgperpupil-edit,
.purchase-marketing-spend-modal,
.campaigns-purchase-scholaboots-modal,
.duplicate-campaign-modal {
  .content {
    padding: 24px;
  }
}
.email-layouts-modal {
  .modal__content {
    @media (min-width: 1024px) {
      width: 760px !important;
    }
  }
}

.duplicate-campaign-modal {
  .modal__content {
    @media (min-width: 1024px) {
      width: 512px !important;
    }
  }
  .content {
    height: 100%;
  }
  .modal-footer {
    padding: 18px;
    justify-content: flex-end !important;
  }
}
.email-layouts-modal {
  .content {
    overflow: hidden;
    overflow-y: scroll;
  }
}
.modal-change-email-layout {
  .modal__content {
    width: 90% !important;
    @media (min-width: 1024px) {
      width: 936px;
    }
    .content {
      height: 100%;
    }
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
  }
  .modal-footer {
    box-shadow: none;
  }
}
.other-campaign-modal {
  .react-datepicker__input-container,
  .react-datepicker-wrapper {
    width: 100%;
    display: block;
  }
  .content {
    height: 100%;
  }
  .modal__content {
    overflow: unset;
    .datepicker-end-date {
      .react-datepicker-popper {
        @media (max-width: $screen-xs) {
          left: unset !important;
          right: 0 !important;
        }
      }
    }
  }
  .modal__overlay {
    width: 100%;
  }
}
.compaings-weekly-analysis {
  width: 100%;
}
.ad-example {
  content {
    .button {
      border: 1px solid $primary;
      color: $primary;
      font-size: 14px;
      text-transform: capitalize;
      margin-top: 1rem;
    }
  }
}
.analysis-title {
  width: 100%;
  i {
    cursor: pointer;
    color: $text-placeholder;
    &:hover {
      color: $secondary;
    }
  }
}
.analysis {
  pre {
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-x: hidden;
    overflow-y: hidden;
  }
}
.table-content {
  .data-content-empty {
    display: flex;
    flex-direction: column;
    min-height: 567px;
  }
}
.table-leads {
  color: $text-primary;
}
.status-tag {
  padding: 6px 8px;
  color: #8a8a8a;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  border-radius: 2px;
  &.active {
    color: $secondary-blue;
    background: #e5ecfd;
  }
  &.sent {
    color: #29a37e;
    background: #eaf6f3;
  }
  &.draft {
    color: $secondary-teal;
    background: rgba(93, 205, 211, 0.1);
  }
  &.inqueue {
    color: #8e44ad;
    background: #f6f0f9;
  }
  &.archived {
    color: #adb1bd;
    background: #f6f6f8;
  }
  &.complete {
    color: $secondary;
    background: #eaf6f3;
  }
  &.deleted {
    color: $primary-red;
    background: #ffe9e1;
  }
  &.error {
    color: #f00;
    background: #fc0;
  }
  &.paused,
  &.invitepending {
    color: #ffb03f;
    background: #fff3e2;
  }
  &.budgetreached,
  &.finished {
    color: $secondary;
    background: #edf7e6;
  }
  &.noaction,
  &.inactive {
    color: $text-placeholder;
    background: #f6f6f8;
  }
}
.campaign-total {
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  padding: 2px 5px !important;
}

.camgaign-email-total-active,
.camgaign-facebook-total-active,
.camgaign-scholamkt-total-active {
  background: $primary;
  border: none;
  color: #fff;
}
.camgaign-email-total,
.camgaign-facebook-total,
.camgaign-scholamkt-total {
  background: transparent;
  color: $text-primary;
  border: 1px solid $primary;
}
.modal-create-import-campaigns {
  i {
    &.fa-chevron-right {
      font-size: 11px;
      color: #adb1bd;
    }
  }
  .modal-header {
    justify-content: space-between;
    padding: 16px;
    align-items: center;
    .header-title-breadcrums {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 95%;
    }
    .header-close-icon {
      display: flex;
      justify-content: flex-end;
      width: 5%;
    }
  }
  .content {
    padding: 24px;
    min-height: 375px !important;
    @media (min-width: 768px) {
      &.step01,
      &.step03,
      &.step-schedule-confirm {
        flex-direction: column;
      }
    }
    &.step02 {
      flex-direction: row;
      padding: 0;
    }
  }
  .modal-aside {
    justify-content: flex-start;
    min-width: 192px;
  }
  .modal-main-content {
    .custom {
      display: flex;
      border: 1px solid #dfe4ea;
      border-radius: 4px;
      margin-top: 12px;
      margin-bottom: 36px;
      &.templates {
        margin-bottom: 12px;
        background: #fff;
        border: 1px solid #dfe4ea;
        cursor: pointer;
      }
      &.selected {
        background: #f9fdff;
        box-sizing: border-box;
        border: 1px solid #b3dff0 !important;
      }
      .custom-text {
        padding: 24px 24px 24px 12px;
      }
      .custom-img {
        padding: 24px 24px 12px 24px;
        display: flex;
        align-items: center;
      }
    }
    .modal-footer {
      padding: 16px;
    }
  }
}
input:not([type='checkbox']).schola-inputs,
select.schola-inputs {
  font-family: $sans;
  font-weight: 400;
  font-size: 14px !important;
}
.select-add-campaign,
.select-add-campaign-selected,
.select-create-new-campaign {
  min-height: 106px;
  padding: 24px;
  border: 1px solid #dfe4ea;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.vendor-logo-add-campaign {
  display: flex;
  align-items: center;
  img {
    max-width: 45px;
  }
}
.vendor-text-add-campaign {
  width: 90%;
}
.select-add-campaign-selected {
  border: 1px solid $primary;
}
.icon-selected {
  display: flex;
  align-items: center;
}
.modal-footer {
  justify-content: space-between !important;
  border: none;
  box-shadow: 2px 0px 6px rgba(101, 107, 118, 0.2);
}
.campiagn-main-content {
  margin: 0 auto;
  width: 100%;
  @media (min-width: 768px) {
    width: 700px;
  }

  .panel {
    box-shadow: none !important;
    margin-bottom: 2rem;
  }
  .email-main-content {
    border: 1px solid #e2e8f0;
    background: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    margin-top: 2rem;
    .input-group,
    .form-row {
      margin-top: 16px;
    }
    .gridColumn {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 0.5rem;
      align-items: end;
    }
    .controlBar {
      position: relative;
    }
    .form-group {
      margin-bottom: 0;
    }
    .dropdown-toggle {
      background: #f5f7f9;
      border: 1px solid #d1d4de;
      box-sizing: border-box;
      border-radius: 0px 3.5px 3.5px 0px;
      max-height: 35px;
      font-size: 12px;
    }
    .email-content {
      &.confirm-details {
        .panel {
          border-top: 8px solid $primary;
        }
      }
    }
  }
}
.fill-blue-upgrade-required {
  background: #f9fdff;
  border: 1px solid #b3dff0;
  border-radius: 4px;
  padding: 16px;
}
.form-control {
  font-size: 0.875rem;
}
.school-logo-content {
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  .school-logo-image-content {
    width: 100px;
  }
  .school-image-campaing-content {
    border: 1px dashed #d1d4de;
    box-sizing: border-box;
    border-radius: 4px;
    height: 97px;
    display: flex;
    justify-content: center;
  }
  .school-logo-image-content {
    @media (max-width: 480px) {
      width: 100%;
      height: 200px;
      margin-bottom: 16px;
    }
  }
  .school-image-campaing-content {
    @media (min-width: 768px) {
      height: 221px;
      width: 306px;
    }
  }
  .school-image-campaign,
  .school-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .school-image-campaign {
    img {
      @media (max-width: 768px) {
        width: 100% !important;
      }
    }
  }
  .school-logo {
    min-width: 100px;
    background: #fff;
    padding: 5px;
    border: 1px dashed #d1d4de;
  }
  .school-logo-actions {
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media (min-width: 768px) {
      margin-left: 0;
    }
    .school-logo-buttons {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      @media (max-width: 480px) {
        flex-direction: column;
      }

      .validation-message__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
      }

      .remove-text-logo,
      .remove-text-image {
        margin-top: 12px;
      }
      .remove-text-image {
        margin-left: -16px;
      }
      .dropzoneTwo {
        margin-top: 1rem;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      .dropzone {
        @media (max-width: 768px) {
          min-width: 100% !important;
        }
      }
    }
  }
}
.checklist-confirm-content {
  p {
    font-size: 14px;
    margin-bottom: 8px;
  }
  i {
    margin-right: 10px;
    font-size: 18px;
    &.fa-check {
      color: #29a37e;
    }
    &.fa-question {
      color: #ff5757;
    }
  }
}
.checklist-upgrade-required-content {
  i {
    margin-right: 10px;
    font-size: 18px;
    &.fa-check {
      color: #d3d3d3;
    }
  }
}
.select-schedule {
  width: 94px;
  margin-right: 12px;
}
.form-check {
  &.disabled {
    margin-top: 16px;
    label {
      color: #adb1bd !important;
    }
  }
}
.school-logo-preview {
  padding: 11px 0;
  display: flex;
  justify-content: center;
}
.content-bullet-email,
.content-bullet-message,
.content-timeline {
  position: relative;
  display: flex;
  justify-content: center;
  &::before {
    content: '';
    position: absolute;
    top: 16px;
    left: 4%;
    height: 100%;
    width: 4px;
    z-index: 0;
    background: #dfe4ea;
    @media (min-width: 768px) {
      left: 4.9%;
    }
  }
  .timeline {
    display: flex;
    justify-content: center;
    width: 10%;
    i {
      text-align: center;
    }
    &.fill-border-divider {
      i {
        color: #dfe4ea;
      }
    }
    &.fill-secondary-green {
      i {
        color: #29a37e;
      }
    }
  }
  .panel {
    width: 90%;
  }
}
.content-timeline {
  justify-content: flex-start;
  margin-top: 10px;
  &::before {
    top: 24px;
    left: 6%;
    height: 60%;
    width: 1px;
    @media (min-width: 768px) {
      left: 11px;
    }
  }
  .timeline {
    width: 26px;
  }
  .timeline-circle {
    width: 22px;
    height: 22px;
    border: 1px solid #dfe4ea;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 10px;
    }
  }
}
.email-sidebar {
  width: 100%;
  padding: 24px;
  border-top: 8px solid $primary;
  .email-preview-content {
    background: #f9f9fb;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .email-image-preview-content {
      min-width: 100%;
      min-height: 100%;
      padding: 16px;
      background: #fff;
      border: 1px solid #dfe4ea;
      border-radius: 4px;
      &.email-content {
        min-height: unset;
        height: 675px;
        overflow: hidden;
        overflow-y: auto;
      }
      .email-image-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: 1130px) {
          width: 300px;
          min-height: 140px;
        }
        .email-image {
          img {
            @media (max-width: 480px) {
              width: 100%;
            }
          }
        }
      }
      .email-text-preview {
        padding: 16px;
        width: 290px;
      }
      .email-text-body-preview {
        width: 375px !important;
        overflow-wrap: break-word;
      }
    }
  }
}
.color-ellipse {
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 1;
  background-color: #d1d4de;
  border-radius: 50%;
  left: 4px;
}
.preview-footer {
  background-color: $primary;
  min-height: 60px;
  color: #fff;
}
.layout-frame {
  width: 207px;
  height: 278px;
  position: static;
  margin: 0 10px;
  .layout-icon {
    position: relative;
    width: 207px;
    height: 208px;
    background: #f5f7f9;
    border: 1px solid #dfe4ea;
    margin-bottom: 12px;
    padding-top: 14px;
    .icon-check-layout {
      position: absolute;
      right: 12px;
      top: 12px;
      font-size: 18px;
    }
    &.selected {
      border: 1px solid #0074cd !important;
    }
  }
  .layout-description {
    width: 207px;
    height: 58px;
  }
}
.select-layout {
  cursor: pointer;
}
.calendly-call-modal {
  .modal__content {
    width: 90% !important;
    border-radius: 4px;
    @media (min-width: 768px) {
      width: 750px !important;
    }
  }
  .modal__children {
    padding: 0;
  }
}
.mobile-preview {
  background-image: url('https://scholamatch-static.s3-us-west-2.amazonaws.com/email-campaing/132561399_720972172165301_658863699655219332_n.png');
  background-position: center;
  background-repeat: no-repeat;
  min-height: 350px;
  width: 285px;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
}
.mobile-preview-container {
  height: 233px;
  width: 205px;
  position: absolute;
  top: 115px;
  left: 45px;
  overflow: hidden;
  overflow-y: auto;
}
.sms-from {
  position: absolute;
  width: 180px;
  background-color: #f8f8f8;
  text-align: center;
  left: 55px;
  top: 95px;
}
.sms-body {
  background-color: #e4e3e9;
  position: absolute;
  width: 150px;
  left: 0;
  top: 45px;
  border-radius: 0.7em;
  &:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-top-color: #e4e3e9;
    border-bottom: 0;
    border-left: 0;
    margin-left: -71.5px;
    margin-bottom: -10px;
  }
}
.schola-switch {
  .react-switch-bg {
    width: 42px !important;
    height: 24px !important;
    :first-child {
      width: 21px !important;
      height: 24px !important;
    }
    div {
      height: 24px !important;
    }
    svg {
      display: none;
    }
  }
}
.scholaboots-title {
  .text-secondary {
    a {
      color: $secondary;
    }
  }
}
.campaigns-purchase-scholaboots-modal {
  &.p-0 {
    .modal__children {
      padding: 0 !important;
    }
  }
}
/* .send-test-button {
  @media (min-width: 768px) and (max-width: 1169px) {
    padding: 0.25rem 1rem;
  }
} */

.container-scholamarketing {
  max-width: 1000px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between; /* This will put the space between the text and the image */
  flex-wrap: wrap; /* This allows the items to wrap in smaller screens */

  .text-content {
    flex: 1; /* This allows the text container to grow */
    min-width: 300px; /* Minimum width before wrapping */
  }

  h1 {
    color: #333;
    font-size: 2em;
    font-weight: bold;
  }
  p {
    color: #666;
    font-size: 1em;
  }
  a {
    color: #0066cc;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  .robot-image {
    transform: scaleX(-1);
    max-width: 400px;
    width: -webkit-fill-available;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    user-select: none;
  }
}

.triggerSection {
  margin: 2.5rem 0;
}

.select-aspect-ratio {
  width: 250px;
  margin-top: 1rem;
  text-align: center;
}
