@import '../../../sass/color-palette';

.text {
    display: block;
    width: 100%;
}

.text-label {
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
}

.text-input,
.text-area,
#claim-school--autocomplete {
    display: block;
    font-size: 16px;
    line-height: 20px;
    padding: 13px 16px;
    appearance: none;
}

.text-area {
    min-height: 200px;
    resize: vertical;
}

// Disabled cursor on parent label and all children elements
.text--disabled,
.text--disabled * {
    cursor: not-allowed !important;
    opacity: 0.75;
}

.text--disabled .text-input,
.text--disabled .text-area {
    background: $navy-blue-25;
}

input[type="text"],
input[type="url"],
input[type="number"],
input[type="date"],
input[type="email"],
input[type="tel"],
input[type="password"],
textarea {  
	color: $dark-blue-65;
	font-size: .875rem;
	width: 100%;
	height: 35px;
	padding: 0 10px;
	border-radius: 3.5px;
	background-color: #fff;
	border: 0.7px solid #dddfe7;

  &:focus {
    outline: none;
    border-radius: 3px
}

}

input[type="text"].transparent,
select.transparent,
textarea.transparent {
	background-color: transparent;
}

  textarea {
	height: auto;
	min-height: 50px;
	padding-top: 10px;
	padding-bottom: 10px;
	resize: vertical;
  }
