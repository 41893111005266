// layout
.layout {
  margin-top: 2rem;
  margin-left: 1.5rem;
}

// tab styles
.tabsList {
  margin-top: 1.5rem;
}

.tabTrigger {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1.5rem;
}

.icon {
  font-size: 1.25rem;
  width: 1.2rem;
  height: 1.2rem;
}
