.upgrade-schola-plan-modal {
	.modal__content {
		width: 70% !important;
		height: 80%;
	}
	.modal {
		&.modal--visible {
			z-index: 9999;
		}
	}
	.modal__header {
		font-weight: bold;
		font-size: 24px;
		line-height: 150%;
		margin-bottom: 0.5rem;
		justify-content: space-between;
		.modal__title {
			color: $schola-gray;
			font-size: 1.63rem;
			font-weight: 700;
			font-family: $montserrat;
		}
	}
	.modal__children {
		padding-bottom: 0;
		.plan-container {
			background: #fdfdfd;
			border: 1px solid #ebeff4;
			box-shadow: 0 1px 5px rgba(0,0,0,0.1);
			border-radius: 3.5px;
			padding: 1rem 2rem;
			min-height: 406px;
			cursor: pointer;
			width: 33%;
			position: relative;
			.plan-price {
				font-weight: 500;
				font-size: 16px;
			}
			.plan-name {
				font-weight: bold;
				font-size: 18px;
				line-height: 150%;
				color: $primary;
				margin-bottom: 2rem;
			}
			.plan-modal-description {
				color: #91949f;
				text-align: center;
			}
			.plan-message {
				font-weight: 500;
				font-size: 12px;
				line-height: 130%;
				background-color: #ebeff4;
				min-height: 31px;
				margin-top: 1rem;
				color: #91949f;
			}
			.plan-plus {
				font-weight: 500;
				font-size: 10px;
				line-height: 130%;
				color: #91949f;
				padding: 0.5rem;
			}
			.or-line {
				background: #ebeff4;
				height: 2px;
				width: 250px;
				padding: 0 5px;
				margin-top: 0.8em;
			}
			.vignete {
				margin-right: 1.2rem;
				i {
					color: $secondary;
					font-size: 0.7rem;
				}
			}
			.vignete-description {
				font-weight: 500;
				font-size: 13px;
				color: #44464b;
			}
			.plan-vignetes {
				margin: 1rem 0;
			}
		}
		.panel-blue {
			background: #40afd9;
			box-shadow: 0 1px 5px rgba(0,0,0,0.1);
			color: #fff;
			border-radius: 3.5px !important;
		}
		.btn-outline-green {
			border: 1px solid $secondary;
			box-sizing: border-box;
			border-radius: 3.5px;
			min-width: 281px;
			font-weight: bold;
			font-size: 14px;
		}
		.btn-upgrade-grey {
			background: #ebeff4;
			border-radius: 3.5px;
			min-width: 281px;
			color: #adb1bd;
			font-weight: bold;
			font-size: 14px;
		}
		.btn-upgrade-green {
			border-radius: 3.5px;
			min-width: 281px;
			font-weight: bold;
			font-size: 14px;
			background: $secondary;
			color: #fff;
		}
		.plan-container-selected {
			border: 2px solid $secondary !important;
		}
		.disable-connect {
			cursor: default !important;
			.upgrade-to-recruitment {
				&:before {
					content: '';
					display: block;
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					top: 0;
					background-color: rgba(255,255,255,0.83);
					z-index: 1;
				}
			}
		}
	}
}
