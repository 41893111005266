@import '../../../../sass/legacy/color-palette';
@import '../../../../sass/variables';
.layoutv2 {
  .fa-square-secondary-orange {
    color: $secondary-orange;
    font-size: 11px;
  }
  .fa-square-secondary-yellow {
    color: $secondary-yellow;
    font-size: 11px;
  }
  .fa-square-secondary-blue {
    color: $secondary-blue;
    font-size: 11px;
  }
  .fa-square-secondary-teal {
    color: $secondary-teal;
    font-size: 11px;
  }
  .fa-square-secondary-green {
    color: $secondary;
    font-size: 11px;
  }
  .fa-square-secondary-purple {
    color: $secondary-purple;
    font-size: 11px;
  }
  .fa-square-secondary-purple2 {
    color: $secondary-purple2;
    font-size: 11px;
  }
  .fa-square-placeholder {
    color: $text-placeholder;
    font-size: 11px;
  }
  .bg-secondary-orange {
    background-color: $secondary-orange;
  }
  .bg-secondary-yellow {
    background-color: $secondary-yellow;
    color: #fff;
    font-size: 11px;
  }
  .bg-secondary-blue {
    background-color: $secondary-blue;
    color: #fff;
    font-size: 11px;
  }
  .bg-secondary-teal {
    background-color: $secondary-teal;
    color: #fff;
    font-size: 11px;
  }
  .bg-secondary-green {
    background-color: $secondary;
    color: #fff;
    font-size: 11px;
  }
  .bg-secondary-purple {
    background-color: $secondary-purple;
    color: #fff;
    font-size: 11px;
  }
  .bg-secondary-purple2 {
    background-color: $secondary-purple2;
    color: #fff;
    font-size: 11px;
  }
  .bg-placeholder {
    background-color: $text-placeholder;
    color: #fff;
    font-size: 11px;
  }
  .navbar {
    .show {
      .navbar-nav {
        margin-top: 0.5rem;
        .nav-item {
          margin: 0.4rem 0;
          text-align: center;
        }
      }
    }
  }
  .panel-cost-lead-marketing-spend {
    z-index: 10;
    position: absolute;
    right: 0;
    top: 15px;
    width: 200px;
    .cost-lead-span {
      font-size: 12px;
      text-align: center;
      display: block;
    }
    .cost-lead-value {
      font-weight: bold;
      margin-top: 5px;
      font-size: 14px;
      text-align: center;
      display: block;
    }
    .recommended-monthly-marketing-spend-span {
      margin-top: 7px;
      font-size: 12px;
      text-align: center;
      display: block;
    }
    .recommended-monthly-marketing-spend-value {
      font-weight: bold;
      margin-top: 5px;
      font-size: 14px;
      text-align: center;
      display: block;
    }
    .cost-lead-marketing-spend-button {
      z-index: 11;
      position: absolute;
      right: 0;
      top: 0px;
    }
  }
  .text-grey-5 {
    color: #91949f !important;
  }
  p {
    line-height: 150%;
  }
  .font-13 {
    font-size: 13px !important;
  }
  .font-500 {
    font-weight: 500 !important;
  }
  .font-600 {
    font-weight: 600 !important;
  }
  .smallstar {
    width: 13px;
  }
  .menu-content-dropdown-header {
    position: relative;
    display: inline-block;
    cursor: pointer;
    &:hover {
      .menu-content-dropdown-content-header {
        display: block;
      }
    }
  }
  .menu-content-dropdown-content-header {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 210px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    left: 0;
  }
  .chart-enrollment-trends {
    width: 100%;
    height: 295px;
  }
  .potenitial-revenue {
    svg {
      width: 100%;
    }
    .chart {
      display: flex;
      flex-direction: column;
      .slidervalue {
        margin-top: -175px;
        margin-bottom: 130px;
      }
    }
  }
  .sticky-billing {
    @media screen and (min-width: 769px) {
      position: sticky;
      top: 80px;
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  #contact-textarea {
    min-height: 161px;
  }
  .sendmessage {
    border-radius: unset !important;
    margin: 2rem 0;
  }
  .btn-lightgray {
    background-color: #ebeff4 !important;
    color: #adb1bd !important;
    border: none !important;
  }
  .notallowed {
    cursor: not-allowed;
  }
  .modal__content {
    @media screen and (max-width: 992px) {
      width: 90% !important;
    }
  }
  .table td,
  .table th {
    padding: 0.75rem 0;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
  }
  th {
    &.contact-name {
      min-width: 150px;
      color: #adb1bd;
      font-weight: 500;
      font-size: 11px;
    }
    &.contact-match {
      min-width: 120px;
      color: #adb1bd;
    }
    &.contact-student {
      min-width: 120px;
      color: #adb1bd;
      font-weight: 500;
      font-size: 11px;
    }
    &.contact-status {
      min-width: 120px;
      color: #adb1bd;
      font-weight: 500;
      font-size: 11px;
    }
    &.contact-buttons {
      min-width: 140px;
      color: #adb1bd;
      font-weight: 500;
      font-size: 11px;
    }
  }
  .panel-table {
    position: relative;
    .contact-status-mark {
      width: 43px;
      height: 3px;
      margin-left: 1rem;
      background-color: $secondary !important;
    }
    .archived-status-mark,
    .contact-status-mark {
      margin-left: 110px;
    }
    .header-filters {
      span {
        font-weight: 500;
        font-size: 13px;
        line-height: 150%;
      }
    }
  }
  .icon-close {
    color: #adb1bd;
    font-size: 1.5rem;
  }
  .icon-16 {
    width: 16px;
  }
  .three-points {
    font-size: 25px;
    color: #adb1bd;
  }
  .square-radius-10 {
    border-radius: 10px;
    height: 44px;
    min-width: 44px;
  }
  .square-color-grey-5 {
    background-color: #91949f;
  }
  .square-color-blue-1 {
    background-color: #0094cd;
    color: #fff;
  }
  .square-color-green-1 {
    background-color: $secondary;
  }
  .square-color-purple {
    background-color: #a572cc;
  }
  .contact-name,
  .contact-date {
    font-weight: 500 !important;
    font-size: 12px;
    line-height: 150%;
    text-align: left;
  }
  .button-green-contact {
    height: 31px;
    font-weight: 900;
    font-size: 11px;
    line-height: 150%;
    background: $secondary !important;
    color: #fff !important;
  }
  .edit-three-points {
    padding-top: 1rem !important;
    i {
      font-size: 1rem !important;
    }
  }
  .btn-green-2 {
    color: #fff;
    border: 1px solid $secondary;
    background-color: $secondary;
    border-radius: 3.5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    height: 45px;
  }
  .leadDetailsSide {
    padding-left: 0;
  }
  .selectEditLead {
    margin: 0.5em auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  .aside-profile-crm-basic {
    margin-top: 6rem !important;
    background: #fff;
    @media (min-widt: 768px) {
      border-radius: 0 0 10px 10px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
      margin-top: -1rem;
      position: sticky;
      top: 80px;
    }
    @media (min-width: 1200px) {
      margin-top: -3rem;
    }

    .menu-notes-lead-side {
      font-size: 13px;
    }
    .btn-delete-note-side-card {
      float: right;
      width: 45%;
    }
    .profile-name {
      margin-bottom: 0.5rem;
      .profile-text {
        padding-top: 0.7rem;
        .grid-container {
          display: grid;
          grid-template-columns: 50% 50%;
          .grid-item {
            text-align: left;
            display: flex;
            align-items: center;
          }
        }
        h6 {
          margin-bottom: 0;
        }
      }
    }
  }
  .img-profile {
    width: 46px;
    height: 46px;
    min-width: 46px;
    min-height: 46px;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 29px;
      width: auto;
      max-width: 29px;
    }
    .student-details {
      width: 100%;
      .value-green {
        font-weight: bold;
        color: $secondary;
        margin-right: 0.5rem;
      }
      .grid-container {
        margin-bottom: 0.5rem;
        display: grid;
        grid-template-columns: 25% 36% 20%;
      }
      .grid-item {
        text-align: left;
        display: flex;
        align-items: center;
      }
    }
  }
  .lead-details {
    width: 100%;
    overflow-y: auto;
    max-height: calc(80vh - 7rem);
    .button-red-cancel {
      margin-top: 0.5rem;
    }
    .three-points {
      position: relative;
    }
    .settings-dropdown-content-leads {
      top: 0;
    }
    .profile-name {
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    }
    .textarea {
      min-height: 15px;
    }
  }
  .select-filters {
    font-size: 13px;
    height: 40px;
  }
  .filter-status {
    position: relative;
    .pill-status {
      position: relative;
      display: inline-block;
      .pill-gray-7 {
        background-color: #ebeff4;
        color: #44464b;
        border-radius: 3.5px;
        height: 31px;
        padding: 0.5rem;
        width: 100%;
      }
      .pill-close {
        color: #adb1bd;
        position: absolute;
        top: -5px;
        right: -6px;
        font-size: 1rem;
      }
    }
  }
  .saved-filter-name {
    height: 71px;
    padding: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .saved-filter-name-selected {
    background-color: #f5f7f9;
  }
  tr#row-selected {
    background-color: #f5f7f9;
  }
  tr {
    &.thead-crm {
      th {
        font-weight: 500 !important;
      }
    }
  }
  .actions {
    padding-left: 0;
    padding-right: 0;
    @media (max-width: 1171px) {
      min-width: 225px;
    }
  }
  .button-grey-applications {
    height: 31px;
    font-weight: 600;
    font-size: 11px;
    line-height: 150%;
    background: #ebeff4 !important;
    color: #adb1bd !important;
    margin-right: 0.5rem !important;
  }
  .crm-tours-container {
    min-height: 75px;
    .tour-left-bar {
      height: 75px;
      width: 7px;
    }
    .bar-left-green-1 {
      background-color: $secondary;
    }
    .bar-left-blue-2 {
      background-color: #40afd9;
    }
    .tour-info-container {
      width: 100%;
      .actions {
        display: flex;
        align-items: center;
        margin-left: auto;
        color: #adb1bd;
        justify-content: flex-end;
      }
    }
  }
  .tours-page-content {
    .contact-icon {
      display: flex;
      align-items: center;
      img {
        max-height: 20px;
      }
    }
  }
  .title-premium-services {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
      margin-bottom: 1rem;
      @media (min-width: 768px) {
        margin-bottom: 0;
        width: 50%;
      }
    }
    .billing-buttons-header {
      display: flex;
      justify-content: flex-end;
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        a {
          width: 100%;
          display: block;
          width: 80%;
          margin-bottom: 1rem;
          text-align: center;
          margin: unset auto;
          button {
            width: 100%;
            display: block;
          }
        }
      }
      button {
        margin: 0 12px;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      a {
        display: block;
        width: 80%;
        margin-bottom: 1rem;
        text-align: center;
        margin: unset auto;
        button {
          width: 100%;
          display: block;
        }
      }
    }
  }
  .banner-billing-blue {
    background-image: linear-gradient(to right, #ecf8fd, #4fbce8);
    min-height: 213px;
    border-radius: 10px;
  }
  .text-banner-blue {
    padding: 2.5rem;
    @media (min-width: 1350px) {
      padding: 4rem;
    }
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  .img-banner-blue {
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 0;
    padding-left: 2rem;
  }
  .schola-select {
    font-size: 12px;
    color: #c9cdd7 !important;
    padding: 0;
    .schola__value-container {
      padding: 0 8px;
      height: 36px;
      & > div {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
        input {
          height: 30px !important;
        }
      }
    }
    .schola__menu {
      color: #484848 !important;
    }
    .schola__single-value {
      overflow: visible !important;
    }
    .schola__control {
      box-shadow: none !important;
      min-height: 33px;
      border-radius: 3.5px;
      border: 0.7px solid #dddfe7;
      .schola__indicator-separator {
        margin-bottom: 12px;
        margin-top: 9px;
      }
      &:hover {
        border-color: $secondary !important;
      }
    }
  }
  .banner-about-premium-services {
    background: url('https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/image-about-premium-services.png')
      no-repeat center top;
    height: 278px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 1rem;
    color: #fff;
    display: flex;
    @media (min-width: 1575px) {
      background-size: cover;
    }
    .text-banner-about {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 2rem;
      .icon-play-video-premium-services {
        display: flex;
        align-items: center;
      }
      h6 {
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
  .plan-selected {
    margin-top: 146px;
    margin-bottom: 1rem;
  }
  .dashboard-main-content {
    width: calc(100% - 30px);
    margin-top: 75px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 768px) {
      width: calc(100% - 60px);
    }
  }
}
.dashboardv3 {
  .panel-welcome-recruitment {
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
      flex-direction: row;
    }
  }
  .lead-pipeline {
    padding: 1rem 1.5rem;
    padding-bottom: 0;
    @media (min-width: 820px) {
      padding: 1.5rem;
    }
    .progress-lead-pipeline {
      display: flex;
      height: 300px;
    }
  }
  .stars-rating {
    margin-top: 6px;
    .dv-star-rating-star {
      margin-left: 0.3rem;
      img {
        width: 17px;
      }
    }
  }
  .card-section-phase-out {
    padding: 22px 0.5rem 24px;
    border-bottom: 1px solid #ebeff4;
    .card-phase-out-competition {
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
      border: none;
      .card-image {
        display: flex;
        justify-content: center;
        background: #bfe4f3;
        padding: 1 0 0 0;
        img {
          margin-bottom: -3px;
        }
        .fa-arrow-right {
          color: #0094cd;
        }
      }
    }
  }
  .settings-dropdown-leads-pipelin {
    position: absolute;
    display: inline-block;
    .settings-dropdown-content-leads-pipelin {
      padding: 1.5rem;
      position: absolute;
      background-color: #fff;
      min-width: 340px;
      overflow: auto;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      right: 0;
      top: 25px;
      text-align: left;
      left: 0;
      .form-check {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        label {
          &.form-check-label {
            margin-left: 0.5rem;
          }
        }
      }
    }
    .settings-date-range {
      padding: 0.7rem 0 1rem 0;
      input {
        width: 140px !important;
      }
    }
    .settings-buttons {
      border-top: 1px solid #dfe4ea;
      display: flex;
      justify-content: space-between;
      padding-top: 1.5rem;
      button {
        color: #d1d4de;
        font-size: 14px;
        font-weight: 500;
        &.active {
          color: #44464b;
        }
        &:hover {
          color: #0094cd;
          font-weight: 600;
        }
      }
    }
  }
  .graphic-container {
    position: relative;
    .icon-zoom {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 1px;
      right: 0;
      z-index: 9;
      i {
        color: #007bff;
      }
    }
  }
  #bar-graphics {
    display: flex;
    justify-content: space-between;
    height: 150px;
    align-items: flex-end;
    position: relative;
    margin-top: 3px;
    .graphic {
      width: 25px;
      text-align: center;
      display: flex;
      flex-direction: column;
      height: 125px;
      justify-content: flex-end;
      p {
        color: #91949f;
        margin-top: 0.5rem;
      }
    }
    .bar {
      width: 25px;
      margin-right: 0.5rem;
    }
    .bg-danger {
      background-color: #fc6e35 !important;
    }
    .bg-blue {
      background-color: #5381ef !important;
    }
    .bg-warning {
      background-color: #ffb03f !important;
    }
    .bg-sea {
      background-color: #5dcdd3 !important;
    }
    .bg-success {
      background-color: $secondary !important;
    }
    .bg-greensea {
      background-color: #53caa6 !important;
    }
    .fa-square-danger {
      color: #fc6e35;
      font-size: 11px;
    }
    .fa-square-warning {
      color: #ffb03f;
      font-size: 11px;
    }
    .fa-square-blue {
      color: #5381ef;
      font-size: 11px;
    }
    .fa-square-blue-sea {
      color: #5dcdd3;
      font-size: 11px;
    }
    .fa-square-primary-green {
      color: $secondary;
      font-size: 11px;
    }
    .fa-square-green-sea {
      color: #53caa6;
      font-size: 11px;
    }
  }
  .icon-anydate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 0.7rem;
  }
  .button-recruiting {
    margin-top: 10px;
    @media (min-height: 810px) {
      margin-top: 0;
    }
  }
  .application-status {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .enrollment-status {
    padding: 0.7rem 1rem 0.5rem 1.5rem;
    @media (min-height: 810px) {
      padding: 1.5rem;
    }
    .potenitial-revenue-v3 {
      width: 100%;
      min-height: 150px;
      svg {
        display: flex;
        justify-content: flex-start;
        padding: 0 !important;
        path {
          stroke-width: 7 !important;
        }
      }
    }
    .chart {
      display: flex;
      .slidervalue {
        margin-top: 0;
        text-align: left;
      }
      .circle-value-inside {
        color: $secondary;
        font-size: 24px;
        font-weight: bold;
        margin-top: -75px;
      }
      .avg-perpupil-data {
        display: flex;
        .icon-edit-avg {
          i {
            color: #adb1bd;
          }
          &:hover {
            i {
              color: $primary;
            }
          }
        }
      }
    }
    #formControlRange {
      padding: 0 !important;
    }
    input[type='range']::-webkit-slider-thumb {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 4px 0 #000;
      cursor: pointer;
      -webkit-appearance: none;
    }
    .fa-arrow-right {
      color: #0094cd;
    }
    .enrollment-content {
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin-top: 0.5rem;
      @media (min-width: 992px) {
        padding-left: 0;
        margin-top: 0;
      }
    }
  }
  .overlay-currently-enrolled {
    width: 100%;
    height: 100%;
    border: 1px dashed #9c9c9c;
    padding: 20px;
    background-color: rgba(245, 244, 244, 0.859);
    position: absolute;
    button {
      margin-top: 40%;
    }
  }
  .modal-how-calculate-amount {
    .modal__children {
      padding: 0 24px 24px;
      .modal-content {
        background-color: #fff;
      }
      .modal__header {
        padding: 24px;
        border-bottom: 1px solid #dfe4ea;
        justify-content: space-between !important;
        .modal__title {
          color: #44464b !important;
          margin-left: 0 !important;
        }
      }
    }
  }
  .enroll-status-container {
    margin-bottom: 0px !important;
  }
  .modal-pipeline {
    .modal__content {
      width: 90% !important;
      max-width: 90% !important;
      max-height: 720px;
      @media (max-width: 480px) {
        width: 100% !important;
        max-width: 100% !important;
      }
      .modal__header {
        padding: 24px;
        border-bottom: 1px solid #dfe4ea;
        justify-content: space-between !important;
        .modal__title {
          color: #44464b !important;
          margin-left: 0 !important;
        }
        @media (max-width: 480px) {
          position: fixed;
          width: 100%;
          background: #fff;
          z-index: 1;
        }
      }
      .modal__children {
        padding: 0 !important;
        @media (max-width: 480px) {
          margin-top: 65px;
        }
        .modal-content {
          background-color: transparent !important;
          .modal-body {
            margin: 0 !important;
            padding: 0 !important;
            .pipeline-left-side {
              border-bottom: 1px solid #dfe4ea;
              display: flex;
              justify-content: space-between;
              padding: 0 !important;
              @media (max-width: 767px) {
                flex-direction: column;
              }
              @media (min-width: 991px) {
                flex-direction: column;
                border-right: 1px solid #d1d4de;
              }
              .application-status {
                margin-bottom: 0;
                a {
                  color: #44464b;
                  font-size: 14px;
                  font-weight: 500;
                }
              }
              .bar-graphics {
                padding: 24px 24px 6px 24px;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                height: 150px;
                @media (min-width: 768px) and (max-width: 990px) {
                  width: 30%;
                }
                .graphic {
                  width: 25px;
                  text-align: center;
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  justify-content: flex-end;
                  p {
                    color: #91949f;
                    margin-top: 0.5rem;
                  }
                }
                .bar {
                  width: 25px;
                  margin-right: 0.5rem;
                  border-radius: 3px;
                }
                .bg-danger {
                  background-color: #fc6e35 !important;
                }
                .bg-blue {
                  background-color: #5381ef !important;
                }
                .bg-warning {
                  background-color: #ffb03f !important;
                }
                .bg-sea {
                  background-color: #5dcdd3 !important;
                }
                .bg-success {
                  background-color: $secondary !important;
                }
                .bg-greensea {
                  background-color: #53caa6 !important;
                }
                .fa-square-danger {
                  color: #fc6e35;
                  font-size: 11px;
                }
                .fa-square-warning {
                  color: #ffb03f;
                  font-size: 11px;
                }
                .fa-square-blue {
                  color: #5381ef;
                  font-size: 11px;
                }
                .fa-square-blue-sea {
                  color: #5dcdd3;
                  font-size: 11px;
                }
                .fa-square-primary-green {
                  color: $secondary;
                  font-size: 11px;
                }
                .fa-square-green-sea {
                  color: #53caa6;
                  font-size: 11px;
                }
              }
              .charts-data {
                padding: 12px 24px 6px 24px;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                border-bottom: 1px solid #d3d3d3;
                @media (min-width: 768px) and (max-width: 990px) {
                  width: 30%;
                }
              }
              .marketing-channels {
                padding: 12px 24px;
                @media (min-width: 768px) and (max-width: 990px) {
                  width: 30%;
                }
                .channel {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 12px;
                  h6 {
                    font-size: 14px;
                  }
                  p {
                    font-size: 14px;
                    font-weight: 500 !important;
                  }
                }
              }
            }
            .pipeline-right-side {
              padding: 24px;
              .table-head-marketing-channel {
                border-bottom: 1px solid #d1d4de;
                font-size: 14px;
                font-weight: 500;
                font-size: 14px;
                color: #6c6d70;
                th {
                  padding: 12px 24px 12px 0;
                }
                .first-title {
                  width: 40%;
                }
              }
              .table-item-marketing-channel {
                font-size: 14px;
                td {
                  padding: 12px 24px 12px 0;
                  font-weight: 600;
                  text-align: right;
                }
                .firt-item-column {
                  font-weight: 500;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
}
.dashboardv3-panel {
  .dashboard-main-content {
    padding-bottom: 0 !important;
    margin-top: 0;
    padding-left: 10px;
    padding-right: 0;
    padding-bottom: 0 !important;

    .parent-content {
      display: flex;
      margin-top: 6rem;
      flex-direction: column;
      gap: 1rem;
      @media (max-width: 480px) {
        margin-top: 6rem;
      }
      @media (min-width: 967px) {
        flex-direction: row;
      }
      @media (min-width: 512px) and (max-width: 991px) {
        justify-content: space-between;
      }

      @media (min-width: 992px) {
        justify-content: space-between;
      }

      .main-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 1rem;
        @media (min-width: 512px) {
          flex-direction: row;
        }

        .column-grey {
          background: #f9f9fb;
          border-right: 1px solid #dfe4ea;
          min-width: 240px;
          max-width: 240px;
        }
        .column-grey {
          background: #f9f9fb;
          border-right: 1px solid #dfe4ea;
          min-width: 240px;
          max-width: 240px;
        }
        .column-left-welcome {
          width: 50%;

          @media (min-width: 960px) {
            width: 60%;
          }
          .panel-welcome-recruitment {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            @media (min-width: 1140px) {
              flex-direction: row;
            }
            .panel-welcome,
            .panel-recruitment {
              min-height: 316px;
              padding: 0.7rem 1.3rem;
              width: 100%;
              @media (min-width: 1140px) {
                width: 50%;
                padding: 1.5rem;
              }
            }
            .panel-welcome {
              margin-right: 0;
              @media (min-width: 992px) {
                margin-bottom: unset;
              }
            }
          }
          .panel-hello-enrollment-trand {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }
        }
        .column-right-pipeline {
          width: 50%;

          @media (min-width: 960px) {
            width: 40%;
          }
        }
      }
    }
  }

  header {
    height: 60px !important;
    @media (min-height: 820px) {
      height: 75px !important;
    }
  }
  .leadPipelineBreakdown-modal {
    .modal__content {
      width: 90% !important;
      border-radius: 4px;
      @media (min-width: 768px) {
        width: 400px !important;
      }
    }
    .modal__header {
      display: none;
    }
    .modal__children {
      padding: 0;
    }
    .modal-header {
      padding: 24px;
      border-bottom: 1px solid $fill-border;
    }
    .modal-footer {
      padding: 24px;
      border-top: 1px solid $fill-border;
    }
    .content {
      padding: 10px;
    }
  }
}
.modal-payment {
  .modal__content {
    width: 100% !important;
    max-width: 100% !important;
    @media (min-width: 769px) {
      width: 50% !important;
      max-width: 50% !important;
    }
    .modal__header {
      padding: 24px;
      border-bottom: 1px solid #dfe4ea;
      justify-content: space-between !important;
      .modal__title {
        color: #44464b !important;
        margin-left: 0 !important;
      }
    }
  }
  .payment-method {
    width: 130px !important;
    height: 136px;
  }
  .payments-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    @media (min-width: 1024px) {
      flex-direction: row;
    }
    .button-payment {
      background: #efefef;
      padding: 2rem 3rem;
      min-width: 245px;
      text-align: center;
      font-weight: 700;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #44464b;
      margin-bottom: 1rem;
      width: 252px;
      @media (min-width: 1024px) {
        width: unset;
        margin-bottom: unset;
      }
      i {
        font-size: 2rem;
        margin-bottom: 24px;
      }
      &:hover {
        background: $secondary;
        color: #fff;
        box-shadow: 0px 2px 6px rgba(145, 180, 120, 0.9);
      }
      &.active {
        box-shadow: 0px 2px 6px rgba(101, 107, 118, 0.2);
      }
    }
  }
  .modal__children {
    padding: 45px 24px !important;
  }
}
.modal-payment-fail,
.modal-payment-result,
.modal-success-notification,
.modal-missing-notification,
.set-phone-modal {
  .text-red {
    color: $primary-red !important;
  }
  .modal__content {
    width: 100% !important;
    max-width: 100% !important;
    @media (min-width: 769px) {
      width: 600px !important;
      max-width: 600px !important;
    }
    .modal__children {
      padding: 0;
    }
    .modal-content {
      padding: 24px;
      .icon-container {
        &.icon-green {
          width: 100px;
          height: 100px;
          border-radius: 50px;
          overflow: hidden;
          color: #fff;
          background: $secondary;
          i {
            &.fa {
              font-size: 3rem;
            }
          }
        }
        &.icon-red {
          i {
            &.fa {
              font-size: 100px;
              color: $primary-red;
            }
          }
        }
        &.icon-blue {
          i {
            &.fa {
              font-size: 100px;
              color: $primary;
            }
          }
        }
      }
    }
    .modal-footer {
      box-shadow: none;
      padding: 24px;
    }
  }
  .modal__children {
    padding: 24px;
  }
  #payment-result-content-billing-panel {
    padding: 0;
    .payment-result-subtitle {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .modal-body {
    .payment-result-subtitle {
      display: block;
      padding: 15px 0 15px 0;
      font-size: 24px;
    }
    .payment-result-label {
      display: block;
      padding: 15px 0 15px 0;
      font-size: 16px;
    }
    .payment-result-sumary {
      display: block;
      padding: 15px 0 15px 0;
      font-size: 20px;
    }
  }
  .btn {
    margin: 5px;
  }
}
.modal-payment-fail {
  .button--color--red {
    width: 100px;
  }
}
.modal-missing-notification {
  .modal-content {
    padding: 0 !important;
  }
  .modal__header {
    padding-bottom: 0;
  }
}
input:not([type='checkbox']):focus,
textarea:focus {
  outline: none;
  border-radius: 3px;
}
.schola-badge {
  width: 95px;
  height: 95px;
  border-radius: 100%;
  color: #fff;
  background: #9c3;
  box-shadow: 0 0 0 8px rgba(153, 204, 51, 0.5);
  p {
    margin: 0;
    &.small {
      font-size: 9px;
    }
  }
}
