.complete-tours-table,
.complete-tours,
.complete-tours:hover {
  background-color: $secondary !important;
  border: none !important;
}
.btn-green-sucess {
  height: 31px;
  font-weight: 900;
  font-size: 11px;
  line-height: 150%;
  background: $secondary !important;
  color: #fff !important;
  width: 130px;
  top: -20px;
  border-radius: 3.5px;
  padding: 5px 25px !important;
  border: none !important;
}
.square-radius-10 {
  border-radius: 10px;
  height: 44px;
  min-width: 44px;
}
.square-color-gray-5 {
  background-color: #91949f;
}
.square-color-blue-1 {
  background-color: $primary;
}
.square-color-green-1 {
  background-color: $secondary;
}
.square-color-purple {
  background-color: #a572cc;
}
.square-color-yellow {
  background-color: #f8d55c;
}
.school-name,
.school-level {
  font-weight: 500 !important;
  font-size: 13px;
  line-height: 150%;
}
.contact-name,
.contact-date {
  font-weight: 500 !important;
  font-size: 12px;
  line-height: 150%;
}
.button-green-contact {
  height: 31px;
  font-weight: 900;
  font-size: 11px;
  line-height: 150%;
  background: $secondary !important;
  color: #fff !important;
  margin-right: 0.5rem !important;
}
.pagination__count {
  font-size: 14px;
}

.leads-page-content {
  position: relative;
}

.modal-open {
  -webkit-filter: blur(6px);
  filter: blur(6px);
  // position: fixed;
  position: relative;
  width: 100%;

  &:before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    display: block;
    position: absolute;
    background-color: #fff;
    z-index: 99;
  }
}

.lead-status-chooser {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  background-color: $white;
  margin-bottom: 32px;
  box-shadow: $card-box-shadow;
  border-radius: 10px 10px 0 0;
}

.lead-status-chooser__tab {
  padding: 25px 40px;
  text-align: center;
  font-weight: $medium;
  position: relative;
  font-size: 11px;

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.05);
  }

  &,
  &:link,
  &:visited {
    color: $schola-lightgrey;
  }
}

.lead-status-chooser__status--active {
  background-color: #f2f2f2;

  &:first-child {
    // border-top-left-radius: 4px;
    // border-bottom-left-radius: 4px;
  }

  &:after {
    width: 100%;
    height: 20px;
    border-bottom: 8px solid #7bba53;
    content: '';
    position: absolute;
    top: 75%;
    left: 0;
  }

  &:hover {
    cursor: pointer;

    &:after {
      border-top-color: darken($primary, 5);
    }
  }

  &,
  &:link,
  &:visited {
    color: #6c6d70;
  }
}

.lead-action-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  background-color: $white;
  border-top: 1px solid #e5e7ed;
  top: -31px;
  border-radius: none;
  position: relative;
  // box-shadow: none;
  box-shadow: $card-box-shadow;

  // border-top-right-radius: $card-border-radius;
  // border-top-left-radius: $card-border-radius;
  .button {
    // padding: 24px 40px;
    background: rgba($black, 0);
    border-color: rgba($black, 0);
    border-radius: 0;

    &:hover,
    &:active {
      background: rgba($black, 0);
      border-color: rgba($black, 0);
    }
  }
}

.send-app-button {
  span {
    text-align: center;
    background-color: #18ab29;
    border-radius: 3.5px !important;
    border: 1px solid #18ab29;
    display: inline-block;
    cursor: pointer;
    color: #fff;
    font-size: 10px;
    padding: 8px;
    width: 90px;
    text-decoration: none;
    box-shadow: $card-box-shadow;

    &:not(.button--disabled):hover {
      // background: $primary;
      color: $white;
    }
  }
}

.waitlist-button {
  span {
    text-align: center;
    background-color: $schola-bluegrey;
    border-radius: 3.5px;
    border: 1px solid $schola-bluegrey;
    display: inline-block;
    cursor: pointer;
    color: #fff;
    font-size: 10px;
    padding: 8px;
    width: 90px;
    text-decoration: none;
    box-shadow: $card-box-shadow;

    &:not(.button--disabled):hover {
      // background: $primary;
      color: $white;
    }
  }
}

.accept-button {
  span {
    text-align: center;
    background-color: $secondary;
    border-radius: 3.5px;
    border: 1px solid $secondary;
    display: inline-block;
    cursor: pointer;
    color: #fff;
    font-size: 10px;
    padding: 8px;
    width: 90px;
    text-decoration: none;
    box-shadow: $card-box-shadow;

    &:not(.button--disabled):hover {
      // background: $primary;
      color: $white;
    }
  }
}

.decline-button {
  span {
    text-align: center;
    background-color: $schola-carmine;
    border-radius: 3.5px;
    border: 1px solid $schola-carmine;
    display: inline-block;
    cursor: pointer;
    color: #fff;
    font-size: 10px;
    padding: 8px;
    width: 90px;
    text-decoration: none;
    box-shadow: $card-box-shadow;

    &:not(.button--disabled):hover {
      // background: $primary;
      color: $white;
    }
  }
}

.archive-button {
  span {
    text-align: center;
    background-color: darken($schola-purple, 10%);
    border-radius: 3.5px;
    border: 1px solid darken($schola-purple, 10%);
    display: inline-block;
    cursor: pointer;
    color: #fff;
    font-size: 10px;
    padding: 8px;
    width: 90px;
    text-decoration: none;
    box-shadow: $card-box-shadow;

    &:not(.button--disabled):hover {
      color: $white;
    }
  }
}

.print-button {
  width: 140px;
  margin-left: auto;

  span {
    text-align: center;
    background-color: $primary;
    border-radius: 28px;
    border: 1px solid $primary;
    display: inline-block;
    cursor: pointer;
    color: #fff;
    font-size: 10px;
    padding: 8px;
    width: 90px;
    text-decoration: none;
    box-shadow: $card-box-shadow;

    &:not(.button--disabled):hover {
      color: $white;
    }

    &.hidden {
      display: none;
    }
  }

  > div {
    padding: 12px 40px;
  }
}

.tour_requested {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  font-size: 12px;
  color: $primary;
  margin-top: 4px;
}

.tour_requested--padding {
  padding-bottom: 0 !important;
}

.leads-subscribe {
  max-width: 100%;
  margin-top: 40px;
}

.export-button {
  width: 140px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  a {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    color: $schola-bluegrey;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: $bold;

    .icon {
      margin-right: 8px;
      fill: $schola-bluegrey;
    }
  }
}

.school-leads__modal {
  h3 {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 24px;
  }
}

#printPDF {
  display: none;
}

// Table
.schola-table-leads {
  width: 100%;
  background: $white;
  border-collapse: collapse;
  border-spacing: 0;
  position: relative;
  top: -30px;
  box-shadow: $card-box-shadow;
  border-radius: 0 0 10px 10px;
  thead {
    font-weight: $medium;
    color: #808183;
    font-size: 14px;
    text-transform: uppercase;
    tr {
      th {
        padding: 20px 16px;
        text-align: left;
        font-size: 12px;
        text-align: center;
        &:first-child {
          @media (min-width: 945px) and (max-width: 1115px) {
            padding: 5px 25px 25px 10px;
          }
        }
      }
    }
  }
  tbody {
    tr {
      border-top: solid 1px $gray-200;
      border-bottom: solid 1px $gray-200;
      td {
        line-height: 24px;
        font-size: 12px;
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        &:first-child {
          padding: 10px 0px 10px 20px;
        }
      }
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
  tfoot {
    tr {
      td {
        padding: 20px 32px;
        text-align: right;
      }
    }
  }
}
.schola-table-leads th,
.schola-table-leads td {
  vertical-align: middle;
  word-wrap: break-word;
}

.table-responsive {
  width: 100%;

  table {
    // table-layout fixed
    tr {
      margin: 0;
      display: table-row;
      position: relative;
      th,
      td {
        text-align: left;
        padding: 0;
        display: table-cell;
      }

      td {
        line-height: 13px;

        &:before {
          content: '';
          display: none;
        }
      }
    }

    thead {
      display: table-header-group;

      tr {
        position: initial;
      }
    }

    tbody {
      display: table-row-group;
    }
  }
}

table.schola-table-tours {
  position: initial;

  thead {
    tr {
      th {
        padding: 13px 10px !important;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 6px 10px !important;

        .checkbox-skin {
          width: 15px;
          height: 15px;
          margin-right: 8px;
          float: left;

          label:before {
            top: 0;
          }
        }

        tdButton-table,
        .complete-tours-table {
          height: 23px;
        }
      }
    }
  }
}

.checkbox-column {
  width: 48px;

  label {
    float: left;
  }
}

.school-applications-table {
  margin-top: 48px;
}

.table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  padding: 8px;
}

.email-marketing {
  text-align: center;
  background-color: transparent;
  border-radius: 3.5px;
  border: 1px solid #7bba53;
  display: inline-block;
  cursor: pointer;
  color: #7bba53;
  font-size: 10px;
  font-weight: bold;
  padding: 10px 25px;
  text-decoration: none;
  width: 150px;
  margin: 5px;
  position: relative;
  top: -20px;
}

.add-leads {
  text-align: center;
  background-color: #44c767;
  border-radius: 3.5px;
  border: 1px solid #18ab29;
  display: inline-block;
  cursor: pointer;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  padding: 10px 25px;
  width: 130px;
  margin: 5px;
  text-decoration: none;
  position: relative;
  top: -20px;
}

.bot {
  height: 18px;
  width: 18px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  color: white;
  line-height: 2;
  font-size: 9px;
}

.settings-dropdown-content-leads {
  // display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 200px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0px;
  top: 15px;
  text-align: left;
}

.settings-dropdown-content-leads a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.settings-show-leads {
  display: block;
}

.settings-dropdown-leads {
  position: absolute;
  display: inline-block;
  right: 0;
}

.settings-dropdown-leads a:hover {
  background-color: #ddd;
}

@media (max-width: 944px) {
  .schola-table-leads table,
  .schola-table-leads thead,
  .schola-table-leads th,
  .schola-table-leads tbody {
    display: block;
  }

  .schola-table-leads thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .schola-table-leads tr {
    margin: 0 0 1rem 0;
    display: block;
  }

  .schola-table-leads td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    display: block;
  }

  .schola-table-leads td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  .schola-table-leads td:nth-of-type(3):before {
    content: 'PHONE';
  }

  .schola-table-leads td:nth-of-type(4):before {
    content: 'EMAIL';
  }

  .schola-table-leads td:nth-of-type(5):before {
    content: 'PREFERRED';
  }

  .schola-table-leads td:nth-of-type(6):before {
    content: 'STUDENTS';
  }

  .schola-table-leads td:nth-of-type(7):before {
    content: 'GRADE';
  }

  .schola-table-leads td:nth-of-type(8):before {
    content: 'NOTE';
  }

  .row {
    margin-top: 30px;
  }

  .lead-status-chooser {
    overflow-x: auto;
  }

  .schola-table-leads tbody tr td {
    border-top: none;
  }
}

.tdButton span {
  text-align: center;
  background-color: $primary;
  border-radius: 3.5px;
  border: 1px solid $primary;
  display: inline-block;
  cursor: pointer;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  padding: 2px;
  width: 70px;
  text-decoration: none;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.tdButton {
  width: 100px;
}

.checkbox-skin label:before {
  content: '';
  top: 7px;
  left: 0;
  width: 15px;
  height: 15px;
  display: block;
  position: absolute;
  border-radius: 50%;
  border: 1px solid #b0b1b5;
}

.schedule-tour-modal {
  top: 20px;
  left: 0;
  right: 0;
  width: 90%;
  max-width: 330px;
  margin: auto;
  position: absolute;
  border-radius: 8px;
  background-color: #fff;
  -webkit-box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.02), 0 3px 16px 2px rgba(0, 0, 0, 0.12),
    0 5px 6px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.02), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.2);

  .image {
    width: 45%;
    height: 100%;
    float: left;
    display: none;
    position: relative;
    background-size: cover;
    background-position: top center;
    background-image: url('https://s3-us-west-2.amazonaws.com/scholamatch-static/modal-image-2.png');
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .content {
    text-align: center;
    height: 100%;
    padding: 20px;
    position: relative;

    .close-modal-button {
      top: 12px;
      right: 13px;
      width: 19px;
      height: 19px;
      position: absolute;
      background-image: url('https://s3-us-west-2.amazonaws.com/scholamatch-static/close-grey.svg');
    }

    .teacher-avatar {
      width: 83px;
      height: 83px;
      border-radius: 50%;
      margin: 0 auto 15px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .modal-title {
      color: #0299d3;
      font-size: 20px;
      font-weight: 700;
    }

    .modal-paragraph {
      font-size: 12px;
      color: #aeafb0;
      line-height: 20px;
    }

    .modal-form {
      padding: 15px 25px;

      .form-group input,
      .form-group select {
        @media (min-width: 992px) {
          font-size: 10px !important;
        }
      }

      .student-info {
        .form-group:last-child {
          margin-bottom: 16px;
          overflow: hidden;
        }

        select {
          max-width: 100%;
        }
      }

      .form-group {
        .alert-error {
          color: #fff;
          font-size: 10px;
          font-weight: 600;
          min-height: 25px;
          padding: 7.5px 0;
          margin-top: 3px;
          display: block;
          border-radius: 3.5px;
          background-color: #ff5e5e;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .modal-form {
      a {
        width: 184px;
        margin-top: 30px;
        color: red;
        font-size: 13px;
        font-weight: 600;
        cursor: pointer;
      }
    }

    .choose-a-tour {
      color: #6c6d70;
      font-size: 11px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 10px 0;
    }

    .lead-column {
      float: left;
      display: flex;

      .form-group {
        width: 50%;
      }

      .form-group:first-child {
        margin-right: 5px;
      }
    }

    .tour-date {
      margin-bottom: 5px;
      overflow: hidden;

      .form-group {
        padding: 0 10px;
      }
    }

    .theme-button {
      font-size: 12px;
      width: 184px;
      margin-top: 20px;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .modal-thanks {
      .thanks-text {
        color: #6c6d70;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin: 30px 0;
      }
    }
  }
}

@media (min-width: 768px) {
  .schedule-tour-modal {
    top: 0;
    bottom: 0;
    width: 670px;
    height: 600px;
    max-width: initial;

    .image {
      display: block;
    }

    .content {
      width: 55%;
      padding: 30px;
      float: left;

      .modal-title {
        margin: 25px 0 15px;
      }

      .modal-form .student-info {
        .student-name {
          width: 60%;
          float: left;
          padding-right: 10px;
        }

        .grade {
          width: 40%;
          float: left;
        }
      }

      .choose-a-tour {
        margin: 18px 0 13px;
      }

      .tour-date {
        margin-bottom: 5px;

        .form-group {
          float: left;

          &.date {
            width: 50%;
          }

          &.time {
            width: 50%;
          }
        }
      }

      .modal-thanks {
        .theme-button {
          left: 0;
          right: 0;
          bottom: 30px;
          margin: auto;
          position: absolute;
        }
      }
    }
  }
}

.month-cancel {
  height: 39px;
  width: 289px;
  color: #6c6d70;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: -0.27px;
  line-height: 20px;
  text-align: center;
}
.with-our-email-autom {
  height: 70px;
  width: 289px;
  color: #6c6d70;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: -0.27px;
  line-height: 20px;
  text-align: center;
}

.don-t-let-prospectiv {
  height: 48px;
  width: 320px;
  color: #0299d3;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.27px;
  line-height: 24px;
  text-align: center;
}

.activate-email-marke {
  height: 31px;
  width: 184px;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.4px;
  line-height: 40px;
  text-align: center;
}
.tours-table {
  .table-responsive {
    overflow-x: visible;
    overflow-y: visible;
    @media (min-width: 1024px) {
      overflow-x: visible;
      overflow-y: visible;
    }
  }
}
