.school-application {
    @media (min-width: $desktop) {
        padding: 40px 152px;
    }
}

.school-application__section-title {
    h3 {
        font-weight: $regular;
        text-align: center;
        padding: 24px 0 32px 0;
    }
}

.school-application__page-icons {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    @media (max-width: $tablet-portrait) {
        width: 100%;
    }
}

.school-application__steppers {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background: $white;
    z-index: 1;
}

.stepper-current {
    width: 32px;
    height: 32px;
    background: $schola-darkgray;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.pending-step {
    width: 32px;
    height: 32px;
    border-radius: 100px;
    background: $schola-lightgrey;
    z-index: 0;

    .icon {
        display: none;
    }
}

.past {
    background: $secondary;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    .icon {
        display: inline-block;
    }
}

.stepper-wrapper {
    position: relative;
}

.school-application__page-icons__line {
    width: calc(50% - 32px);
    height: 1px;
    background-color: $gray-200;
    position: absolute;
    top: 20px;
    left: 220px;

    @media (max-width: $tablet-portrait) {
        width: calc(100% - 32px);
        left: 16px;
    }
}

.section-name {
    text-align: center;
    padding: 64px 0 48px;
}

//  Form

.multi-text-field > span {
    display: inline-block;
    font-size: 18px;
    font-weight: $medium;
    padding-bottom: 16px;
}

.school-application__radio {
    h4 {
        font-size: 14px;
        padding-bottom: 16px;
    }
    .radio-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .radio {
        margin-left: 0;
    }

    .radio-label {
        font-size: 16px;
        font-weight: $regular;
    }
}

.medical-conditions {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;

    h4 {
        font-weight: $regular;
        margin-bottom: 16px;
    }
}

.school-application {
    .checkbox {
        margin-left: 16px;
    }

    .checkbox-label {
        font-size: 16px;
        font-weight: $regular;
    }
}

.school-application__general-info {
    margin-bottom: 40px;
}

.school-application__parent-info {
    margin-bottom: 40px;
}

.school-application__parent-info,
.school-application__student-info {
    h4 {
        margin-bottom: 16px;
    }
}

.school-application__success {
    max-width: 600px;
    margin: 0 auto;

    p {
        margin-bottom: 16px;
        line-height: 1.4;
    }
}
