// modal
.header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.backButton {
  height: 2rem;
  width: 2rem;
}
