.status {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: 9999px;
  font-size: 0.75rem;
}

.active {
  background-color: #ebf8ff; /* bg-blue-50 */
  color: #3182ce; /* text-blue-600 */
}

.inactive {
  background-color: #f7fafc; /* bg-gray-50 */
  color: #718096; /* text-gray-600 */
}

.pending {
  background-color: #fffbeb; /* bg-yellow-50 */
  color: #d69e2e; /* text-yellow-600 */
}
