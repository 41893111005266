.app-footer {
	.cta {
		text-align: center;
		padding-bottom: 30px;
		h3 {
			color: #fff;
			font-size: 18px;
			font-weight: 700;
			line-height: 26px;
		}
		.theme-button {
			width: 200px;
			margin-top: 20px;
			-webkit-box-shadow: -1px 12px 20px 0 rgba(0,53,74,0.14);
			box-shadow: -1px 12px 20px 0 rgba(0,53,74,0.14);
		}
	}
	.footer-navigation {
		padding-top: 30px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		.footer-navigation__list {
			li {
				text-align: center;
				a {
					color: #fff;
					font-size: 12px;
					font-weight: 700;
					text-decoration: none;
					padding: 8px 0;
					display: block;
				}
			}
		}
	}
	&.solid-bar {
		background: #0299d3;
		border-top: 1px solid #007cac;
		.footer-navigation {
			padding-top: 0;
			border: none;
			margin-top: 5px;
		}
	}
	&.light-bar {
		background: #f5f7f9;
		.footer-navigation {
			padding: 0;
			.content-logo {
				img {
					max-width: 144px;
					mix-blend-mode: multiply;
				}
			}
			.footer-navigation__list {
				li {
					a {
						color: #44464b;
						font-size: 14px;
					}
				}
			}
		}
	}
}
@media (min-width: 768px) {
    .app-footer {
        padding: 38px 150px 38px;
        .cta {
            width: 540px;
            text-align: left;
            margin: auto;
            padding-bottom: 67px;
            h3 {
                font-size: 24px;
                width: 270px;
                display: inline-block;
                vertical-align: middle;
            }
            .theme-button {
                margin-left: 40px;
                display: inline-block;
            }
        }
        .footer-navigation {
            padding-top: 80px;
            flex-direction: row;
            .footer-navigation__list {
                li {
                    display: inline-block;
                    text-align: left;
                    a {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
            }
        }
        &.solid-bar {
            padding: 18px 150px 15px;
        }
    }
}
