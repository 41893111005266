.tableContainer {
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
}

.table {
  width: 100%;
  border-radius: 0.5rem;
  overflow: auto;
  min-width: 1150px;
}

.tableRow {
  border-bottom: 1px solid #f3f4f6;

  &:last-child {
    border-bottom: 0;
  }
}

.tableCell {
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  color: #4b5563;

  &.primary {
    color: #3b82f6;
    font-weight: 500;
    text-align: left;
    transition: color 0.2s;

    &:hover {
      color: rgba(59, 130, 246, 0.8);
    }
  }

  &.capitalize {
    text-transform: capitalize;
  }

  :global(.actions-content-menu) {
    right: 25px !important;
  }
}
