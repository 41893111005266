.live-search {
  .lead-disabled {
    background: $fill-disabled;
  }
  .main-content {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
      height: 100%;
    }
  }
  .column-grey {
    @media (max-width: 768px) {
      min-width: 100%;
    }
  }
  .live-head-title {
    background: #fff;
    box-shadow: 0 2px 6px rgba(101, 107, 118, 0.2);
    padding: 24px;
    .head-title-search {
      display: flex;
      align-items: baseline;
      flex-direction: column;
      @media (min-width: 768px) {
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
      }
      form {
        width: 100%;
        margin: 1rem 0;
        @media (min-width: 768px) {
          width: 150px;
          margin: 0 24px;
        }
        @media (min-width: 1140px) {
          width: 200px;
        }
        input {
          background: #f9f9fb;
          border: 1px solid #ebeff4;
          box-sizing: border-box;
          border-radius: 3.5px;
          color: $text-primary;
          font-weight: 500;
          width: 100%;
        }
      }
      p {
        font-size: 14px;
        color: $text-placeholder;
        margin: 1rem 0;
        @media (min-width: 768px) {
          margin: 1rem 0;
        }
        span {
          font-weight: bold;
          color: $text-primary;
        }
      }
    }
    .button-import-contacts {
      @media (max-width: 767px) {
        display: block;
        width: 100%;
        margin-top: 2rem;
      }
    }
  }
  .live-content {
    height: 75vh;
    position: relative;

    input {
      background: #f9f9fb;
      border: 1px solid #ebeff4;
      box-sizing: border-box;
      border-radius: 3.5px;
    }
    .live-leads-categories {
      height: calc(100vh - 185px);
      background-color: #fff;
      width: 20%;
      h6 {
        font-weight: 600;
        font-size: 12px;
        padding: 12px 24px;
        color: #adb1bd;
      }
      .application-status {
        padding: 12px 24px;
        margin-top: 0;
        margin-bottom: 0;
      }
      .leads-total {
        font-size: 10px;
        padding: 2px 4px;
        text-align: center;
        border: 1px solid #d1d4de;
        color: $primary;
        border-radius: 10px;
        min-width: 1.5rem;
      }
      .active {
        background: #ebeff4;
        .leads-total {
          background: $primary;
          border: none;
          color: #fff;
        }
      }
    }
    .live-leads-results {
      position: relative;
      max-height: 80vh;
      width: 35%;
      padding-bottom: 24px;
      overflow-y: auto;
      @media (max-width: 767px) {
        width: 100%;
        height: 400px;
        overflow-x: hidden;
        overflow-y: auto;
      }
      .lead-search {
        padding: 0 24px;
        margin-bottom: 24px;
      }
      .lead-details {
        .lead {
          // padding: 6px 24px;
          // padding-bottom: 0;
        }
        .lead-detail {
          box-shadow: 0px 2px 6px rgba(101, 107, 118, 0.2);
          border-radius: 4px;
          margin-bottom: 8px;
          & > div {
            padding: 16px;
            padding-bottom: 8px;
          }
          .reachout-button {
            height: 40px;
          }
        }
        .lead-fullname {
          font-size: 14px;
          font-weight: 600;
          color: $text-primary;
          margin-bottom: 8px;
        }
        .lead-last-contact {
          font-size: 13px;
          color: $text-placeholder;
        }
        .lead-phone-address {
          margin: 6px 0;
          li {
            color: $text-secondary;
            font-size: 14px;
            margin-bottom: 12px;
            i {
              color: $text-placeholder;
            }
          }
        }
        .border-left-potential {
          border-left: 2px solid $secondary-purple;
        }
        .border-left-incoming {
          border-left: 2px solid $secondary-orange;
        }
        .border-left-application-sent {
          border-left: 2px solid $secondary-yellow;
        }
        .border-left-application-received {
          border-left: 2px solid $secondary-blue;
        }
        .border-left-waitlisted {
          border-left: 2px solid $secondary-teal;
        }
        .border-left-accepted {
          border-left: 2px solid $secondary-purple2;
        }
        .border-left-enrolled {
          border-left: 2px solid $secondary-lead-green;
        }
        .border-left-archived {
          border-left: 2px solid $text-placeholder;
        }
      }
    }
  }
  .live-map {
    position: relative;
    width: calc(100%);
    height: 100%;

    @media (max-width: 767px) {
      height: 450px;
    }
    .live-filters-map {
      position: absolute;
      z-index: 9;
      background: #fff;
      box-shadow: 0 2px 6px rgba(101, 107, 118, 0.2);
      border-radius: 4px;
      padding: 5px 10px;
      top: 24px;
      right: 24px;
      left: 62px;
      @media (max-width: 767px) {
        left: 42px;
      }
      .live-filters-inputs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (min-width: 1024px) {
          justify-content: flex-start;
        }
        .input-group {
          flex-wrap: nowrap;
        }
        .live-filters-content {
          position: relative;
          margin: 5px 0;
          margin-right: 6px;
          .custom-select {
            display: flex;
            align-items: center;
            font-size: 12px;
            label {
              margin-bottom: unset;
            }
          }
        }
        .input-group-text {
          background: $fill-hover;
          font-size: 12px;
          font-weight: 600;
          margin-bottom: unset;
        }
        .input-group-prepend {
          text-transform: uppercase;
        }
      }
      .filter-button {
        min-width: 100px;
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        color: $primary;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .hide-button {
      cursor: pointer;
      background: #0094cd;
      border-radius: 0 4px 4px 0;
      height: 36px;
      width: 26px;
      position: absolute;
      left: 0;
      top: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      i {
        color: #fff;
      }
    }
  }

  .modal-request-reachout {
    .modal__content {
      width: 90% !important;
      border-radius: 4px;
      @media (min-width: 1024px) {
        width: 70% !important;
      }
    }
    .modal__header {
      display: none;
    }
    .modal__children {
      padding: 0;
    }
    .lead-data {
      font-size: 14px;
      font-size: 14px;
      .row {
        margin-bottom: 20px;
        margin-bottom: 20px;
      }
      .label-data-lead {
        color: $text-secondary;
        color: $text-secondary;
      }
      .info-data-lead {
        color: $text-primary;
        color: $text-primary;
      }
    }
    .modal-body {
      padding: 24px;
      padding-bottom: 0;
    }
    .tabs-container {
      min-height: 100% !important;
      background: #f4f6f8 !important;
      box-shadow: none;
    }
    .gravatar {
      i {
        color: $schola-gray;
        font-size: 32px;
      }
    }
    .modal-header,
    .lead-data,
    .modal-footer {
      padding: 24px;
      border-bottom: 1px solid $fill-border;
      justify-content: space-between;
    }
    .modal-footer,
    .tabs-navigation {
      overflow-x: unset;
      .tab-item {
        background-color: #fff !important;
      }
      .tab-item-active {
        background-color: #f4f6f8 !important;
        box-shadow: none;
      }
    }
  }
  .request-status {
    background-color: #eaecf0;
    border-radius: 20px;
    padding: 4px 10px;
    margin-left: 2rem;
    &.lead-listing {
      margin-left: 0;
      padding: 4px 7px;
      text-align: center;
    }
  }
  .history {
    position: relative;
    .history-counter-tag {
      position: absolute;
      background: #2f4871;
      padding: 3px;
      border-radius: 20px;
      color: #fff;
      font-size: 10px;
      top: 15px;
      left: 5px;
    }
  }
  .modal-more-filters {
    .schola-modal-child {
      padding: 0;
      .schola-modal-child-header,
      .schola-modal-child-close {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 1.5rem;
      }
    }
    .modal__content {
      width: 90% !important;
      border-radius: 4px;
    }
    .modal__header {
      display: none;
    }
    .modal__children {
      padding: 0;
    }
    .modal-header,
    .learning-models,
    .focus,
    .languages,
    .school-features,
    .sports,
    .special-education,
    .religiously-affiliated,
    .clubs {
      padding: 24px;
      border-bottom: 1px solid $fill-border;
      & > p {
        font-size: 16px;
      }
    }
    .content {
      border-bottom: 1px solid $fill-border;
      height: 437px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0;
      p {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 600;
      }
      .form-row {
        p {
          font-weight: 600;
          margin-top: 24px;
          font-size: 14px;
        }
      }
      label {
        font-size: 14px;
        font-weight: 400;
        &.checkbox {
          display: flex;
          &:focus {
            outline: none;
          }
        }
      }
      .checkbox-label {
        margin-left: 16px;
      }
      input[type='checkbox'] {
        display: none;
      }
      .form-check {
        padding-left: 0;
        &:focus {
          outline: none;
        }
      }
      .checkbox-label,
      .checkbox-icon {
        float: none;
      }
    }
    .modal-footer {
      padding: 16px 24px;
      justify-content: space-between;
    }
  }
  .modal-add-address {
    h3 {
      margin-bottom: 24px;
    }
    .modal__content {
      width: 90% !important;
      border-radius: 4px;
      @media (min-width: 1024px) {
        width: 400px !important;
      }
    }
    .modal__header {
      display: none;
    }
    .modal__children {
      padding: 0;
    }
    .modal-footer {
      display: flex;
      justify-content: space-between;
    }
    .geosuggest__input {
      outline: none;
    }
    .geosuggest__suggests-wrapper {
      ul {
        max-height: 130px;
      }
    }
    .user-data {
      width: 100%;
    }
  }
  .dashboard-main-content-v2 {
    padding: 8px 24px;
    .head-title {
      margin-bottom: 3px !important;
      h1 {
        margin-bottom: 0;
        padding: 0;
        font-size: 28px;
      }
    }
  }
  .live-filters-inputs {
    .form-check {
      margin-bottom: 12px;
    }
  }
  .img-loading-search {
    position: fixed;
    z-index: 99;
    top: 50%;
    left: 45%;
    width: 200px;
  }
}
.filters-options {
  width: 235px;
  background: #fff;
  box-shadow: 0 4px 12px rgba(101, 107, 118, 0.2);
  position: absolute;
  top: 35px;
  border-radius: 3.5px;
  z-index: 999;
  left: 0;
  .search-grades {
    input {
      background: #fff;
      border-bottom: 1px solid $fill-border;
      height: 45px;
      border-radius: 0;
      width: 235px;
    }
  }
  .checkbox-options {
    height: 100%;
    overflow-y: auto;
    .form {
      padding: 14px;
      label {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .filters-buttons {
    height: 45px;
    display: flex;
    justify-content: space-between;
    box-shadow: 2px 0 6px rgba(101, 107, 118, 0.2);
    padding: 0 12px;
    button {
      font-size: 14px;
      color: $text-disabled;
      font-weight: 600;
      &.active {
        color: $text-primary-blue;
      }
    }
  }
}
.Toastify__toast--info,
.Toastify__toast--success,
.Toastify__toast--error,
.Toastify__toast--warning {
  background: #fff;
  padding: 16px;
  box-shadow: 0px 4px 12px rgba(101, 107, 118, 0.2);
  border-radius: 3.5px;
  color: $text-primary;
  top: 75px;
  right: 30px;
  border-bottom: none !important;
  background: none !important;
  box-shadow: none !important;
  .icon-notification {
    margin-right: 12px;
  }
}
.notification-popup {
  display: flex;
}
.Toastify__toast--success {
  border-bottom: 3px solid $secondary;
  text-align: left;
  i {
    display: none;
  }
}
.Toastify__close-button {
  display: none;
}
.Toastify__toast--error {
  border-bottom: 3px solid $secondary-orange;
  i {
    color: $secondary-orange;
    font-size: 18px;
  }
  img {
    display: none;
  }
}
.Toastify__toast--info {
  border-bottom: none;
  background: none;
  box-shadow: none;
}
.content-bullet-timeline {
  position: relative;
  display: flex;
  justify-content: center;
  .day-ago {
    display: flex;
    justify-content: center;
    width: 40px;
    align-items: center;
  }
  .timeline {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 40px;
    margin-right: 0.5rem;
    i {
      color: $gray-400;
      font-size: 10px;
    }
    &::before {
      top: 10px;
      width: 1px;
      content: '';
      position: absolute;
      height: 100%;
      z-index: -1;
      background: $gray-400;
    }
  }
  .timeline-panel-content {
    background: #fff;
    width: 100%;
  }
}
.textwrap,
.text-wrap {
  overflow-wrap: break-word;
  max-width: 95%;
}

.modal-loading-livesearch {
  .schola-modal-child {
    background: transparent !important;
  }
}
