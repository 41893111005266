.marketing-dashboard__modal {
	z-index: 999 !important;
	.modal__content {
		width: 80% !important;
		max-width: 795px;
		height: auto;
		.modal__header {
			background-color: #fff;
		}
		.modal__children {
			.marketing-dashboard__modal-form {
				.requesttoconnect--container {
					padding-left: 20px;
					padding-right: 20px;
                    @media (min-width: 992px){
                        padding-left: 40px;
                        padding-right: 40px;
                    }
					.list--part {
						width: 100%;
						max-width: initial;
						&.left {
                            @media (min-width: 992px){
                                width: 40%;
                            }
						}
						&.right {
							padding-left: 0;
                            @media (min-width: 992px){
                                width: 60%;
                            }
						}
					}
				}
				div[class^="popup-payment__"] {
					width: 100%;
				}
				.popup-payment__method {
					padding-left: 0;
					margin-bottom: 15px;
                    @media (min-width: 992px){
                        width: 45% !important;
                        padding-right: 30px;
                        margin: 0;
                    }
					.input--holder {
                        @media (min-width: 992px){
                            margin-top: 15px;
                            margin-top: 25px;
                        }
					}
					.card-input {
						width: 100%;
					}
					.input--card--save {
						position: relative;
						.checkbox-icon {
							vertical-align: middle;
							display: inline-block;
						}
						.checkbox-label {
							top: 1px;
							margin: 10px 0 0 5px;
							display: inline-block;
							position: relative;
						}
						input[type="checkbox"] {
							left: -999px;
							visibility: hidden;
							position: absolute;
						}
					}
				}
				.popup-payment__order {
					margin-top: 15px;
                    @media (min-width: 992px){
                        width: 55% !important;
                        margin: 0;
                    }
					.order__container {
						margin-top: 15px;
                        @media (min-width: 992px){
                            margin-top: 25px;
                        }
					}
				}
			}
		}
		.requesttoconnect__footer {
			text-align: center;
			padding-top: 15px;
			position: initial !important;
            @media (min-width: 992px){
                text-align: right;
            }
		}
	}
}
.request-to-connect__modal {
	.requesttoconnect-form {
		div[class^="popup-payment__"] {
			width: 100% !important;
		}
		.popup-payment__method {
			padding-left: 0 !important;
			margin-bottom: 15px !important;
            @media (min-width: 992px){
                width: 45% !important;
                padding-right: 30px !important;
                margin: 0 !important;
            }
			.input--holder {
				margin-top: 15px !important;
                @media (min-width: 992px){
                    margin-top: 25px !important;
                }
			}
			.card-input {
				width: 100% !important;
			}
			.input--card--save {
				position: relative !important;
				.checkbox-icon {
					vertical-align: middle !important;
					display: inline-block !important;
				}
				.checkbox-label {
					top: 1px !important;
					margin: 10px 0 0 5px !important;
					display: inline-block !important;
					position: relative !important;
				}
				input[type="checkbox"] {
					left: -999px !important;
					visibility: hidden !important;
					position: absolute !important;
				}
			}
		}
		.popup-payment__order {
			margin-top: 15px !important;
            @media (min-width: 992px){
                width: 55% !important;
                margin: 0 !important;
            }
			.order__container {
				margin-top: 15px !important;
                @media (min-width: 992px){
                    margin-top: 25px !important;
                }
			}
		}
	}
}
