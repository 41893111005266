.wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  p {
    font-size: 12px !important;
    margin-bottom: 1.5rem !important;
    margin-top: 0.25rem !important;
  }
}

.barsContainer {
  width: 100%;
  height: 77%;
  margin-top: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  gap: 1rem;
}

.custombar {
  width: 15%;
  border-radius: 4px;
  position: relative;
}

.number {
  font-size: 10px;
  font-weight: 700;
  position: absolute;
  top: -12px;
  display: grid;
  place-items: center;
  width: 100%;
}

.label {
  font-size: 10px;
  position: absolute;
  bottom: -20px;
  text-align: center;
  width: -webkit-fill-available;
  display: grid;
  place-items: center;
}

.note {
  color: #00000073;
  font-size: 10px;

  &_link {
    color: #006b8f;
  }
}
