

@use "sass:math";

$radio-padding : 12px;
/* Base */
.radio {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: -($radio-padding); // Account for left alignment due to .radio-icon padding
}

.radio-icon {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 24px + ($radio-padding * 2);
  height: 24px + ($radio-padding * 2);
  border-radius: 100%;
  padding: $radio-padding;
  flex-shrink: 0;
}

.radio-icon:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: $schola-gray;
  transform: scale(0, 0);
  opacity: 0.35;
  transition-property: transform, opacity;
  transition-timing-function: ease-out;
  transition-duration: 0;
}

.radio--checked .radio-icon:after {
  transform: scale(1, 1);
  opacity: 0;
  transition-duration: 500ms;
}

.radio-icon + .radio-label {
  padding-top: $radio-padding;
  padding-right: $radio-padding;
  padding-bottom: $radio-padding;
  padding-left: math.div($radio-padding, 2);
}

/* Hide HTML input */
.radio input[type="radio"] {
  display: none;
}
