.connection-detail__modal {
    width: 100%;
    padding: 0px !important;

    .modal__content{
        width: 800px;
        background: $white;
        box-shadow: 0 3px 6px 0 rgba(0,154,211,0.08), 0 3px 12px 0 rgba(0,0,0,0.06);
        border-radius: 4px;

        @media (min-width: $tablet-portrait) {
            width: 800px;
        }
        @media (min-width: $tablet-landscape) {
            width: 800px;
        }
        @media (min-width: $desktop) {
            width: 800px;
        }

        .modal__header{

            .modal__close {
                display: inline-block;
                cursor: pointer;
                padding-top: 46px;
                padding-right: 20px;
                z-index: 1000; 

                .icon {
                    margin: 4px;
                    fill: $schola-mist;
                }
            }
        }
        .modal__children{
            padding: 0px !important;

                @media (min-width: $tablet-portrait) {
                    padding: 0px;
                }

                @media (min-width: $tablet-landscape) {
                    padding: 0px;
                    padding-top: 0; // Close button in title provides padding-top for desktop
                }

                @media (min-width: $desktop) {
                    padding: 0px;
                    padding-top: 0; // Close button in title provides padding-top for desktop
                }
        }
    }


    .detail-form{
        width: 100%;
        .detail__header__main{
           text-align: center;
            padding: 0px;
            margin: 0px;

                .title{
                    font-family: $sans;
                    color: $primary;
                    line-height: 32px;
                    font-size: 24px;
                    letter-spacing: 0;
                }
            }

        .detail--container{
            padding-left: 50px;
            padding-right: 50px;
            padding-bottom: 50px;
            padding-top: 36px;

            .list--row{
                display: flex;           
                display: -webkit-flex;
                flex-direction: row;     
                -webkit-flex-direction: row;
                flex-grow: 0;            
                -webkit-flex-grow: 0;
                flex-wrap: wrap;         
                -webkit-flex-wrap: wrap;
                width: 100%;
                border-collapse: collapse;
                color: $primary;
                margin-top: 20px;
                // justify-content: space-between;

                &.info{
                    color: $schola-gray;
                    font-size: 12px;
                    .list--cell{
                        text-transform: capitalize;
                    }
                }
            }

            .list--cell{
                // display: flex;
                // flex-grow: 1;            
                // -webkit-flex-grow: 1;
                // text-overflow: ellipsis;
                // padding: 0px 10px;
                // vertical-align: middle;
                // justify-content: center;
                // flex-direction: column;
                // overflow-wrap: normal;

                .list-header{
                    text-transform: uppercase;
                    font-family: $sans;
                    font-size: 14px;
                    color: #9B9B9B;
                    letter-spacing: 0;
                    font-weight: $bold;
                    line-height: 20px;
                }
                .list-info{
                    font-family: $sans;
                    font-size: 18px;
                    color: #4A4A4A;
                    letter-spacing: 0;
                    font-weight: $bold;
                    line-height: 24px;
                    text-align: left;
                    text-transform: none;
                }

                    &.location{
                        width: 423px;
                        text-align: left;
                        margin-right: 15px;
                    }
                    &.distance{
                        width: 240px;
                        text-align: left;
                    }
                    &.grade{
                        width: 150px;
                        text-align: left;
                        margin-right: 15px;
                    }
                    &.top-feature{
                        width: 260px;
                        text-align: left;
                        margin-right: 15px;
                    }
                    &.sub-feature{
                        width: 260px;
                        text-align: left;
                }
            }
        }
    }
}
