.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #6b7280; /* text-gray-500 */
}

.items {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pagination {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  :global(button) {
    font-size: 14px;
  }
}

.pageNumber {
  padding: 0 0.5rem;
}
