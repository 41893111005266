.container {
  border: 1px solid #e2e8f0;
  background: #fff;
  border-radius: .5rem;
  padding: 1rem;
  position: relative;
  margin: 1.5rem auto;

  .step {
    color: #64748b;
  }
}

.layout {
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 1rem;
}
