@import './dropdown/dropdown';
@import './radio/radio';
@import './array-input/array-input';
@import './array-input/event-array-input';
@import './cropper/image-cropper';

// Global cursor hover hacklabel {
label {
    &:hover {
        cursor: pointer;
}
}
.validation-message__wrapper {
	width: 100%;
	& + .validation-message__wrapper {
		margin-top: 16px;
	}
}
.validation-message {
	font-size: 14px;
	color: $schola-carmine;
	margin-top: 4px;
}
.geosuggest__input {
	color: $schola-gray;
	font-family: $sans;
	appearance: none;
}
.upload--form {
	.validation-message__wrapper {
		margin-bottom: 40px;
	}
}
.image-upload__remove {
	display: block;
	margin-top: 8px;
	background: none;
	border: 1px solid $schola-carmine;
	border-radius: 4px;
	color: $schola-carmine;
	transition: all 0.25s ease-in-out;
	&:hover {
		cursor: pointer;
		background: $schola-carmine;
		color: $white;
	}
}
.success {
	color: $secondary;
}
.video-helpertext {
	display: inline-block;
	color: $body-color;
	font-style: italic;
	padding-top: 4px;
}
.img-help-button {
	color: $secondary;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	border: 1px solid $secondary;
	position: absolute;
	top: -2px;
	&:before {
		content: '?';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		font-size: 10px;
		font-weight: 600;
		position: absolute;
	}
}
.school-application__field--required {
	color: #f00;
}
