// fonts and typography styles
//main font
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

//global styles
$font-base: 1rem;
$sans: 'Open Sans', sans-serif !important;
$sans-legacy: 'Lato', sans-serif;
$mono: 'Source Code Pro', monospace;
$montserrat: 'Montserrat', sans-serif;
$lato: 'Lato', sans-serif;
$semibold: 600;
$bold: 700;

@mixin Heading(
  $fontSizeDesktop,
  $lineHeightDesktop,
  $fontSizeMobile,
  $lineHeightMobile,
  $fontweightDesktop: 400,
  $fontweightMobile: 400
) {
  font-size: $fontSizeDesktop + px;
  line-height: $lineHeightDesktop + px;
  font-weight: $fontweightDesktop;
  @media (max-width: 768px) {
    font-size: $fontSizeMobile + px;
    line-height: $lineHeightMobile + px;
    font-weight: $fontweightMobile;
  }
}

.layoutv3 {
  font-family: $sans;
  h1.heading-1,
  h1 {
    color: $dark-blue-100 !important;
    @include Heading(32, 38.4, 30, 36, 600, 600);
  }
  h2.heading-2,
  h2 {
    color: $dark-blue-100 !important;
    @include Heading(28, 33.6, 26, 31.2, 600, 600);
    font-family: $sans;
  }
  h3.heading-3,
  h3 {
    color: $dark-blue-100 !important;
    @include Heading(22, 26.4, 20, 24, 600, 600);
  }
  h4.heading-4,
  h4 {
    color: $dark-blue-100 !important;
    @include Heading(20, 24, 18, 21.6, 600, 600);
    font-family: $sans;
  }
  h5.heading-5,
  h5 {
    color: $dark-blue-100 !important;
    @include Heading(18, 25.2, 16, 19.2, 600, 700);
    font-family: $sans;
  }
  h6.heading-6,
  h6 {
    color: $dark-blue-100 !important;
    @include Heading(16, 22.4, 16, 19.2, 600, 700);
    font-family: $sans;
  }
  .body-large {
    color: $dark-blue-75 !important;
    @include Heading(18, 28.8, 18, 27);
    font-family: $sans;
  }
  .body-regular {
    color: $dark-blue-75 !important;
    @include Heading(16, 25.6, 16, 23.2);
    font-family: $sans;
  }
  .body-small {
    color: $dark-blue-75 !important;
    @include Heading(14, 23.8, 14, 21);
    font-family: $sans;
  }
  .body-xs-small {
    color: $dark-blue-75 !important;
    @include Heading(12, 20.4, 12, 20.4);
    font-family: $sans;
  }
  .text-caption {
    @include Heading(14, 21, 14, 21, 600, 600);
    font-family: $sans;
  }
  .text-overline {
    text-transform: uppercase;
    @include Heading(12, 18, 12, 18, 700, 700);
    font-family: $sans;
  }
  /* a {
        color:$dark-blue-100;
        &:hover {
            color: $navy-blue-100;
        }
    } */
}
.font-600 {
  font-weight: $semibold !important;
}
.font-700 {
  font-weight: $bold !important;
}
.text-left {
  text-align: left !important;
}
.font-16 {
  font-size: 1rem !important;
}
@mixin fontSizeLineHeight($fontSize, $lineHeight, $fontNameSize) {
  font-size: $fontSize !important;
  line-height: $lineHeight !important;

  @if (
    $fontNameSize ==
      'colossus' or
      $fontNameSize ==
      'schola' or
      $fontNameSize ==
      'hero' or
      $fontNameSize ==
      'headline1' or
      $fontNameSize ==
      'headline2' or
      $fontNameSize ==
      'headline3'
  ) {
    @media (max-width: 768px) {
      font-size: fontSize - 35% !important;
      line-height: lineHeight - 35% !important;
    }
  }
}

@mixin fontBold($headingName, $headingColor, $fontFamily) {
  .font-#{$headingName}-#{$headingColor}-bold {
    @if $headingColor == 'primary' {
      color: $primary;
    }
    @if $headingColor == 'secondary' {
      color: $secondary;
    }
    @if $headingColor == 'scholagray' {
      color: $schola-gray !important;
    }
    @if $headingColor == 'light' {
      color: $schola-gray-light;
    }
    @if $headingColor == 'white' {
      color: white;
    }

    font-family: $fontFamily;
    font-weight: 700 !important;

    @if $headingName == 'colossus' {
      @include fontSizeLineHeight(8.75rem, 10.31rem, 'colossus');
    }

    @if $headingName == 'schola' {
      @include fontSizeLineHeight(7rem, 7.18rem, 'schola');
    }

    @if $headingName == 'hero' {
      @include fontSizeLineHeight(5.5rem, 5.75rem, 'hero');
    }

    @if $headingName == 'headline1' {
      @include fontSizeLineHeight(3rem, 3.25rem, 'headline1');
    }

    @if $headingName == 'headline2' {
      @include fontSizeLineHeight(2.3rem, 2.6rem, 'headline2');
    }

    @if $headingName == 'headline3' {
      @include fontSizeLineHeight(1.923rem, 2.25rem, 'headline3');
    }

    @if $headingName == 'headline4' {
      @include fontSizeLineHeight(1.63rem, 1.93rem, 'headline4');
    }

    @if $headingName == 'headline5' {
      @include fontSizeLineHeight(1.33rem, 1.63rem, 'headline5');
    }

    @if $headingName == 'headline6' {
      @include fontSizeLineHeight(1.13rem, 1.5rem, 'headline6');
    }

    @if $headingName == 'subtitle1' {
      @include fontSizeLineHeight(1rem, 1.45rem, 'subtitle1');
    }

    @if $headingName == 'subtitle2' {
      @include fontSizeLineHeight(0.875rem, 1.45rem, 'subtitle2');
    }

    @if $headingName == 'body' {
      @include fontSizeLineHeight(1rem, 1.45rem, 'body');
    }

    @if $headingName == 'input1' {
      @include fontSizeLineHeight(1.125rem, 1.5rem, 'input1');
    }

    @if $headingName == 'input2' {
      @include fontSizeLineHeight(1rem, 1.125rem, 'input2');
    }

    @if $headingName == 'small' {
      @include fontSizeLineHeight(0.875rem, 1.45rem, 'small');
    }

    @if $headingName == 'x-small' {
      @include fontSizeLineHeight(0.75rem, 1rem, 'x-small');
    }

    @if $headingName == 'tiny' {
      @include fontSizeLineHeight(0.625rem, 1rem, 'tiny');
    }

    @if $headingName == 'caption' {
      @include fontSizeLineHeight(0.75rem, 1rem, 'caption');
    }

    @if $headingName == 'overline' {
      @include fontSizeLineHeight(0.625rem, 0.875rem, 'overline');
    }
  }
}
@mixin fontRegular($headingName, $headingColor, $fontFamily) {
  .font-#{$headingName}-#{$headingColor}-regular {
    @if $headingColor == 'primary' {
      color: $primary;
    }
    @if $headingColor == 'secondary' {
      color: $secondary;
    }
    @if $headingColor == 'scholagray' {
      color: $schola-gray !important;
    }
    @if $headingColor == 'light' {
      color: $schola-gray-light;
    }
    @if $headingColor == 'white' {
      color: white;
    }

    font-family: $fontFamily;
    font-weight: 400 !important;

    @if $headingName == 'colossus' {
      @include fontSizeLineHeight(8.75rem, 10.31rem, 'colossus');
    }

    @if $headingName == 'schola' {
      @include fontSizeLineHeight(7rem, 7.18rem, 'schola');
    }

    @if $headingName == 'hero' {
      @include fontSizeLineHeight(5.5rem, 5.75rem, 'hero');
    }

    @if $headingName == 'headline1' {
      @include fontSizeLineHeight(3rem, 3.25rem, 'headline1');
    }

    @if $headingName == 'headline2' {
      @include fontSizeLineHeight(2.3rem, 2.6rem, 'headline2');
    }

    @if $headingName == 'headline3' {
      @include fontSizeLineHeight(1.923rem, 2.25rem, 'headline3');
    }

    @if $headingName == 'headline4' {
      @include fontSizeLineHeight(1.63rem, 1.93rem, 'headline4');
    }

    @if $headingName == 'headline5' {
      @include fontSizeLineHeight(1.33rem, 1.63rem, 'headline5');
    }

    @if $headingName == 'headline6' {
      @include fontSizeLineHeight(1.13rem, 1.5rem, 'headline6');
    }

    @if $headingName == 'subtitle1' {
      @include fontSizeLineHeight(1rem, 1.45rem, 'subtitle1');
    }

    @if $headingName == 'subtitle2' {
      @include fontSizeLineHeight(0.875rem, 1.45rem, 'subtitle2');
    }

    @if $headingName == 'body' {
      @include fontSizeLineHeight(1rem, 1.45rem, 'body');
    }

    @if $headingName == 'input1' {
      @include fontSizeLineHeight(1.125rem, 1.5rem, 'input1');
    }

    @if $headingName == 'input2' {
      @include fontSizeLineHeight(1rem, 1.125rem, 'input2');
    }

    @if $headingName == 'small' {
      @include fontSizeLineHeight(0.875rem, 1.45rem, 'small');
    }

    @if $headingName == 'x-small' {
      @include fontSizeLineHeight(0.75rem, 1rem, 'x-small');
    }

    @if $headingName == 'tiny' {
      @include fontSizeLineHeight(0.625rem, 1rem, 'tiny');
    }

    @if $headingName == 'caption' {
      @include fontSizeLineHeight(0.75rem, 1rem, 'caption');
    }

    @if $headingName == 'overline' {
      @include fontSizeLineHeight(0.625rem, 0.875rem, 'overline');
    }
  }
}
@include fontBold('headline1', 'primary', $montserrat);
@include fontBold('headline1', 'secondary', $montserrat);
@include fontBold('headline1', 'scholagray', $montserrat);
@include fontBold('headline1', 'light', $montserrat);
@include fontBold('headline1', 'white', $montserrat);
@include fontRegular('headline1', 'primary', $montserrat);
@include fontRegular('headline1', 'secondary', $montserrat);
@include fontRegular('headline1', 'scholagray', $montserrat);
@include fontRegular('headline1', 'light', $montserrat);
@include fontRegular('headline1', 'white', $montserrat);

@include fontBold('headline2', 'primary', $montserrat);
@include fontBold('headline2', 'secondary', $montserrat);
@include fontBold('headline2', 'scholagray', $montserrat);
@include fontBold('headline2', 'light', $montserrat);
@include fontBold('headline2', 'white', $montserrat);
@include fontRegular('headline2', 'primary', $montserrat);
@include fontRegular('headline2', 'secondary', $montserrat);
@include fontRegular('headline2', 'scholagray', $montserrat);
@include fontRegular('headline2', 'light', $montserrat);
@include fontRegular('headline2', 'white', $montserrat);

@include fontBold('headline3', 'primary', $montserrat);
@include fontBold('headline3', 'secondary', $montserrat);
@include fontBold('headline3', 'scholagray', $montserrat);
@include fontBold('headline3', 'light', $montserrat);
@include fontBold('headline3', 'white', $montserrat);
@include fontRegular('headline3', 'light', $montserrat);
@include fontRegular('headline3', 'primary', $montserrat);
@include fontRegular('headline3', 'secondary', $montserrat);
@include fontRegular('headline3', 'scholagray', $montserrat);
@include fontRegular('headline3', 'white', $montserrat);

@include fontBold('headline4', 'primary', $montserrat);
@include fontBold('headline4', 'secondary', $montserrat);
@include fontBold('headline4', 'scholagray', $montserrat);
@include fontBold('headline4', 'light', $montserrat);
@include fontBold('headline4', 'white', $montserrat);

@include fontBold('headline5', 'primary', $montserrat);
@include fontBold('headline5', 'secondary', $montserrat);
@include fontBold('headline5', 'scholagray', $montserrat);
@include fontBold('headline5', 'light', $montserrat);
@include fontBold('headline5', 'white', $montserrat);

@include fontBold('headline6', 'primary', $montserrat);
@include fontBold('headline6', 'secondary', $montserrat);
@include fontBold('headline6', 'scholagray', $montserrat);
@include fontBold('headline6', 'light', $montserrat);
@include fontBold('headline6', 'white', $montserrat);

@include fontBold('subtitle1', 'primary', $montserrat);
@include fontBold('subtitle1', 'secondary', $montserrat);
@include fontBold('subtitle1', 'scholagray', $montserrat);
@include fontBold('subtitle1', 'light', $montserrat);
@include fontBold('subtitle1', 'white', $montserrat);
@include fontRegular('subtitle1', 'scholagray', $montserrat);
@include fontRegular('subtitle1', 'primary', $montserrat);
@include fontRegular('subtitle1', 'secondary', $montserrat);
@include fontRegular('subtitle1', 'light', $montserrat);
@include fontRegular('subtitle1', 'white', $montserrat);

@include fontBold('subtitle2', 'primary', $montserrat);
@include fontBold('subtitle2', 'secondary', $montserrat);
@include fontBold('subtitle2', 'scholagray', $montserrat);
@include fontBold('subtitle2', 'light', $montserrat);
@include fontBold('subtitle2', 'white', $montserrat);
@include fontRegular('subtitle2', 'primary', $montserrat);
@include fontRegular('subtitle2', 'secondary', $montserrat);
@include fontRegular('subtitle2', 'scholagray', $montserrat);
@include fontRegular('subtitle2', 'light', $montserrat);
@include fontRegular('subtitle2', 'white', $montserrat);

@include fontBold('body', 'primary', $lato);
@include fontBold('body', 'secondary', $lato);
@include fontBold('body', 'scholagray', $lato);
@include fontBold('body', 'light', $lato);
@include fontBold('body', 'white', $montserrat);
@include fontRegular('body', 'primary', $lato);
@include fontRegular('body', 'secondary', $lato);
@include fontRegular('body', 'scholagray', $lato);
@include fontRegular('body', 'light', $lato);
@include fontRegular('body', 'white', $montserrat);

@include fontBold('tiny', 'primary', $lato);
@include fontBold('tiny', 'secondary', $lato);
@include fontBold('tiny', 'scholagray', $lato);
@include fontBold('tiny', 'light', $lato);
@include fontBold('tiny', 'white', $lato);
@include fontRegular('tiny', 'primary', $lato);
@include fontRegular('tiny', 'secondary', $lato);
@include fontRegular('tiny', 'scholagray', $lato);
@include fontRegular('tiny', 'light', $lato);
@include fontRegular('tiny', 'white', $lato);

@include fontBold('small', 'primary', $lato);
@include fontBold('small', 'secondary', $lato);
@include fontBold('small', 'scholagray', $lato);
@include fontBold('small', 'light', $lato);
@include fontBold('small', 'white', $lato);
@include fontRegular('small', 'primary', $lato);
@include fontRegular('small', 'secondary', $lato);
@include fontRegular('small', 'scholagray', $lato);
@include fontRegular('small', 'light', $lato);
@include fontRegular('small', 'white', $lato);

@include fontRegular('x-small', 'primary', $lato);
@include fontRegular('x-small', 'secondary', $lato);
@include fontRegular('x-small', 'scholagray', $lato);
@include fontRegular('x-small', 'light', $lato);
@include fontRegular('x-small', 'white', $lato);
