.toastNotification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: fixed !important;
  width: 300px;
  min-height: 85px;
  background: #fff;
  box-shadow: 0px 4px 12px rgba(101, 107, 118, 0.2);
  border-radius: 3.5px;
  right: 45px;
  top: 10px;
  z-index: 9999;
}

.toastContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  width: 300px;
}

.toastIcon {
  padding-left: 1rem;
}

.toastMessage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
}

.toastMessageTitle {
  padding-bottom: 0.5rem;
  font-weight: bold;
}

.toastDismiss {
  cursor: pointer;
  text-align: right;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

.toastBottomLine {
  height: 5px;
  width: 300px;
}

.success {
  background: #99cc33;
}
.error {
  background: red;
}
.warning {
  background: #f0c52e;
}
.info {
  background: #136ac3;
}
