.list__connections{
  padding:25px;

  .list__container--main{
    padding: 0px 0px 25px 0px;
    display: flex;            
    display: -webkit-flex;
    flex-direction: column;     
    -webkit-flex-direction: column;
    flex-grow: 0;            
    -webkit-flex-grow: 0;

  .dash__list{
      background-color: $white;
      border-radius: $card-border-radius;
      box-shadow: $card-box-shadow;
      margin: 16px 24px;
      .list--header{
        padding: 16px 32px 13px 32px;
        text-transform: uppercase;
        color: $schola-gray-sub;
        font-weight: $bold;
      }
    }

    .list--header--control{
       background-color: $white;
        padding-left: 32px;
        float: left;
        font-size: 14px; 
        letter-spacing: 0;

        .control{
          margin: 0px;
          text-transform: capitalize;
          background-color: $white;
          text-align: left;
          min-width: 65px;
          line-height: 24px;
          padding: 0px 17px 0px 0px;
          font-weight: $medium;
          color: #9B9B9B;
          cursor: pointer;
          border: none;
          border-radius: 0;

          .button__children{
            padding-bottom: 5px;
          }
          &:focus {
            outline: 0;
          }
          &.active{
            .button__children{
               span {
                  height: 18px;
                  color: $primary;
                  border-bottom: 1px solid $primary;
                }

                span:after {
                  height: 18px;
                  position: relative;
                  width: 100%;
                  margin-top: 20px;
                }
            }
          }
        }
    }

    .modal__close{
        padding: 0px;

        .icon {
            margin: 0px;
            fill: $schola-mist;
        }
    }

    .list--container{
      margin-top: 8px;

      .list--row {
          display: flex;           
          display: -webkit-flex;
          flex-direction: row;     
          -webkit-flex-direction: row;
          flex-grow: 0;            
          -webkit-flex-grow: 0;
          flex-wrap: wrap;         
          -webkit-flex-wrap: wrap;
          width: 100%;
          border-bottom: 1px solid #CFD8DC;
          border-collapse: collapse;
          color: $primary;
          padding-left: 32px;
          padding-right: 24px;
          
          &.header {  
            color: $schola-gray;
            padding-top: 0px;
            padding-bottom: 8px;
            opacity: 0.64;
            font-size: 12px;
            .list--cell{
              text-transform: capitalize;
              font-weight: $bold;
            }
          }

          &.data {
            padding-top: 16px;
            padding-bottom: 16px;

          }
      }

      .list--row--container{
            max-height: 285px;
            overflow-y: auto;
      }

      .list--cell {
        display: flex;
        flex-grow: 1;            
        -webkit-flex-grow: 1;
        text-overflow: ellipsis;
        padding-right: 20px;
        vertical-align: middle;
        justify-content: center;
        flex-direction: column;
        text-transform: uppercase;
        overflow-wrap: normal;
        line-height: 24px;
        font-size: 14px;
        font-weight: $medium;
        -webkit-flex:1;
        -webkit-box-flex:1;
        -moz-flex:1;
        -moz-box-flex:1;
        -ms-flex:1;
        flex:1;
        word-wrap: break-word;

        .button{
          min-height: 24px;
          padding: 0px;
          vertical-align: top;
          border-radius: 12px;
          text-transform: capitalize;
          font-weight: $bold;

        }

        &.date{
          max-width: 30%;
        }
        &.name{
          max-width: 21%;
        }
        &.email{
          max-width: 30%;
        }
        &.phone-number{
          max-width: 30%;
        }
        &.details{
          max-width: 10%;
        }

        &.location{
          max-width: 20%;
        }
        &.distance{
          max-width: 12%;
        }
        &.grade{
          max-width: 14%;
        }
        &.top-feature{
          max-width: 14%;
        }
        &.sub-feature{
          max-width: 25%;
        }
        &.reason{
          max-width: 25%;
        }


        &.location-pending{
          max-width: 25%;
        }
        &.distance-pending{
          max-width: 17%;
        }
        &.grade-pending{
          max-width: 19%;
        }
        &.top-feature-pending{
          max-width: 19%;
        }
        &.sub-feature-pending{
          max-width: 19%;
        }
        
      }

    }
   
    .no_results{
      text-align: center;
      padding: 10px;
    }
  }
}