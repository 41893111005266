.dashboard-navigation {
	top: 0;
	left: 0;
	width: 240px;
	min-height: 100vh;
	padding: 35px 0;
	position: absolute;
	-webkit-transform: translateX(-240px);
	transform: translateX(-240px);
	background-color: #0299d3;
	-webkit-transition: -webkit-transform 0.2s ease-in-out;
	transition: -webkit-transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
	z-index: 97;
	&.open {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	.dashboard-navigation--content {
		height: 100%;
		overflow: auto;
	}
	.dashboard-navigation__user {
		text-align: center;
		.avatar-name {
			width: 42px;
			height: 42px;
			margin: auto;
			line-height: 43px;
			border-radius: 50%;
			background-color: #fff;
			.sidebar__user-img {
				width: 100%;
				height: auto;
				border-radius: 50%;
			}
			span {
				color: #0299d3;
				font-size: 14px;
				font-family: 'GothamBold';
				text-transform: uppercase;
			}
		}
		.user-email {
			color: #fff;
			font-size: 12px;
			font-weight: 500;
			text-transform: uppercase;
			margin-top: 15px;
			display: block;
		}
	}
	.dashboard-navigation__nav {
		margin-top: 50px;
		.dashboard_navigation__nav-list {
			box-shadow: none;
			li {
				overflow: hidden;
				a {
					color: #fff;
					font-size: 13px;
					text-decoration: none;
					height: 43px;
					padding: 14px 35px;
					display: block;
					position: relative;
					-webkit-transition: -webkit-box-shadow 0.15s ease-in-out;
					transition: -webkit-box-shadow 0.15s ease-in-out;
					transition: box-shadow 0.15s ease-in-out;
					transition: box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
					&:before {
						content: '';
						top: 0;
						left: 0;
						width: 7px;
						height: 100%;
						opacity: 0;
						display: block;
						position: absolute;
						-webkit-transition: opacity 0.15s ease-in-out;
						transition: opacity 0.15s ease-in-out;
						background-color: $secondary;
					}
					.badge {
						font-size: 10px;
						font-weight: 300;
						text-align: center;
						line-height: 19px;
						width: 17px;
						height: 17px;
						float: right;
						border-radius: 50%;
						display: block;
						background-color: #ff4949;
					}
				}
				&.separator {
					position: relative;
					padding: 0 35px;
					margin: 30px 0;
					&:before {
						content: '';
						width: 100%;
						height: 1px;
						display: block;
						background-color: #007cac;
					}
				}
			}
			.active {
				display: block;
				background-color: $secondary;
			}
		}
	}
	.dashboard-navigation__contact {
		padding: 45px 35px 0;
		.contact-us {
			color: #fff;
			font-size: 13px;
			text-decoration: none;
			display: block;
			small {
				font-weight: 300;
				margin-top: 8px;
				display: block;
			}
		}
		.logout-button {
			color: #fff;
			font-size: 13px;
			font-weight: 600;
			margin-top: 40px;
			position: relative;
			&:before {
				content: '';
				width: 20px;
				height: 20px;
				margin-right: 12px;
				float: left;
				display: block;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url("https://s3-us-west-2.amazonaws.com/scholamatch-static/logout.png");
			}
		}
	}
	.toggle-dashboard-navigation-button {
		top: 0;
		right: -34px;
		color: #fff;
		width: 35px;
		height: 35px;
		position: absolute;
		background-color: #0299d3;
		&:before {
			content: '\f0c9';
			font-family: 'FontAwesome';
		}
	}
}
.dashboard-navigation .dashboard-navigation__nav .dashboard_navigation__nav-list li:not(.back).active a,
.dashboard-navigation .dashboard-navigation__nav .dashboard_navigation__nav-list li:not(.back):hover a {
	-webkit-box-shadow: inset 0 1px 26px 0 rgba(6,110,150,0.36), 0 2px 4px 0 rgba(0,0,0,0.5);
	box-shadow: inset 0 1px 26px 0 rgba(6,110,150,0.36), 0 2px 4px 0 rgba(0,0,0,0.5);
}
.dashboard-navigation .dashboard-navigation__nav .dashboard_navigation__nav-list li:not(.back).active a:before,
.dashboard-navigation .dashboard-navigation__nav .dashboard_navigation__nav-list li:not(.back):hover a:before {
	opacity: 1;
}
.dashboard-navigation .toggle-dashboard-navigation-button:focus,
.dashboard-navigation .toggle-dashboard-navigation-button:active {
	outline: none;
}
.currentSchoolTitle {
	text-align: center;
	background: #fff;
	margin: 15px;
	padding: 5px;
	color: #0299d3;
	font-weight: 500;
}
@media (min-width: 1200px) {
    .dashboard-navigation {
      width: 270px;
      -webkit-transform: none;
      transform: none;
      -webkit-transition: none;
      transition: none;
      position: relative;
    }
    .dashboard-navigation .toggle-dashboard-navigation-button {
      display: none;
    }
  }