@import '../../sass/color-palette';

.sectionPreview {
  max-width: 90%;
  margin: 0 auto;
  text-align: center;

  @media screen and (min-width: 525px) {
    width: 640px;
  }

  &_title {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px; 
    padding-top: 5rem;
  }

  &_description {
    color: $dark-blue-600;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px;
    margin: 1.5rem 0 !important;
  }

  &_buttons {
    width: 100%;
    margin: 1.5rem auto;

    @media screen and (min-width: 525px) {
      width: 388px;
    }

    & .primary {
      background: $primary-500 !important;
      margin-bottom: 1rem;

      & svg {
        margin-right: .5rem;
      }
    }

    & .secondary {
      color: $primary-500 !important;
      background: none !important;
    }
  }

  &_image {
    margin-top: 1rem;
    width: 90%;
  }
}