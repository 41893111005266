.triggerText {
  font-size: .8rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #3f3f46;
}

.triggerDescription {
  background-color: #faf5ff80;
  border: 1px solid #f3e8ff;
  color: #64748b;
  border-radius: .3rem;
  padding: .5rem;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}
