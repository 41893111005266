.cardsWrapper {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.card {
  border: 1px solid rgba(241, 242, 243, 1);
  border-radius: 0.25rem;
  padding: 0.5rem;

  &_label {
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 1rem;
  }
}

.parentProgress {
  position: relative;
}

.completion {
  display: flex;
  align-items: center;
  gap: 0.25rem;

  position: absolute;
  top: -24px;
  right: 6px;

  & p {
    font-size: 10px !important;
  }
}

.circularProgressBar {
  display: block;
}

.circularProgressBar circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.tooltip {
  width: 190px;
  line-height: 1.3rem;

  & b {
    font-weight: 700;
  }
}
