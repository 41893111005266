@use '../../../../../sass/color-palette' as palette;

.tableContainer {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  overflow-y: hidden;
  overflow-x: scroll;
  font-weight: 500;
  width: 100%;
}

.table {
  width: 100%;
  min-width: 1440px;
}

.tableHeader {
  border-bottom: 1px solid #e5e7eb;
  background-color: #f9fafb;
}

.tableHeaderCell {
  text-align: left;
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: palette.$primary-600;
}

.tableRow {
  border-bottom: 1px solid #f3f4f6;
  &:last-child {
    border-bottom: 0;
  }
  &:hover {
    background-color: #f9fafb;
  }
}

.tableCell {
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  color: palette.$primary-600;
}

.tableCellPrimary {
  font-weight: 600;
  color: #111827;
  text-transform: capitalize;
}

.tableCellSecondary {
  color: palette.$primary-600;
  font-weight: 500;
}

.actionsCell {
  text-align: right;
  padding: 1rem 1.5rem;
  display: flex;
  gap: 0.5rem;
}

.footer {
  padding: 1.5rem;
  border-top: 1px solid #f3f4f6;
  background-color: #f9fafb;
}

.footerText {
  font-size: 0.875rem;
  color: palette.$primary-600;
}

.footerActions {
  display: flex;
  gap: 1rem;
}

.button {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  &:hover {
    background-color: #f9fafb;
  }
}

.buttonDisabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.modal {
  .modalContent {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .modalDescription {
    font-size: 0.875rem;
    color: palette.$primary-600;
  }
  h4 {
    font-size: 1.125rem;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
  }
  .description {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  .sourceTag {
    font-weight: 700;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
