.about-campaign-estimator-page {
    h2 {
        margin: 0 auto 32px auto;
        line-height: 1.25;
        text-align: center;
    }
}

.ace--body {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    text-align: center;

    @media (min-width: $tablet-portrait) {
        width: 75%;
        margin: 0 auto;
    }
}

.ace--button {
    margin-top: 64px;
}

.sample-graph {
    display: block;
    margin: 40px auto;
    width: 100%;
    height: 100%;

    @media (min-width: $tablet-portrait) {
        width: 353px;
        height: 206px;
    }

    @media (min-width: $tablet-landscape) {
        width: 600px;
        height: 373px;
    }
}

.reportcard-mini {
    display: block;
    margin: 40px auto;
    width: 100%;
    height: 100%;

    @media (min-width: $tablet-portrait) {
        width: 353px;
        height: 206px;
    }

    @media (min-width: $tablet-landscape) {
        width: 531px;
        height: 202px;
    }
}
