$family-medium: 'Lato', sans-serif;
$family-semibold: 'Lato', sans-serif;
.claim-page-v2,
.claim-modal-v3 {
  background: #fff;
  .background-top-blue {
    background-image: url('https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/claim/bg-header-claim-2.png');
    background-color: #005c7a;
    background-position-x: right;
    background-position-y: center;
    background-repeat: repeat-y;
    height: 370px;
  }
  .title-form-columns {
    margin-top: -12.5rem;
    .text-description {
      color: #fff;
      font-size: 14px;
      margin-bottom: 36px;
      @media (min-width: 768px) {
        margin-bottom: 60px;
      }
    }
  }
  .aboutSchola {
    display: flex;
    background-color: #fff;
    border-radius: 3.5px;
    box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    flex-direction: column;
    @media (min-width: 960px) {
      flex-direction: row;
      height: 175px;
    }
    .about-schola-img {
      img {
        @media (max-width: 960px) {
          width: 100%;
        }
      }
    }
  }
  .enrollment-points {
    margin-top: 46px;
    p {
      color: #44464b;
    }
    .d-flex {
      margin-bottom: 36px;
    }
  }
  .section {
    margin: 70px 0;
  }
  .hero-section-claim-v3 {
    padding-bottom: 2rem;
    @media (min-width: 768px) {
      position: relative;
      min-height: 1100px;
    }
  }
  .claim-form-column {
    margin-top: 36px;
    @media (min-width: 767px) and (max-width: 900px) {
      margin-top: 0;
    }
    @media (min-width: 768px) {
      right: 0;
    }
    h3 {
      &.title-form {
        font-size: 24px !important;
        font-weight: bold;
      }
    }
    h4 {
      margin-top: 36px;
      margin-bottom: 24px;
      font-size: 18px;
    }
    input {
      min-height: 39px !important;
    }
    .select-state {
      select {
        height: 39px !important;
        margin-bottom: 24px;
      }
    }
    label,
    p.p-label,
    p.panel-label {
      font-size: 12px;
      color: #6c6d70;
      font-weight: 500;
      margin-bottom: 4px;
      display: block;
    }
  }
  .modal-confirm {
    color: #44464b;
    .modal__header {
      display: none;
    }
    .modal__children {
      padding: 0;
    }
    .icon-sending {
      padding: 3rem;
      background-color: #bfe4f3;
    }
    .sending-content {
      padding: 0 3rem 0 2rem;
      margin-bottom: 1rem;
      button {
        max-width: 128px;
        font-size: 14px;
        padding: 1rem 0.5rem;
      }
    }
  }
  .modal-about-schola {
    color: #44464b;
    .modal__content {
      width: 370px !important;
    }
    .modal__children {
      padding: 0 24px 24px 24px;
    }
    .modal__header {
      padding: 0.5rem 1rem;
      justify-content: space-between;
    }
    button {
      width: 172px;
      padding: 0.5rem;
    }
    .button-claim-school {
      margin-top: 25px;
      .button__children {
        height: 23px;
      }
    }
    .input-one-number {
      max-width: 29px;
      margin-right: 10px;
      padding: 0;
      text-align: center;
      font-weight: bold;
      border-radius: 8px;
    }
  }
  .banner-improve {
    background-image: url('https://scholamatch-static.s3.us-west-2.amazonaws.com/schools/How+Can+Schola+Help+Your+School2.png');
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: center;
    background-size: cover;
    color: #fff;
    margin-top: 99px;
    display: flex;
    flex-direction: column;
    height: 626px;
    justify-content: flex-end;
    .play-icon {
      display: flex;
      justify-content: center;
      img {
        width: 200%;
        height: auto;
      }
    }
    .text-welcome-schola {
      padding: 4rem 2rem 2rem;
      @media (min-width: 768px) {
        width: 60%;
        height: 50%;
      }
      h3 {
        margin-top: 36px;
        margin-bottom: 16px;
      }
    }
  }
  .image-dashboard-section {
    background: #bfe4f3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3.5px;
    overflow: hidden;
  }
  .future-students-content {
    padding: 1rem 3rem 1rem 3rem;
    h3 {
      line-height: 41px;
      font-size: 34px;
      font-weight: 600;
      & > p {
        line-height: 32px;
      }
    }
  }
  .saying-about-schola {
    &.section {
      margin-top: 145px;
    }
    .title-sayint-about-schola {
      margin-bottom: 60px;
      padding-bottom: 0 !important;
    }
    .card {
      box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.08);
      border-radius: 3.5px;
      border: none;
      overflow: hidden;
      .card-body {
        padding: 2rem;
        padding-top: 0;
        padding-bottom: 1rem;
        @media (min-width: 768px) {
          min-height: 490px;
        }
        h5 {
          margin-top: 30px;
          margin-bottom: 18px;
        }
        p {
          &.description {
            line-height: 20px !important;
            margin-bottom: 36px !important;
          }
        }
        .logo-school {
          padding-top: 20px;
          padding-bottom: 10px;
        }
        .read-full-case {
          padding-top: 24px;
          border-top: 1px solid #d3d3d3;
        }
      }
    }
  }
  .schedule-button {
    border: 1.5px solid #40afd9;
    box-sizing: border-box;
    font-weight: 700;
    margin-bottom: 1rem !important;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #40afd9;
    border-radius: 3.5px;
    &.selected {
      background: #40afd9;
      border-radius: 10px;
      border: none;
      color: #fdfdfd;
      border-radius: 3.5px;
    }
    &.disable {
      color: #d3d3d3;
      background-color: #ebeff4;
      border-color: #ebeff4;
    }
  }
  .btn-active {
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    margin-top: 8px;
    color: #fff;
    background-color: $primary;
  }
  .btn-disable {
    color: #d3d3d3;
    background-color: #ebeff4;
    border-color: #ebeff4;
    font-weight: 700;
    font-size: 0.8rem;
  }
  .geosuggest__suggests-wrapper {
    background-color: #fff;
    box-shadow: none !important;
    margin-bottom: 1rem;
  }
  .geosuggest__item {
    padding: 16px;
    line-height: 1.25;
    font-size: 0.8rem;
    &:hover {
      background: $green-legacy;
    }
  }
}
.claim-page-v3 {
  .modal-request-demo {
    .modal__children {
      padding: 24px;
    }
    .modal__header {
      justify-content: space-between;
      padding: 24px;
      padding-bottom: 0;
      .modal__title {
        color: #44464b;
        font-size: 24px;
        font-weight: 600;
        margin-left: 0;
      }
    }
    .content {
      padding-top: 24px;
    }
  }
  .title-montserrat {
    font-family: 'Montserrat' !important;
  }
  h2 {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .title-form-columns {
    margin-top: 75px;
    .title-description {
      margin-top: 3rem;
      @media (min-width: 768px) {
        margin-top: 212px;
      }
    }
    .text-description {
      @media (min-width: 768px) {
        margin-top: 2rem;
      }
    }
  }
  h3 {
    font-size: 34px !important;
    text-align: center;
    font-weight: 600;
  }
  .welcome {
    margin-bottom: 48px;
  }
  .section-welcome {
    margin-bottom: 65px;
    @media (min-width: 768px) {
      margin-top: 165px;
      margin-bottom: 125px;
    }
  }
  .section {
    h3 {
      padding-bottom: 90px;
      &.welcome-title {
        padding-bottom: 0;
      }
    }
    .text-welcome-schola {
      h3 {
        text-align: left;
        padding-bottom: 0;
      }
    }
  }
  h4 {
    margin-bottom: 7px;
  }
  h5 {
    &.welcome-subtitle {
      font-size: 24px;
      color: #6c6d70;
      font-weight: 400;
      line-height: 32px;
    }
  }
  .background-top-blue {
    background-image: url('https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/claim/claim-v3-landing-02.jpg');
    height: 100%;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
  }
  .button-learn-more {
    min-width: 206px;
    margin-top: 88px;
  }
  .student-recruitmnet-hub {
    &.section {
      margin-top: 0;
    }
    img {
      margin-bottom: 1.5rem;
    }
    p {
      font-size: 16px;
      color: #6c6d70;
    }
  }
  .free-premium-services {
    margin-bottom: 63px;
    &.section {
      margin-top: 65px;
      @media (min-width: 768px) {
        margin-top: 120px;
      }
    }
    .service-row {
      display: flex;
      border-bottom: 1px solid #dfe4ea;
      .service-name {
        p {
          font-size: 16px !important;
          font-weight: 400;
        }
        i {
          color: #adb1bd;
          cursor: pointer;
          &.rotate {
            transform: rotate(90deg);
          }
        }
      }
      .service-cel {
        flex-grow: 2;
        font-size: 25px;
        font-weight: 600;
        text-align: left;
        border-radius: 4px 4px 0 0;
        color: #44464b;
        padding: 10px;
        @media (min-width: 768px) {
          padding: 10px 24px 10px 40px;
        }
        &.cel-empty,
        &.free {
          background-color: #0098cf;
          margin-right: 6px;
          color: white;
          padding: 12px;
          font-size: 18px;
          width: 50%;
          @media (min-width: 768px) {
            padding: 24px;
            font-size: 25px;
            margin-right: 12px;
          }
        }
        &.premium {
          color: #fff;
          background-color: #0c5e79;
          margin-left: 6px;
          color: #fff;
          padding: 12px;
          font-size: 18px;
          width: 50%;
          @media (min-width: 768px) {
            margin-left: 12px;
            padding: 24px;
            font-size: 25px;
          }
        }
        &.check-icon-free {
          margin-right: 12px;
        }
        &.check-icon-premium {
          margin-left: 12px;
        }
        &.check-icon-free,
        &.check-icon-premium {
          display: flex;
          justify-content: center;
          background: rgba(0, 152, 207, 0.05);
        }
        @media (min-width: 768px) {
          &.free,
          &.premium,
          &.check-icon-free,
          &.check-icon-premium {
            width: 30%;
          }
          &.service-name {
            width: 40%;
          }
        }
      }
      &.title-row {
        .service-cel {
          color: #44464b;
          background: #ebeff4;
        }
      }
    }
    .service-row-name {
      @media (max-width: 767px) {
        flex-wrap: wrap;
        .service-cel {
          &.service-name {
            flex-grow: 1;
            flex-basis: 100%;
            flex-wrap: wrap;
            font-size: 14px;
          }
          &.check-icon-free {
            margin-right: 0;
          }
          &.check-icon-premium {
            margin-left: 0;
          }
        }
      }
    }
  }
  .feature-schola {
    background: #f5f7f9;
    padding-top: 96px;
  }
  .icon-recruitment-hub {
    height: 76px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .background-bottom-blue {
    background-image: url('https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/claim/bg-header-claim-2.png');
    background-color: #005c7a;
    background-position-x: right;
    background-position-y: center;
    background-repeat: repeat-y;
    height: 322px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .claim-school-booton {
    h3 {
      font-size: 24px;
      color: #fff;
      text-align: center;
      margin-bottom: 53px;
      @media (min-width: 768px) {
        font-size: 34px;
      }
    }
  }
  .button--size--small {
    padding: 12px 24px !important;
    font-weight: 600 !important;
  }
  .DayPicker {
    background: #fdfdfd;
    border: 1px solid #ebeff4;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
  }
  .DayPicker-Weekday {
    padding: 1.1rem;
    abbr {
      color: $green-legacy;
    }
  }
  .DayPicker-Day {
    padding: 24px 0.5rem;
  }
  .button-request-demo-student-recruitment {
    margin-top: 83px;
    margin-bottom: 83px;
  }
  a {
    text-decoration: none;
  }
}
.checkboxes-goals-can-schola-help {
  margin-bottom: 14px;
  textarea {
    margin-top: 14px;
  }
  .checkboxes-goals-title {
    margin-bottom: 43px;
  }
  label {
    font-family: $family-medium;
    font-size: 14px;
    color: #000;
    font-weight: 500;
  }
  .form-check-input {
    min-height: 0;
    margin-top: 0px;
  }
  .form-check-label {
    padding-bottom: 8px;
  }
}
.position--absolute {
  position: unset !important;
  @media (min-width: 768px) {
    position: absolute !important;
  }
}
.section-welcome {
  &.mt-116 {
    margin-top: 65px;
    @media (min-width: 768px) {
      margin-top: 116px;
    }
  }
}

.school-dropdown__menu {
  left: 0;
  z-index: 2;
  border-radius: 3px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background: hsla(0, 0%, 100%, 0.9);
  padding: 2px 0;
  font-size: 90%;
  width: 100%;
  overflow: auto;
  min-height: 100%;
}
.school-dropdown__results {
  padding: 10px;
  cursor: pointer;
  z-index: 4;
}
