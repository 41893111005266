.needToUpgrade{
      height: 100vh; 

    .needToUpgrade-image{
        background-image: url('https://s3-us-west-2.amazonaws.com/scholamatch-static/Marketing-Dashboard.jpg');

      /* Center and scale the image nicely */
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      height: 100%; 

    }

    .needToUpgrade-popup{
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #4a4a4a;

      // Holds main modal content
      // fullscreen on mobile/tablet with a centered box for desktop
      .modal__content {
        box-shadow: 0 3px 6px 0 rgba(0,154,211,0.08), 0 3px 12px 0 rgba(0,0,0,0.06);
        border-radius: 4px;
          position: relative;
          width: 100%;
          height: 100%;
          background: $white;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
          z-index: 99;

          @media (min-width: $tablet-landscape) {
              max-width: 900px;
              height: auto;
              box-shadow: $card-box-shadow;
          }

          @media (min-width: $desktop) {
              max-width: 1024px;
              height: auto;
              box-shadow: $card-box-shadow;
          }
      }

      // Holds close button and title
      // blue bar on mobile/tablet with a hidden title + right-aligned close button on desktop
      .modal__header {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          @media (min-width: $tablet-landscape) {
              justify-content: center;
              background: transparent;
          }
      }

      .modal__title {
          display: inline-block;
          font-size: 20px;
          font-weight: $medium;
          margin-left: 24px;
          text-align: center;
          padding: 0px;
          margin: 0px;
          font-family: $sans;
          color: white;
          line-height: 32px;
          font-size: 24px;
          letter-spacing: 0;

      }

      // Main content, passed into the modal as children
      .modal__children {
          padding: 8px 16px;

          @media (min-width: $tablet-portrait) {
              padding: 12px 24px;
          }

          @media (min-width: $tablet-landscape) {
              padding: 16px 32px;
          }

          @media (min-width: $desktop) {
              padding: 24px 48px;
          }
      }

      .billing-payment-description {
          text-align: center;
          font-weight: $medium;
          line-height: 24px;

          .payment-description__emphasized {
              color: $secondary;
          }
      }

      .billing-payment-button {
          text-align: center;
          margin-top: 48px;
      }

  }
}