.buttonIcon {
  svg {
    color: #9ca3af; /* text-gray-400 */
    &:hover {
      color: #4b5563; /* text-gray-600 */
    }
  }
}

.dropdownMenuContent {
  width: 12rem; /* w-48 */
}

.publishOption {
  color: #16a34a; /* text-green-600 */
  &:focus {
    color: #16a34a; /* focus:text-green-600 */
  }
}

.deleteOption {
  color: #dc2626; /* text-red-600 */
  &:focus {
    color: #dc2626; /* focus:text-red-600 */
  }
}

.icon {
  height: 1rem; /* h-4 */
  width: 1rem; /* w-4 */
  margin-right: 0.5rem; /* mr-2 */
}

.actionWrapper {
  position: relative;
}

.triggerButton {
  display: flex;
  align-items: center;
  gap: 8px;
}

.statusBadge {
  padding: 4px 8px;
  background-color: rgba(0, 122, 255, 0.1);
  color: rgba(0, 122, 255, 1);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
}

.iconButton {
  padding: 8px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.editIcon {
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
}

.dropdownContent {
  width: 150px;
  padding: 8px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  span {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    margin-right: 0.7rem;
  }
}

.menuIcon {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.deleteItem {
  color: #ff3b30;

  .menuIcon,
  span {
    color: #ff3b30;
  }

  &:hover {
    background-color: rgba(255, 59, 48, 0.05);
  }
}
