@use '../../../../../sass/color-palette' as palette;

$bg-color: #ffffff;
$border-radius: 0.5rem;
$box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
$padding: 1.5rem;
$space-y: 1.5rem;
$text-lg: 1.125rem;
$font-medium: 500;
$mb-4: 1rem;
$text-gray-500: #6b7280;
$mb-6: 1.5rem;
$grid-gap: 1rem;
$text-sm: 0.875rem;
$grid-cols-2: repeat(2, minmax(0, 1fr));
$relative: relative;
$absolute: absolute;
$right-3: 0.75rem;
$top-1-2: 50%;
$translate-y-1-2: translateY(-50%);
$h-4: 1rem;
$w-4: 1rem;
$bg-button: #006d8f;
$hover-bg-button: #005d7f;
$text-white: #ffffff;
$flex: flex;
$justify-end: flex-end;

.heading {
  font-weight: 700 !important;
  margin-bottom: $mb-4;
  font-size: 1.125rem !important;
}

.description {
  color: $text-gray-500;
  margin-bottom: $mb-6;
}

.inputGroup {
  margin-bottom: $space-y;
}

.label {
  font-size: $text-sm;
  color: palette.$dark-blue-600;
}

.gridCols2 {
  display: grid;
  grid-template-columns: $grid-cols-2;
  gap: $grid-gap;
}

.relativeInput {
  position: relative;
}

.calendarIcon {
  position: absolute;
  right: $right-3;
  top: $top-1-2;
  transform: $translate-y-1-2;
  height: $h-4;
  width: $w-4;
  color: palette.$dark-blue-500;
}

/* .button {
  background-color: $bg-button;
  &:hover {
    background-color: $hover-bg-button;
  }
  color: $text-white;
} */

.flexEnd {
  display: flex;
  justify-content: flex-end;
}
.row {
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
}
.title {
  margin-bottom: 16px;
}

.description {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 24px;
}
.halfWidth {
  flex: 1;
  min-width: 0; // Prevents flex items from overflowing
}
.input,
.datePicker {
  width: 100%;
  border: 1px solid palette.$border-color !important;
  &::placeholder {
    color: gray;
  }
}
.submitButton {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
