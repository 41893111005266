.formLayout {

  &_buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

.formWaitlist {
  max-width: 550px;
  max-height: 550px;
  overflow-y: scroll;
  padding-right: 1rem;
}

.labelAdapter {
  width: 100%;
  position: relative;

  &:last-child {
    &::after {
      content: 'Optional';
      position: absolute;
      top: 0;
      right: 0;
      color: #96A3B8;
      font-size: .8rem;
    }
  }

}

.inputSearch {
  width: 100%;

  &_label {
    color: #546982;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;

  }

  & input {
    color: #6E7B91;
    font-size: 0.875rem;
    width: 100%;
    height: 35px;
    padding: 0 10px;
    border-radius: 3.5px;
    background-color: #fff;
    border: 0.7px solid #dddfe7;
  }
}

.searchSchool {
  position: relative;
}

.dropContainer {
  background: #FFF;
  position: absolute;
  top: 62px;
  z-index: 1;
  width: 100%;
  border-radius: .5rem;
  box-shadow: 0px 2px 6px rgba(33, 53, 86, 0.08);
}

.listSearch {
  border: 1px solid rgba(47, 72, 113, 0.1);
  max-height: 300px;
  overflow-y: scroll;

  
  & li {
    padding: .5rem 0;
    border-bottom: 1px solid #E8EAEE;
    cursor: pointer;
    
    .schoolItem {
      border-radius: 8px;
      padding: 1rem;
      display: flex;
      align-items: center;
      gap: 1rem;

      & strong {
        font-weight: bold;
      }
      
      &:hover {
        background: #F2F5FB;
      }
    }
    

    &:last-child {
      border-bottom: none;
    }
  }
}

.notFound {
  border: 1px solid rgba(47, 72, 113, 0.1);
  height: 370px;
  padding: 2rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  & img {
    width: 45%;
  }
}

.errorLabel {
  color: #ff444f;
  font-size: 12px;
  margin: 0.3rem 0.2rem;
}