.event-array-input__wrapper {

  .event-array-input__date{
    background: $primary;
    color: $white;
    height: 64px;
    width: 64px;    
    .event-array-input__date__child{
      padding: 17px 10px;
      text-align: center;
      font-size: 14px;
      font-weight: $bold;
    }
  }

  .event-array-input__desc{
    border: solid 1px $schola-mist;
    width: 100%;
    height: 64px;
    padding: 5px;
    display: inline-flex;
    border-top: none;
  
    


    .text-container{
      font-size: 10px;
      line-height: 16px;
      width: 80%;
      align-items: center;
      display: flex;
    }
    .text-container-tooltip{
      left: 25%;
      width: 25%;
    }

    .btn-container{ 
      width: 20%;
      display: flex;
      .button .icon{
        height: 24px;
        width: 24px;
      }
      .icon--edit{
        svg{
          fill: $primary;
        }
      }
      
    }


  }



}

.event-array-input--show{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1px;

    button {
        cursor: pointer;
    }
    
    &:first-of-type{//setting border for 1 px only for each element
      .event-array-input__desc{
        border-top: solid 1px $schola-mist;
      }
    }
}

.event-array-input--edit  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1px;

    button {
        cursor: pointer;
    }

    &.new-element{
      margin-top: 24px;
    }

    .icon--check:disabled{
      cursor: not-allowed !important;
      opacity: 0.5;
      svg{
        fill: $schola-gray;
        
      }
    }
    
}


.event-array-input__add .button .icon {
    display: inline-block;
}

.event-array-input__add {
    .icon {
        margin-right: 4px;
    }
}

// .react-datepicker__navigation--previous {
//     left: 10px;
//     border-right-color: #ccc;
// }
// .react-datepicker__navigation {
//     line-height: 1.7rem;
//     text-align: center;
//     cursor: pointer;
//     position: absolute;
//     top: 10px;
//     width: 0;
//     border: 0.45rem solid transparent;
//     z-index: 1;
// }