.dropzoneTwo {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	text-align: center;
	h4 {
		margin-bottom: 8px;
	}
	p {
		font-style: italic;
		margin-bottom: 4px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.validation-message {
		margin-top: 8px !important;
		padding-bottom: 5px !important;
		font-size: 14px !important;
		font-weight: bold;
	}
    &.dropzone--active{
        background: lighten($schola-mist, 80%);
        border-color: darken($schola-mist, 40%);
    }
	.validation-message-error {
		font-size: 12px !important;
		font-weight: bold;
		color: #f00;
	}
}
.loadingIcon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.loadingIconText {
		font-size: 20px;
	}
}
.buttonContainer {
	display: flex;
	justify-content: flex-end;
	padding-top: 15px;
	button {
		&:first-child {
			margin-right: 15px;
		}
	}
}
.icon-edit-delete {
	color: #9e9e9e;
	cursor: pointer;
	float: left;
	margin-left: 5px;
	padding-bottom: 10px;
	&:hover {
		color: #4a4a4a;
	}
}
