@use 'sass:map';

$primary: #006b8f;
$primary-dark: #001f29;
$primary-dark-hover: #00455cff;
$dangerous: #c11f3f;

$themes: (
  light: (
    primary: $primary,
    primary-dark: $primary-dark,
    primary-dark-hover: $primary-dark-hover,
    secondary: #9c3,
    dangerous: #c11f3f,
    dangerous-light: #d62246,
  ),
  dark: (
    primary: $primary,
    primary-dark: $primary-dark,
    primary-dark-hover: $primary-dark-hover,
    secondary: #9c3,
    dangerous: #c11f3f,
    dangerous-light: #d62246,
  ),
);

@function themed($key) {
  @return map-get($theme-map, $key);
}

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    :global(.theme-#{$theme}) & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@mixin theme-aware($key, $color) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      #{$key}: map.get(map.get($themes, $theme-name), $color);
    }
  }
}
