.account-settings__section__wrapper {
    width: 50%;

    .paper__heading {
        margin-bottom: 16px;
    }
}

.account-settings__section {
    width: 100%;
    margin-bottom: 32px;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: $tablet-landscape) {
        width: 45%;
        margin-bottom: 0;
    }
}

.account-settings .validation-message__wrapper {
    margin-bottom: 16px;
}

.account-settings > .paper__heading {
    margin-bottom: 40px;
}

.current-password {
    width: 45%;
}

.account-settings__section--sensitives > * + * {
    margin-top: 40px;
}

.change-email {
    h4 {
        margin-bottom: 16px;
    }
}
