.rangePicker {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0.5rem;
  height: 40px;

  .ant-picker-cell {
    text-align: center;
    padding: 0;
    height: 2.25rem;
    width: 2.25rem;
    font-size: 0.875rem;
    position: relative;

    &.ant-picker-cell-in-view {
      background-color: var(--accent-color);
      color: var(--accent-foreground-color);
    }

    &.ant-picker-cell-selected {
      background-color: var(--primary-color);
      color: var(--primary-foreground-color);
    }

    &.ant-picker-cell-today {
      background-color: var(--accent-color);
      color: var(--accent-foreground-color);
    }

    &.ant-picker-cell-disabled {
      color: var(--muted-foreground-color);
      opacity: 0.5;
    }
  }

  .ant-picker-cell-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.icon {
  font-size: 1rem;
}
