@use '../themes';
@import '../color-palette';

//body legacy background
body {
  background: #f9f9fb;
}

.layoutv2 {
  select.dropdown-select {
    background-color: white !important;
  }
  .dashboard-main-content {
    // margin-top: 75px;
    // @media (min-width: 1200px){
    //     padding: 30px;
    // }
    // @media (max-width: 768px){
    //     padding-top: 4rem;
    // }
    // @media (min-width: 900px){
    //     .paper{
    //          margin-top: 10px;
    //     }
    // }
    .text-view-profile {
      @media (min-width: 768px) {
        margin-left: 10px;
      }
    }
  }
  .dashboard-main-content-profile {
    .schola__multi-value {
      margin: 2px 3px !important;
    }
  }
  a {
    color: themes.$primary;
    &.button {
      text-decoration: none;
      color: #fff;
    }
  }
  .form-add-source,
  .form-add-lead,
  .request-reach-out,
  .sidebar-add-note,
  .form-add-student,
  .panel-lead-details {
    background: #fff;
    height: calc(100vh - 145px);
    width: 380px;
    position: fixed;
    right: 0;
    top: 75px;
    box-shadow: 0px 5px 12px rgba(85, 106, 113, 0.15);
    overflow-y: auto;
    z-index: 10;
    @media (min-width: 768px) {
      width: 380px;
    }
  }
  .add-title {
    padding: 1rem 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dfe4ea;
    z-index: 1;
  }
  button {
    &.button-save {
      width: 100%;
    }
  }
  .react-datepicker__input-container,
  .react-datepicker-wrapper {
    width: 100%;
  }
  .request-reach-out {
    height: calc(100vh - 100px);
  }
  /*     .panel-lead-details {
        height: calc(100vh - 75px);
    } */
  .form-add-source,
  .sidebar-add-note,
  .form-add-lead {
    height: calc(100vh - 75px);
  }
  .form-add-lead {
    .form-group {
      &.radio-buttons {
        label {
          margin-left: 0;
          font-size: 12px;
          color: #6c6d70;
          &.form-check-label {
            margin-left: 8px;
          }
        }
      }
    }
    .label-address {
      color: #6c6d70;
    }
  }
  .info-data {
    padding: 24px;
    border-bottom: 1px solid $fill-border;
    .info-data-row {
      display: flex;
      font-size: 14px;
      margin-bottom: 22px;
      &:last-child {
        margin-bottom: 0;
      }
      .label-data {
        width: 129px;
      }
      .user-data {
        font-weight: 500;
        color: $text-primary;
      }
      .user-data-fullname {
        @extend .user-data;
        color: themes.$primary;
      }
      .user-data-wrap {
        max-width: 150px;
        overflow-wrap: break-word;
      }
      .name-date {
        margin-bottom: 16px;
        font-size: 12px;
        color: #adb1bd;
        margin-top: 0.25rem;
      }
      .text-message {
        font-size: 14px;
        font-weight: 600;
      }
      .notes-content {
        background: #f9f9fb;
        padding: 16px;
        padding-bottom: 8px;
      }
    }
  }
  .lead-message,
  .lead-message-notes {
    padding: 24px;
    border-bottom: 1px solid $fill-border;
  }
  label {
    color: $text-secondary;
    margin-bottom: 8px;
  }
  textarea {
    height: 109px;
    font-size: 14px !important;
  }
  input {
    font-size: 14px;
    color: $text-primary;
  }
  .lead-message-notes {
    border-bottom: none;
  }
  .button-add-note {
    padding: 0 24px;
  }
  .status-tag {
    @mixin leadsProperty($color, $percent) {
      background: lighten($color, $percent);
      border-radius: 2px;
      color: $color;
      padding: 6px 12px;
      font-size: 12px;
      font-weight: 600;
    }
    .potencial-leads {
      @include leadsProperty($secondary-purple, 29%);
    }
    .incoming-lead {
      @include leadsProperty($secondary-orange, 29%);
    }
    .application-sent {
      @include leadsProperty($secondary-yellow, 29%);
    }
    .application-received,
    .active {
      @include leadsProperty($secondary-blue, 29%);
    }
    .waitlisted {
      @include leadsProperty($secondary-teal, 29%);
    }
    .accepted {
      @include leadsProperty($secondary-purple2, 29%);
    }
    .archived {
      @include leadsProperty($text-placeholder, 20%);
    }
  }
  .status-tag .application-received,
  .status-tag .active {
    border-radius: 2px;
    color: $secondary-blue;
    padding: 6px 12px;
    font-size: 12px;
    font-weight: 600;
  }
  .dashboard-main-content-v2 {
    .head-title {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 24px;
      @media (min-width: 768px) {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
      }
      h1 {
        margin-bottom: 2rem;
      }
    }
    .head-buttons {
      display: block;
      width: 100%;
      @media (min-width: 768px) {
        width: unset;
        display: flex;
      }
    }

    .head-button {
      .button {
        i {
          color: white;
        }
        @media (max-width: 767px) {
          display: block;
          width: 100%;
        }
      }
    }
    h1 {
      color: #44464b;
      display: block;
    }
    .panel-v2 {
      background: #fff;
      box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }
    .content-header {
      padding: 24px;
      border-bottom: 2px solid #dfe4ea;
      label {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 1rem;
      }
    }
    .table-spends {
      width: 100%;
      height: 100%;
      tr {
        border-bottom: 1px solid #dfe4ea;
      }
      td {
        &.source-data {
          width: 20%;
        }
      }
      th {
        font-weight: 600;
      }
      .button-actions {
        text-align: right;
        button {
          margin-left: 1rem;
        }
      }
      .source-data {
        min-width: 20%;
      }
    }
    .table-spends th,
    .table-spends td {
      padding: 16px 24px;
      font-size: 14px;
      color: #44464b;
    }
    .pags,
    .pags-numb {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0.3rem 0;
      color: #d1d4de;
      font-size: 14px;
    }
    .pags {
      i {
        margin: 0 0.5rem;
        &.disable {
          color: #d1d4de;
        }
        &.active {
          color: #44464b;
        }
      }
    }
    ul {
      &.pags-numb {
        padding: 10px;
        li {
          margin: 0 0.3rem;
          color: #44464b;
          &.active {
            color: $primary;
          }
          &.disable {
            color: #d1d4de;
          }
        }
      }
    }
  }
  .dashboard-v2 {
    min-height: 800px;
  }
  .modal-content {
    border: none;
    color: #44464b;
  }
  .h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 110%;
  }
  .h1-red {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 110%;
    color: #ff5e5e;
  }
  .h1-green-1 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 110%;
    color: $green-legacy;
  }
  .h1-yellow {
    font-style: normal;
    font-weight: bold;
    line-height: 110%;
    font-size: 18px;
    color: #f8d55c;
  }
  .h1-white {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 110%;
    color: #fff;
  }
  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
  }
  h6,
  .h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
  .subheader-1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }
  .font-8 {
    font-size: 8px;
  }
  .font-10 {
    font-size: 10px;
  }
  .font-11 {
    font-size: 11px !important;
  }
  .font-12 {
    font-size: 12px !important;
  }
  .font-13 {
    font-size: 13px !important;
  }
  .font-14 {
    font-size: 14px !important;
  }
  .font-16 {
    font-size: 16px !important;
  }
  .font-18 {
    font-size: 18px !important;
  }
  .font-20 {
    font-size: 20px !important;
  }
  .font-24 {
    font-size: 24px !important;
  }
  .font-28 {
    font-size: 28px !important;
  }
  .font-34 {
    font-size: 34px !important;
  }
  .font-40 {
    font-size: 40px !important;
  }
  .font-normal {
    font-weight: normal !important;
    font-weight: normal !important;
  }
  .font-normal,
  .font-400 {
    font-weight: 400 !important;
  }
  .font-500 {
    font-weight: 500;
  }
  .font-600 {
    font-weight: 600 !important;
  }
  .font-700 {
    font-weight: 700;
  }
  .font-bold {
    font-weight: bold;
  }
  .font-uppercase {
    text-transform: uppercase;
  }
  .p-24px {
    padding: 24px;
  }
  .b-grey-8 {
    background-color: #f5f7f9;
  }
  .b-green {
    background: $green-legacy;
    color: #fff;
  }
  .b-secondary-orange {
    background-color: $secondary-orange !important;
    color: #fff;
  }
  .b-fill-orange {
    background-color: $primary-orange !important;
    color: #fff;
  }
  .b-fill-purple {
    background-color: $primary-purple !important;
    color: #fff;
  }
  .b-secondary-purple {
    background-color: $secondary-purple;
    color: #fff;
  }
  .b-secondary-yellow {
    background-color: $secondary-yellow;
    color: #fff;
  }
  .b-secondary-blue {
    background-color: $secondary-blue;
    color: #fff;
  }
  .b-secondary-teal {
    background-color: $secondary-teal;
    color: #fff;
  }
  .b-secondary-purple2 {
    background-color: $secondary-purple2;
    color: #fff;
  }
  .b-secondary-lead-green {
    background-color: $secondary-lead-green;
    color: #fff;
  }
  .b-disabled {
    background-color: $fill-disabled;
  }
  .border-bottom-grey {
    border-bottom: 1px solid $fill-border;
  }
  .text-white {
    color: #fff !important;
  }
  .text-blue,
  .text-primary-blue,
  .text-primary {
    color: $text-primary-blue !important;
  }
  .text-primary-grey {
    color: $text-primary !important;
  }
  .text-secondary {
    color: $text-secondary !important;
  }
  .text-green {
    color: $green-legacy;
  }
  .text-red {
    color: $secondary-red;
  }
  .text-grey-1 {
    color: #44464b;
  }
  .text-grey-6 {
    color: #adb1bd;
  }
  .text-grey-8 {
    color: #f5f7f9;
  }
  .text-grey-placeholder {
    color: $text-placeholder !important;
  }
  .btn-green {
    background: $green-legacy;
    color: #fff;
    font-weight: bold;
  }
  .text-link {
    color: $text-primary-blue;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .link-green {
    color: #29a37e;
    font-weight: 500;
  }
  .mt-24 {
    margin-top: 24px !important;
  }
  .mr-24 {
    margin-right: 24px !important;
  }
  .mb-24 {
    margin-bottom: 24px !important;
  }
  .ml-24 {
    margin-left: 24px !important;
  }
  .mt-16 {
    margin-top: 16px !important;
  }
  .mr-16 {
    margin-right: 16px !important;
  }
  .mb-16 {
    margin-bottom: 16px !important;
  }
  .ml-16 {
    margin-left: 16px !important;
  }
  .mb-36 {
    margin-bottom: 36px !important;
  }
  .p-24 {
    padding: 24px !important;
  }
  .pb-24 {
    padding-bottom: 24px !important;
  }
  .p-16 {
    padding: 16px !important;
  }
  .panel {
    background-color: #fff;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.08) !important;
    border-radius: 10px !important;
  }
  .panel-blue {
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.08) !important;
    border-radius: 10px !important;
    background: #40afd9;
    color: #fff;
  }
  .panel-gray-8 {
    border-radius: 10px !important;
    background: #f5f7f9;
    box-shadow: none !important;
  }
  .b-bottom-grey-7 {
    border-bottom: 1px solid #ebeff4;
  }
  .accent-1 {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
  }
  .icon-pointer {
    cursor: pointer;
  }
  .main-content {
    .schola-basics-select {
      border: 1px solid #ebeff4;
      box-sizing: border-box;
      border-radius: 3.5px;
      background: #fff;
      padding: 0.5rem;
      margin-top: 1rem;
      cursor: pointer;
    }
    .progress-content {
      width: 93%;
    }
  }
  .progress-bar {
    background-color: $green-legacy;
  }
  .aside {
    padding-top: 2.5rem !important;
    background: #fff;
    .img-profile {
      border: 1px solid #ebeff4;
      border-radius: 10px !important;
    }
    .profile-text {
      i {
        color: #f8d55c;
      }
    }

    @media (min-width: 967px) {
      width: 25%;
    }
  }
  table {
    &.table-school-leader {
      tr {
        &.grey-9 {
          th {
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            color: #44464b;
            text-transform: capitalize !important;
            vertical-align: middle;
          }
        }
      }
      td {
        font-weight: 600;
        font-size: 13px;
      }
      .school-name,
      .school-level {
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        text-transform: capitalize;
      }
    }
  }
  .table-leaders {
    @media (min-width: 993px) {
      height: 225px;
    }
    .col {
      padding-right: 1rem;
    }
    .leader-school {
      .logo-school {
        width: 45px;
        img {
          width: 100%;
          margin-right: 0.5rem;
          @media (min-width: 768px) {
            width: 45px;
          }
        }
      }
    }
  }
  .title-leaderboard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    padding-left: 1rem;
  }
  .panel-015-gray-4 {
    background-color: #fdfdfd;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.015) !important;
    border-radius: 10px !important;
  }
  .plan-description {
    border-right: 1px solid #ebeff4;
  }
  .feature-included {
    display: flex;
    margin: 15px 0;
    align-items: baseline;
  }
  .border-green-1 {
    border: 2px solid $green-legacy;
  }
  .button-green-outline {
    height: 45px;
    width: 188px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    border: 1px solid $green-legacy;
    color: $green-legacy;
    border-radius: 3.5px;
    background: #fff;
  }
  .border-dashed {
    border: 2px dashed #dddfe7;
  }
  .border-solid {
    border: 1px solid #d1d4de;
  }
  .button-payment-period-select {
    background: #ebeff4;
    border-radius: 1px;
  }
  .button-payment-period-unselect {
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.08) !important;
    background: #fff;
    color: #44464b;
    cursor: pointer;
  }
  .current-school-section {
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      font-family: 'Montserrat' sans-serif;
    }
    .logo-media {
      border: 1px solid #d1d4de;
      border-radius: 100px;
      margin-right: 10px;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        overflow: hidden;
        max-height: 23px;
        max-width: 23px;
        width: auto;
      }
    }
  }
  .scroll-upgrade {
    .b-green-immediate-roi {
      background: #7cbc55;
      color: #fff;
    }
    .immediate-roi {
      padding: 5px;
    }
    @media (min-width: 768px) {
      margin: 1rem 0;
    }
  }
  .scholaboosts-question {
    display: block;
  }
  .scholaboosts-quantity {
    width: 100px !important;
    display: inline;
  }
  .scholaboosts-amount {
    display: inline;
    font-size: 17px;
    padding-left: 15px;
  }
  .scholaboosts-total {
    display: inline;
    font-size: 20px;
    font-weight: bold;
    padding-left: 10px;
  }
  .alert--danger,
  .alert--warning,
  .alert--primary {
    background: #ffefef;
    padding: 16px;
    color: #000;
  }
  .alert--warning {
    background: #fff3e2;
  }
  .alert--primary {
    background: #f2fbff;
  }
  .breadcrumb {
    position: unset;
    font-size: 1rem;
    color: $text-primary;
    background: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  .form-search {
    position: relative;
    i {
      position: absolute;
      padding: 10px;
      color: #adb1bd;
    }
    input {
      padding: 10px;
      text-align: left;
      text-indent: 1.5rem;
      background: $fill-hover;
      border: 1px solid $fill-disabled;
      height: 39px;
      width: 100%;
      @media (min-width: 768px) {
        width: 300px;
      }
      &:focus {
        outline: none !important;
        border: 1px solid $fill-disabled;
        box-shadow: none;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  filters-content {
    position: relative;
    margin: 5px 0;
    margin-right: 6px;
  }
  .school-list--search {
    input {
      &:not([type='checkbox']) {
        font-family: $sans;
      }
      &:not([type='checkbox']):focus {
        outline: 1px solid $green-legacy;
      }
    }
  }
}
.icon-rotate-90deg {
  img {
    transform: rotate(90deg);
  }
}
// .main-content {
// 	height: calc(100vh - 175px);
// 	width: 100%;
// 	.column-grey {
// 		background: #f9f9fb;
// 		border-right: 1px solid #dfe4ea;
// 		min-width: 240px;
// 		max-width: 240px;
// 	}
// }
.relationship-manager {
  .dashboard-main-content {
    padding: 24px;
  }
}
.Select-input {
  & > input {
    border: 0;
  }
}
.Select-value-label {
  font-size: 12px;
}
.Select-menu-outer,
.Select-placeholder {
  font-size: 12px;
}
.upload-file-content {
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid #d1d4de;
  border-radius: 4px !important;
  .icon-upload-file {
    color: #adb1bd;
    font-size: 3rem;
  }
}
.school-list--search {
  input[type='text'] {
    font-size: 18px !important;
  }
}
.app-header {
  &.solid-bar {
    background: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    .theme-button {
      border-radius: 4px;
      &.outline {
        border-radius: 4px;
        border: 1px solid #d1d4de;
        color: $green-legacy;
      }
    }
  }
}
.dashboardv3-panel {
  .dashboard-main-content {
    margin-top: 0;
    padding-left: 10px;
    padding-right: 0;
  }
}
.button--color--disabled {
  background: $fill-disabled !important;
  border-radius: 4px !important;
  color: $text-placeholder;
  border: none !important;
  cursor: unset !important;
}
.geosuggest__suggests {
  width: 100%;
  border: 1px solid #d3d3d3;
}
.geosuggest__suggests--hidden {
  display: none;
}
.geosuggest__input-wrapper,
.geosuggest__input {
  width: 100%;
  outline: none;
}
.geosuggest__suggests-wrapper {
  background-color: #fff;
  box-shadow: none !important;
  margin-bottom: 1rem;
  ul {
    text-align: left;
  }
}
.geosuggest__item {
  padding: 16px;
  line-height: 1.25;
  font-size: 0.8rem;
  &:hover {
    background: $green-legacy;
  }
  &:focus {
    background: #f8f9fb;
  }
}
.geosuggest__item-separator {
  padding: 8px 12px;
  line-height: 1.25;
  cursor: context-menu;
  background: #f1f4f7;
  border-bottom: 1px solid #ebf2f7;
  color: #6f8094;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}
.title-fixed {
  background: #fff;
  position: fixed;
  top: 75px;
  min-width: 380px;
  margin-bottom: 16px;
  z-index: 1;
}
.parent-details {
  margin-top: 77px;
}
.sidebar-buttons {
  padding: 31px 18px;
  padding-bottom: 12px;
  position: fixed;
  bottom: 0;
  background: #fff;
  min-width: 380px;
  button {
    width: 50%;
  }
  .button--size--small {
    padding: 8px !important;
  }
  .more-options {
    text-align: center;
    text-decoration: underline;
  }
}
.button-small-x {
  font-size: 12px !important;
  padding: 10px;
}
.student-parent {
  .sidebar-buttons {
    padding: 24px 18px;
  }
  .form-add-student {
    height: calc(100vh - 125px);
  }
}
.full-content {
  .layoutv2 {
    min-height: 100vh;
  }
  .dashboard-main-content,
  .dashboard-main-content-v3 {
    padding: 0;
    width: 100%;
    .main-content {
      display: flex;
    }
  }
  header {
    box-shadow: 2px 0px 6px rgba(101, 107, 118, 0.2);
  }
}
.schola-select {
  .schola__value-container {
    height: unset !important;
    min-height: 33px;
  }
}
.Toastify__toast--success {
  background: #fff !important;
  border-bottom: 3px solid $green-legacy !important;
}
.rc-slider,
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  width: 85% !important;
  margin-top: 7px;
}
.rc-slider-rail,
.rc-slider-track {
  position: absolute;
  height: 4px;
  border-radius: 6px;
}
.rc-slider-rail {
  width: 100%;
  background-color: #e9e9e9;
}
.rc-slider-track {
  background-color: #abe2fb;
  background-color: $primary;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 50%;
  border: 2px solid #96dbfa;
  background-color: #fff;
  touch-action: pan-x;
  background-color: $primary;
  border: solid 2px $primary;
}
.rc-slider-mark-text-active {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-size: 10px;
}
.new-message {
  color: $primary-red;
}
