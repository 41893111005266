.fab {
	position: fixed;
	padding: 16px;
	bottom: 16px;
	right: 16px;
	z-index: 1;
	box-shadow: $fab-box-shadow;
	min-width: auto;
}
.modal {
	display: none;
	&.modal--visible {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-wrap: nowrap;
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 98;
	}
}
.modal__content {
    position: relative;
    width: 100%;
    //height: 100%;
    background: $white;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 99;

    @media (min-width: $tablet-landscape) {
        max-width: 900px;
        height: auto;
        box-shadow: $card-box-shadow;
    }

    @media (min-width: $desktop) {
        max-width: 1024px;
        height: auto;
        box-shadow: $card-box-shadow;
    }
}
.modal__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;

    @media (min-width: $tablet-landscape) {
        justify-content: flex-end;
        background: transparent;
    }
}

.modal__title {
    display: inline-block;
    font-size: 20px;
    color: white;
    font-weight: $medium;
    margin-left: 24px;
}
.close-box{
    display: none;
}
.modal__close {
    display: inline-block;
    cursor: pointer;

    .icon {
        margin: 4px;
        fill: $schola-mist;
    }
}

// Main content, passed into the modal as children
.modal__children {
    padding: 16px;

    @media (min-width: $tablet-portrait) {
        padding: 24px;
    }

    @media (min-width: $tablet-landscape) {
        padding: 32px;
        padding-top: 0; // Close button in title provides padding-top for desktop
    }

    @media (min-width: $desktop) {
        padding: 48px;
        padding-top: 0; // Close button in title provides padding-top for desktop
    }
}

.set-phone-modal {
    .modal__content{
        width:100% !important;
        
        @media (min-width: $screen-sm) {
            width:600px !important
        }

    }
    .modal__header {
        padding: 24px;
        padding-bottom: 0;

        @media (min-width: $tablet-landscape) {
            justify-content: space-between;
            background: transparent;
        }
    }
    .modal__title {
        font-weight: bold;
        font-size: 18px;
        color: $schola-gray;
        margin-left: 0
    }

    .modal__children {
        padding: 0;

        .modal-body{
            padding: 24px;
            padding-top: 0;
        }
        .modal-footer {
            padding: 24px;
        }
    }

    .modal__overlay {
        width: 100%;
    }

}

// Opaque black background, sibling to modal content, sits behind everything
.modal__overlay {
    position: fixed;
    width: 100vw;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.25);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 97;
}
.create-user-board,
.parent-student-modal {
	.modal__header {
		padding-left: 0;
		padding-bottom: 0;
		.modal__title {
			color: $schola-gray;
			padding-left: 24px;
			margin-left: 0;
		}
	}
	.modal__children {
		padding: 0;
	}
	.modal__content {
		width: 30% !important;
		max-width: 480px !important;
		min-width: 360px !important;
	}
	.icon-unlock {
		background: #f2fbff;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.modal-header {
		height: 70px;
	}
	.content {
		padding: 20px 24px;
	}
	.modal-footer {
		padding: 24px;
	}
	.modal-body {
		padding: 0 24px;
	}
}
.modal-success-toast {
	.modal__close {
		display: none !important;
	}
	.modal__header {
		padding: 0 !important;
	}
	.modal__content {
		min-width: 480px !important;
		width: 480px !important;
		max-width: 480px !important;
        .modal-content,
		.modal__children {
			padding: 0;
			padding-top: 24px;
			.modal-footer {
				padding: 24px;
				justify-content: center !important;
			}
		}
		img {
			width: 70%;
		}
	}
	.modal-content {
		padding-top: 0 !important;
	}
}
