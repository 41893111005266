/*School profile styles*/
.dashboard-main-content .dashboard-panel {
  display: block !important;
}

.school-type {
  overflow: unset !important;
}

.image-profile,
.imageProfile {
  width: 100% !important;
  height: 160px !important;
  margin-bottom: 2rem;
  background-position: center;
}
.gallery-content {
  display: flex;
  flex-wrap: wrap;
}
.gallery-content .gallery-item {
  display: flex;
  margin: 1rem;
}

.image-gallery {
  width: 160px;
  height: 160px;
  background-size: cover;
}

/* .upload-icon {
  float: none!important;
} */

/* .upload-icon .upload-content {
	display: flex;
} */
.images-list .upload-message,
.images-list .upload-icon {
  height: 100% !important;
  width: 100% !important;
}
.upload-icon .upload-content .upload-image-button {
  width: 67px;
}
.upload-icon .upload-content .validation-message {
  min-width: 500px;
  height: 20px !important;
  margin-left: 1rem;
}

.event-array-input--show {
  border: solid 1px #b1c1c5 !important;
}
.event-array-input__wrapper .event-array-input__desc {
  border: none !important;
}
.testimonial-holder label {
  width: 100% !important;
}

/*-----------------------------------------
  # Forms
  -----------------------------------------*/
.form-group {
  margin-bottom: 16px;
}

.form-group label {
  color: #202020;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 10px;
  display: block;
}

.checkbox-skin {
  position: relative;
}

.checkbox-skin input[type='checkbox'] {
  left: -999px;
  opacity: 0;
  position: absolute;
}

.checkbox-skin input[type='checkbox']:checked + label:before {
  content: '\f00c';
  color: #fff;
  font-family: 'FontAwesome';
  font-size: 10px;
  text-align: center;
  line-height: 13px !important;
  border-color: $secondary;
  background-color: $secondary;
}

.checkbox-skin,
.staff-options label {
  color: #707174;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-skin label:before {
  content: '';
  top: 1.5px;
  left: 0;
  width: 15px;
  height: 15px;
  display: block;
  position: absolute;
  border-radius: 50%;
  border: 1px solid #b0b1b5;
}

.checkbox-skin label span {
  padding: 2.5px 10px 0 25px;
  display: inline-block;
}

.checkbox-skin-disabled {
  cursor: default !important;
}

.staff-options {
  display: flex;

  input {
    margin-right: 0.5rem;
  }

  span {
    margin-right: 2rem;
  }
}

.range-skin input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
}

.range-skin input[type='range']:focus {
  outline: none;
}

.range-skin input[type='range']::-webkit-slider-thumb {
  height: 11px;
  width: 11px;
  margin-top: -4px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50%;
  border-color: transparent;
  background: #0299d3;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

.range-skin input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-radius: 2px;
  background: rgba(2, 153, 211, 0.8);
}

.range-skin input[type='range']::-moz-range-thumb {
  width: 11px;
  height: 11px;
  cursor: pointer;
  border-color: transparent;
  background: #0299d3;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

.range-skin input[type='range']::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-radius: 2px;
  background: transparent;
  background-color: #e9e8e9;
}

.range-skin input[type='range']::-moz-range-progress {
  background: #0299d3;
}

.range-skin input[type='range']::-ms-thumb {
  width: 11px;
  height: 11px;
  cursor: pointer;
  border-radius: 50%;
  border-color: transparent;
  background: #0299d3;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

.range-skin input[type='range']::-ms-track {
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
}

.range-skin input[type='range']::-ms-fill-lower {
  border-radius: 2px;
  background: #0299d3;
}

.range-skin input[type='range']::-ms-fill-upper {
  border-radius: 2px;
  background: #e9e8e9;
}

.checkbox-button-skin {
  position: relative;
}

.checkbox-button-skin label {
  color: #6c6d70;
  font-size: 10px;
  font-weight: 300;
  text-align: center;
  line-height: 11px;
  width: 70px;
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 3.5px;
  border: 0.7px solid #dddfe7;
  background-color: #f6f6fa;
  z-index: 9;
}

.checkbox-button-skin img {
  max-width: 100%;
  margin-bottom: 4px;
}

.checkbox-button-skin input[type='checkbox'],
.checkbox-button-skin input[type='radio'] {
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.checkbox-button-skin input[type='checkbox']:checked + label,
.checkbox-button-skin input[type='radio']:checked + label {
  border: 2px solid $secondary;
}

.theme-button {
  color: inherit;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  padding: 11px 30px;
  border: 1px solid #ccc;
  border-radius: 19px;
  display: inline-block;
  background-color: #ccc;
  cursor: pointer;
}

.theme-button.default {
  color: #fff;
  border: 1px solid #fff;
  background-color: #fff;
}

.theme-button.default.outline {
  color: #fff;
  background-color: transparent;
}

.theme-button.primary {
  color: #fff;
  border-color: $secondary;
  background-color: $secondary;
}

.theme-button.primary.outline {
  color: $secondary;
  background-color: transparent;
}

.theme-button.small {
  font-size: 10px;
  width: 87px;
  height: 25px;
  padding: 0;
}

.theme-button.shadow {
  -webkit-box-shadow: -1px 12px 20px 0 rgba(0, 53, 74, 0.14);
  box-shadow: -1px 12px 20px 0 rgba(0, 53, 74, 0.14);
}

.dashboard-body {
  background: linear-gradient(225.42deg, #f9f9fc 0%, #f0f0f8 100%);
}

.dashboard-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dashboard-main-content .dashboard-title {
  color: #0299d3;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 26px;
}

.dashboard-main-content .dashboard-panel {
  padding: 15px;
  margin-bottom: 12px;
  overflow: auto;
  border-radius: 3.5px;
  background-color: #fff;
}
.dashboard-main-content .dashboard-panel .button--color--primary:not(.button--disabled):hover {
  background: $secondary;
  border-color: $secondary;
}
.dashboard-main-content .dashboard-panel .button--size--small {
  margin-left: 6px;
  display: inline-block;
  color: #fff;
  border-color: $secondary;
  background-color: $secondary;
}

.dashboard-main-content .dashboard-panel.transparent {
  padding-left: 0;
  padding-right: 0;
  background-color: transparent;
}

.dashboard-main-content .dashboard-panel .panel-label {
  color: #6c6d70;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin-bottom: 17px;
}

.dashboard-main-content .dashboard-panel .panel-label span {
  color: #94959a;
  font-size: 10.5px;
  font-weight: 400;
  text-transform: initial;
  margin-left: 6px;
  display: inline-block;
}

.dashboard-main-content .dashboard-panel .inner-section {
  margin-bottom: 25px;
}

.images-inner-section {
  display: flex;
  flex-direction: column;
}

.dashboard-main-content .dashboard-panel .to select {
  width: auto;
  display: inline-block;
}

.dashboard-main-content .dashboard-panel .to span {
  color: #6c6d70;
  font-size: 12px;
  font-weight: 300;
  margin: 0 3px;
  display: inline-block;
}

.dashboard-main-content .dashboard-panel .change-logo .logo-holder {
  width: 67px;
  height: 67px;
  padding: 8px;
  display: inline-block;
  border-radius: 3.5px;
  vertical-align: middle;
  background-color: transparent !important;
}

.dashboard-main-content .dashboard-panel .change-logo .logo-holder img {
  width: 100%;
  max-width: 100%;
}

.dashboard-main-content .dashboard-panel .change-logo .image-cropper__controls {
  width: auto;
}

.dashboard-main-content .dashboard-panel .change-logo .change-logo-button {
  font-size: 9px;
  width: 87px;
  height: 25px;
  padding: 0;
  margin-left: 6px;
  display: inline-block;
}

.dashboard-main-content .dashboard-panel .checkbox-group {
  display: inline-block;
}

.dashboard-main-content .dashboard-panel .checkbox-group:last-child {
  margin-bottom: 0;
}

.dashboard-main-content .dashboard-panel .checkbox-group .checkbox-button-skin {
  margin-right: 6px;
  margin-bottom: 10px;
  vertical-align: top;
  display: inline-block;
}

.dashboard-main-content .dashboard-panel .checkbox-group .checkbox-button-skin:last-of-type {
  margin-right: 0;
}

.dashboard-main-content .dashboard-panel.times .tab-buttons div[class^='col'] a {
  margin-bottom: 10px;
  display: inline-block;
}

.dashboard-main-content .dashboard-panel .seats-list .seat {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  width: 33.33333%;
  display: inline-block;
  margin-right: -5px;
  margin-bottom: 15px;
}

.dashboard-main-content .dashboard-panel .seats-list .seat .seat-title {
  margin-bottom: 15px;
}

.dashboard-main-content .dashboard-panel .seats-list .seat .seat-button {
  width: 35px;
  height: 25px;
  text-decoration: none;
  line-height: 24px;
  display: inline-block;
  border-radius: 2.8px;
  border: 0.7px solid #dddfe7;
}

.dashboard-main-content .dashboard-panel .seats-list .seat .seat-button.active,
.dashboard-main-content .dashboard-panel .seats-list .seat .seat-button:hover {
  border-width: 1.4px;
  border-color: $secondary;
}

.dashboard-main-content .dashboard-panel .seats-list .seat .of {
  margin: 3px;
  display: inline-block;
}

.dashboard-main-content .dashboard-panel .reviews-container .review-box {
  width: 215px;
  height: 126px;
  text-align: center;
  padding: 13px 0;
  margin: 0 auto 15px auto;
  border-radius: 3.5px;
  background-color: #fff;
}

.dashboard-main-content .dashboard-panel .reviews-container .review-box .image-container {
  height: 30px;
  margin-bottom: 10px;
}

.dashboard-main-content .dashboard-panel .reviews-container .review-box .score-review .form-group {
  width: 195px;
  text-align: left;
  float: left;
  padding: 0 10px;
}

.dashboard-main-content .dashboard-panel.media .images .images-list li {
  width: 67px;
  height: 67px;
  float: none;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 3.5px;
}

.grade div {
  width: 20px;
  display: inline;
}
.dashboard-main-content .dashboard-panel.media .images .images-list .upload-content {
  width: 67px;
  height: 67px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 3.5px;
}

.dashboard-main-content .dashboard-panel.media .images .images-list li:not(:first-child) {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  border: 1px solid #dddfe7;
}

.dashboard-main-content .dashboard-panel.media .images .images-list li:last-child {
  margin-right: 0;
}

.dashboard-main-content .dashboard-panel.media .images .images-list li .upload-image-button {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 3.5px;
  border: 1px dashed $secondary;
}

.dashboard-main-content .dashboard-panel.media .images .images-list li .upload-image-button:before {
  content: '';
  width: 67px;
  height: 67px;
  display: block;
  background-size: 34px 23px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://s3-us-west-2.amazonaws.com/scholamatch-static/upload.svg');
}

.dashboard-main-content .dashboard-panel.media .form-group {
  margin-bottom: 10px;
  position: relative;
}

.dashboard-main-content .dashboard-panel.media .form-group.checked:before {
  content: '\f00c';
  top: 14px;
  right: 10px;
  color: $secondary;
  font-family: 'FontAwesome';
  font-size: 10px;
  position: absolute;
}

.dashboard-main-content .dashboard-panel.media .form-group.checked input {
  padding-right: 23px;
}

.dashboard-main-content .dashboard-panel.media .add-button {
  margin-top: 10px;
}

.dashboard-main-content .tab-buttons {
  text-align: center;
}

.dashboard-main-content .tab-buttons a {
  color: #6c6d70;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 0.4px;
  padding: 5px 0;
}

.dashboard-main-content .tab-buttons a.active,
.dashboard-main-content .tab-buttons a:hover {
  border-bottom: 2px solid $secondary;
}

.connect-school-form .form-content {
  padding: 25px;
}

.connect-school-form .section-title {
  font-size: 16px;
  color: #0299d3;
  font-weight: 700;
  margin-bottom: 15px;
}

.testimonial-holder {
  display: flex;
}

/* .testimonial-holder button {
		padding: 0 16px;
	}
   */
.modal__content {
  width: 45% !important;
}

/* Modals */
.add-testimonial-modal .modal__content {
  width: 80% !important;
  height: auto;
  max-width: 460px;
}

.add-testimonial-modal .modal__header {
  background-color: #fff;
}

.event-array-input__wrapper {
  /*width: 180px */
}

.event-array-input__wrapper .btn-container button:first-child {
  margin-right: 2px;
}

.event-array-input__wrapper .event-array-input--show .event-array-input__desc .text-container {
  width: 100% !important;
}

@media (min-width: 768px) {
  .dashboard-main-content .dashboard-panel .reviews-container .review-box {
    margin: 0 15px 0 0;
    float: left;
  }
  .dashboard-main-content .dashboard-panel .reviews-container .review-box:last-child {
    margin-right: 0;
  }

  .modal__content {
    width: 45%;
  }
}

@media (min-width: 1200px) {
  .dashboard-main-content {
    padding: 0 30px;
    width: calc(100% - 270px);
  }
  .layoutRetool {
    .dashboard-main-content {
      padding: 0;
      width: 100%;
    }
  }
  .dashboard-main-content .dashboard-title {
    font-size: 29px;
  }
  .dashboard-main-content .dashboard-panel {
    padding: 25px;
  }
  .dashboard-main-content .dashboard-panel .checkbox-group {
    display: block;
  }
  .dashboard-main-content .dashboard-panel .tour-times:before {
    content: '';
    top: 0;
    right: 8px;
    width: 1px;
    height: 100%;
    display: block;
    position: absolute;
    background-color: #e5e7ed;
  }
  .dashboard-main-content .dashboard-panel .seats-list .seat {
    width: auto !important;
    margin-right: 10px;
  }
  .dashboard-main-content .dashboard-panel .seats-list .seat:last-child {
    margin-right: 0;
  }
  .dashboard-main-content .dashboard-panel.social-media {
    overflow: auto;
  }
  .dashboard-main-content .dashboard-panel.social-media .form-group {
    width: 20%;
    float: left;
    padding-right: 20px;
    margin-bottom: 0;
  }
  .event-array-input__desc {
    /*max-width: 174px*/
  }
}

.save-status {
  left: 0;
  right: 0;
  color: #fff;
  font-size: 14px;
  height: 30px;
  text-align: center;
  font-weight: 600;
  padding: 8px;
  position: fixed;
  background-color: #6c757d;
  transform: translateY(-30px);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
  transition: all 0.1s ease-in-out;
  opacity: 0;
  z-index: 999;
}
.save-status.show {
  top: 0;
  opacity: 1;
  transition-delay: 0.1s;
  transform: translateY(0);
}
.save-status.show.saved {
  transition-delay: 0.1s;
  background-color: $secondary;
}

.testimonial-holder .btn-container {
  width: 7%;
  display: flex;
}

.testimonial-holder .btn-container .button .icon {
  height: 24px;
  width: 24px;
}
.testimonial-holder .btn-container .icon--edit svg {
  fill: $primary;
}
/* integration */
.integration-container .jotform {
  width: 420px;
  padding: 10px;
  border-radius: 3.5px;
  background-color: #fff;
}
.integration-container img {
  width: 120px;
}
/* seo */
.integration-container .seo {
  max-width: 550px;
  padding: 10px;
  border-radius: 3.5px;
  background-color: #fff;
}
/*
.teacher-staff .form-group
{
	width: 250px;
	float: left;
	margin-top: 15px;
	margin-right: 20px;

}*/
.geosuggest__suggests-wrapper {
  position: relative;
}
.geosuggest__suggests-wrapper ul {
  border: none;
  max-height: 300px;
  overflow-y: scroll;
  position: absolute;
  z-index: 999;
  background: white;
}
.geosuggest__suggests {
  border: solid 2px #b1c1c5;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.geosuggest__suggests--hidden {
  display: none;
}
.border-green {
  border: 2px solid $secondary !important;
}
.border-light {
  border: 0.7px solid #dddfe7 !important;
}
.dropzone {
  padding: 0 1rem;
  .icon-upload-file {
    i {
      font-size: 24px;
      color: $schola-gray-light;
    }
  }
}
.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
  padding: 5px;
  background: white;
  margin-top: 0.5rem;
}
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  top: 4px !important;
}
.dashboard-panel.social-media {
  svg.social-icon {
    fill: $primary;
  }
  .social-icon {
    svg,
    &img {
      fill: $primary;
      width: 32px;
      height: 32px;
    }
  }
}
.datepicker-column {
  @media (max-width: 480px) {
    padding-right: 0;
    padding-left: 0;
  }
}

.timepicker-column {
  .row {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.modal-request-demo {
  .modal__content {
    width: 100% !important;
    @media (min-width: 1024px) {
      width: 80%;
    }
  }
}
.checkTourTime2 {
  margin-bottom: 0px !important;
  vertical-align: middle;
}
.dashboard-main-content-profile {
  .confirmation-modal {
    .modal__content {
      max-width: 400px;
    }
  }
}
.description-editor > .ql-container {
  min-height: 200px;
}
