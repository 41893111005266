//shcolav1
//$primary: #0099CC
//dark-blue-100 : #4a4a4a
//$secondary: #9C3
//dark-blue-75 #0c5e79 //#546982,

@mixin theme-aware($key, $color) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      #{$key}: map-get(map-get($themes, $theme-name), $color) !important;
    }
  }
}

// UI Colors
// Primary Color Brand
$primary-blue: #0099cc;
$schola-green: #99cc33;
$aqua-secondary: #0c5e79;
$dark-blue: #1a2a45;
// Secondary Colors
$orange: #ffaa5a;
$amaranth: #ee4266;
$indigo: #5076c7;
// Semantic Colors
$success: #02ca81;
$warning: #fbb13c;
$danger: #d62246;
$info: #136ac3;

//// Usage

// Primary
// Use this to emphasise main ui components and text.
$primary-700-text-icon: $dark-blue;
$primary-600-pressed: #003d52;
$primary-500-default-link: #006b8f;
$primary-400-accent-icon-hover-selected: $primary-blue;
$primary-300-border: #4db8db;
$primary-200-subtle-border: #99d6eb;

//Secondary
//Use this to emphasise secondary ui components and text.
$secondary-600-text-icon-default: $aqua-secondary;
$secondary-500-secondary-text-accent: #3d7e94;
$secondary-300-border-pressed: #9ebfc9;
$secondary-200-subtle-border-hover: #cedfe4;

//Neutral
//Use this as the base for text color and neutral greys for Schola platform. Use it for Titles, secondary text and pargraph.
$dark-blue-700-text-icon: #1a2a45;
$dark-blue-500-secondary-text: #5f6a7d;
$dark-blue-400-subtle-icon-border: #818a99;
$dark-blue-300-subtle-border-disabled-text: #a3aab5;
$dark-blue-200-disabled-border: #c6cad1;

//Success
//Use this color to communicate positive actions
$success-700-text-icon: #015134;
$success-600-2ry-text-icon-pressed: #018d5a;
$success-400-accent-default: #02ca81;
$success-300-border-hover: #4edaa7;
$success-200-subtle-border: #9aeacd;

//Warning
//Use this color to communicate warning actions
$warning-700-text-icon: #644718;
$warning-600-secondary-text: #b07c2a;
$warning-500-accent-pressed: #e29f36;
$warning-400-accent-default: #fbb13c;
$warning-300-border-hover: #fcc877;
$warning-200-subtle-border: #fde0b1;

//Danger
//Use this color to communicate danger actions
$danger-700-text-icon: #560e1c;
$danger-600-secondary-text: #961831;
$danger-500-accent-default: #c11f3f;
$danger-400-accent-hover: #d62246;
$danger-300-border: #e2647e;
$danger-200-subtle-border: #efa7b5;

//Info
//Use this color for informational ui elements
$info-700-text-icon: #0a3562;
$info-600-pressed: #0d4a89;
$info-500-2ry-text-default: #0f559c;
$info-400-accent-hover: #136ac3;
$info-300-border: #5a97d5;
$info-subtle200-border: #a1c3e7;

//Backgrounds
//Use this colors for backgrounds only. Do not combine elements with different colors.
$primary-100-background: #e6f5fa;
$secondary-100-background: #e7eff2;
$green-100-background: #f5faeb;
$dark-blue-100-background: #f1f2f3;
$success-100-background: #e6faf2;
$warning-100-background: #feefd8;
$danger-100-background: #fbe9ed;

//Full Color Palette
$primary-700: #001f29;
$secondary-700: #0a4b61;
$green-700: #1f290a;
$primary-600: #003d52;
$secondary-600: #0c5e79;
$green-600: #3d5214;
$primary-500: #006b8f;
$secondary-500: #3d7e94;
$green-500: #6b8f24;
$primary-400: #0099cc;
$secondary-400: #6d9eaf;
$green-400: #99cc33;
$primary-300: #4db8db;
$secondary-300: #9ebfc9;
$green-300: #b8db70;
$primary-200: #99d6eb;
$secondary-200: #cedfe4;
$green-200: #d6ebad;
$primary-100: #e6f5fa;
$secondary-100: #e7eff2;
$green-100: #f5faeb;

$success-800: #00281a;
$warning-800: #32230c;
$danger-800: #2b070e;
$success-700: #015134;
$warning-700: #644718;
$danger-700: #560e1c;
$success-600: #018d5a;
$warning-600: #b07c2a;
$danger-600: #961831;
$success-500: #02b674;
$warning-500: #e29f36;
$danger-500: #c11f3f;
$success-400: #02ca81;
$warning-400: #fbb13c;
$danger-400: #d62246;
$success-300: #4edaa7;
$warning-300: #fcc877;
$danger-300: #e2647e;
$success-200: #9aeacd;
$warning-200: #fde0b1;
$danger-200: #efa7b5;
$success-100: #e6faf2;
$warning-100: #feefd8;
$danger-100: #fbe9ed;

$info-800: #041527;
$dark-blue-700: #1a2a45;
$info-700: #0a3562;
$dark-blue-600: #3c4a61;
$info-600: #0d4a89;
$dark-blue-500: #5f6a7d;
$info-500: #0f559c;
$dark-blue-400: #818a99;
$info-400: #136ac3;
$dark-blue-300: #a3aab5;
$info-300: #5a97d5;
$dark-blue-200: #c6cad1;
$info-200: #a1c3e7;
$dark-blue-100: #f1f2f3;
$info-100: #e7f0f9;

/////////////////////////////////////////////////////////////////////////////////

$secondary-500: $secondary-500-secondary-text-accent;

$dark-blue-100: #213556;
$dark-blue-75: #546982;
$dark-blue-65: #6e7b91;
$dark-blue-50: #96a3b8;
$dark-blue-25: #c7ccd4;
$dark-blue-15: #dadee5;
$dark-blue-10: #e8eaee;
$dark-blue-05: #f4f6f8;

$navy-blue-100: #0645ad;
$navy-blue-75: #4473c1;
$navy-blue-50: #82a2d6;
$navy-blue-25: #c1d0ea;
$navy-blue-15: #d8e3f5;
$navy-blue-10: #e8eef8;
$navy-blue-05: #f2f5fb;
$navy-blue-02: #fafbfd;

$accent-dark: #005fb3;
$accent-100: #008cff;
$accent-75: #45abff;
$accent-50: #8ccbff;
$accent-25: #cce9ff;
$accent-10: #e5f4ff;
$accent-05: #f2f9ff;
$accent-02: #fafdff;

$themes: (
  schola: (
    secondary: #99cc33,

    dark-blue-100: $dark-blue-100,
    dark-blue-75: $dark-blue-75,
    dark-blue-65: $dark-blue-65,
    dark-blue-50: $dark-blue-50,
    dark-blue-25: $dark-blue-25,
    dark-blue-15: $dark-blue-15,
    dark-blue-10: $dark-blue-10,
    dark-blue-05: $dark-blue-05,

    navy-blue-100: $navy-blue-100,
    navy-blue-75: $navy-blue-75,
    navy-blue-50: $navy-blue-50,
    navy-blue-25: $navy-blue-25,
    navy-blue-15: $navy-blue-15,
    navy-blue-10: $navy-blue-10,
    navy-blue-05: $navy-blue-05,
    navy-blue-02: $navy-blue-02,

    accent-dark: $accent-dark,
    accent-100: $accent-100,
    accent-75: $accent-75,
    accent-50: $accent-50,
    accent-25: $accent-25,
    accent-10: $accent-10,
    accent-05: $accent-05,
    accent-02: $accent-02,
  ),
);

////////////////////////////////////// color classes for text and carbon icons:

.text-dark-blue-100 {
  color: $dark-blue-100;
  fill: $dark-blue-100;
}
.text-dark-blue-75 {
  color: $dark-blue-75;
  fill: $dark-blue-75;
}
.text-dark-blue-65 {
  color: $dark-blue-65;
  fill: $dark-blue-65;
}
.text-dark-blue-50 {
  color: $dark-blue-50;
  fill: $dark-blue-50;
}
.text-dark-blue-25 {
  color: $dark-blue-25;
  fill: $dark-blue-25;
}
.text-dark-blue-15 {
  color: $dark-blue-15;
  fill: $dark-blue-15;
}
.text-dark-blue-10 {
  color: $dark-blue-10;
  fill: $dark-blue-10;
}
.text-dark-blue-05 {
  color: $dark-blue-05;
  fill: $dark-blue-05;
}

.text-navy-blue-100 {
  color: $navy-blue-100 !important;
  fill: $navy-blue-100 !important;
}
.text-navy-blue-75 {
  color: $navy-blue-75 !important;
  fill: $navy-blue-75 !important;
}
.text-navy-blue-50 {
  color: $navy-blue-50 !important;
  fill: $navy-blue-50 !important;
}
.text-navy-blue-25 {
  color: $navy-blue-25 !important;
  fill: $navy-blue-25 !important;
}
.text-navy-blue-15 {
  color: $navy-blue-15 !important;
  fill: $navy-blue-15 !important;
}
.text-navy-blue-10 {
  color: $navy-blue-10 !important;
  fill: $navy-blue-10 !important;
}
.text-navy-blue-05 {
  color: $navy-blue-05 !important;
  fill: $navy-blue-05 !important;
}
.text-navy-blue-02 {
  color: $navy-blue-02 !important;
  fill: $navy-blue-02 !important;
}

.text-accent-dark {
  color: $accent-dark !important;
  fill: $accent-dark !important;
}
.text-accent-100 {
  color: $accent-100 !important;
  fill: $accent-100 !important;
}
.text-accent-75 {
  color: $accent-75 !important;
  fill: $accent-75 !important;
}
.text-accent-50 {
  color: $accent-50 !important;
  fill: $accent-50 !important;
}
.text-accent-25 {
  color: $accent-25 !important;
  fill: $accent-25 !important;
}
.text-accent-10 {
  color: $accent-10 !important;
  fill: $accent-10 !important;
}
.text-accent-05 {
  color: $accent-05 !important;
  fill: $accent-05 !important;
}
.text-accent-02 {
  color: $accent-02 !important;
  fill: $accent-02 !important;
}
////////////////////////////////////////////////// classes for background color:

.bg-dark-blue-100 {
  background-color: $dark-blue-100 !important;
}
.bg-dark-blue-75 {
  background-color: $dark-blue-75 !important;
}
.bg-dark-blue-65 {
  background-color: $dark-blue-65 !important;
}
.bg-dark-blue-50 {
  background-color: $dark-blue-50 !important;
}
.bg-dark-blue-25 {
  background-color: $dark-blue-25 !important;
}
.bg-dark-blue-15 {
  background-color: $dark-blue-15 !important;
}
.bg-dark-blue-10 {
  background-color: $dark-blue-10 !important;
}
.bg-dark-blue-05 {
  background-color: $dark-blue-05 !important;
}

.bg-navy-blue-100 {
  background-color: $navy-blue-100;
}
.bg-navy-blue-75 {
  background-color: $navy-blue-75;
}
.bg-navy-blue-50 {
  background-color: $navy-blue-50;
}
.bg-navy-blue-25 {
  background-color: $navy-blue-25;
}
.bg-navy-blue-15 {
  background-color: $navy-blue-15;
}
.bg-navy-blue-10 {
  background-color: $navy-blue-10;
}
.bg-navy-blue-05 {
  background-color: $navy-blue-05;
}
.bg-navy-blue-02 {
  background-color: $navy-blue-02;
}
///
.bg-accent-dark {
  background-color: $accent-dark !important;
}
.bg-accent-100 {
  background-color: $accent-100 !important;
}
.bg-accent-75 {
  background-color: $accent-75 !important;
}
.bg-accent-50 {
  background-color: $accent-50 !important;
}
.bg-accent-25 {
  background-color: $accent-25 !important;
}
.bg-accent-10 {
  background-color: $accent-10 !important;
}
.bg-accent-05 {
  background-color: $accent-05 !important;
}
.bg-accent-02 {
  background-color: $accent-02 !important;
}

////////////////////////////////////////////////////////////////
//legacy color vars
// ============= DEPRECATED DONT USE FOR FUTURE DESIGN
$dashboard-border: #e5edd5;
$dashboard-background: #f6fcf9;
$green-dark: #317159;
$green: #52bd94;
$green-light: #f5fbf8;
$red-dark: #a73636;
$red-light: #fdf4f4;
$red: #d14343;
$yellow: #f0ad1b;
$black: #000000;
$white: #ffffff;

$primary: #0099cc;
$green-legacy: #9c3;
$secondary: $green-legacy;
$red-legacy: #ff444f;
$heading-color: $dark-blue-75;
$body-color: $dark-blue-100;
$anchor-color: $accent-100;
$border-color: $dark-blue-15;
$gray-200: #d1d3dd;
$gray-400: #8d919a;

$text-secondary: #6c6d70;

$primary-red: #ff444f;
$primary-orange: #fda353;
$primary-purple: #95a2ff;
$fill-disabled: #ebeff4;
$fill-hover: #f9f9fb;
$fill-blue-hover: #f2fbff;
$fill-tab: #fbfcfd;
$fill-border: #dfe4ea;
$fill-modal: #f5f7f9;

$primary-blue: #0099cc;
$schola-atlantis: #81c784;
$schola-carmine: #f4333e;
$schola-purple: #af8cc4;
$schola-purple-2: #7e57c2;
$schola-yellow: #f6d270;
$schola-bluegrey: #6e95a0;
$schola-seablue: #086788;
$schola-bluegreen: #108daf;
$schola-mist: #b1c1c5;
$schola-lightgrey: #999999;
$schola-alabaster: #fafafa;
$schola-gray: #4a4a4a;
$schola-darkgray: #333333;
$schola-gray-sub: #78909c;
$schola-gray-light: #ccc;
$schola-lemon: #ff9100;
$schola-hr-color: #cfd8dc;
$schola-back-grey: #f5f5f5;
$schola-border: #b0bec5;
$schola-bluemoon: #eaf0fa;
/* color leads v3 */
$secondary-red: #ff5757;
$secondary-orange: #fc6e35;
$secondary-purple: #7f5dff;
$secondary-yellow: #f0c52e;
$secondary-blue: #5381ef;
$secondary-teal: #5dcdd3;
$secondary-purple2: #8e44ad;
$secondary-lead-green: #29a37e;
$secondary-sea-green: #53caa6;
$secondary-green: #29a37e;

$white: #fff;
$gray-200: #d1d3dd;
$fill-disabled: #ebeff4;
$fill-hover: #f9f9fb;

$text-disabled: #d1d4de;

$primary-blue: #0099cc;
$schola-atlantis: #81c784;
$schola-carmine: #f4333e;
$schola-purple: #af8cc4;
$schola-purple-2: #7e57c2;
$schola-yellow: #f6d270;
$schola-bluegrey: #6e95a0;
$schola-seablue: #086788;
$schola-bluegreen: #108daf;
$schola-mist: #b1c1c5;
$schola-lightgrey: #999999;
$schola-alabaster: #fafafa;
$schola-gray: #4a4a4a;
$schola-darkgray: #333333;
$schola-gray-sub: #78909c;
$schola-lemon: #ff9100;
$schola-hr-color: #cfd8dc;
$schola-back-grey: #f5f5f5;
$schola-border: #b0bec5;
$schola-bluemoon: #eaf0fa;
/* color leads v3 */
$secondary-red: #ff5757;
$secondary-orange: #fc6e35;
$secondary-purple: #7f5dff;
$secondary-yellow: #f0c52e;
$secondary-blue: #5381ef;
$secondary-teal: #5dcdd3;
$secondary-purple2: #8e44ad;
$secondary-lead-green: #29a37e;
$secondary-sea-green: #53caa6;
$secondary-green: #29a37e;
// ============= DEPRECATED DONT USE FOR FUTURE DESIGN
