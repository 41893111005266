@import '../../../sass/color-palette';
.assignments-page{
    select {
        background-image: unset;
    }

    .btn-add-assignment {
        margin-right: 1rem;
    }
    
    .btn-save-assignment {
        margin-top: 8px;
        float: right;
    }

    .input-begin-at {
        margin-right: 1rem;
    }

    #filter-active {
        margin-right:8px;
    }

    .requiredField {
        color: $red-legacy;
    }
    
}