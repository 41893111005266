.container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.heading {
  font-size: 1.125rem !important;
  font-weight: 600 !important;
}
.label {
  margin-bottom: 0 !important;
}
