
.modal_mobile {
  background: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  &_header {
    padding: 1rem;
    display: grid;
    place-items: center;
    position: relative;
    min-height: 56px;

    border-bottom: 1px solid #CEDFE4;

    &_title {
      max-width: 70%;
    }

    .buttonIcon {
      padding: .5rem;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 1rem);      
    }

    .close {
      left: .5rem;

      @media screen and (min-width: 526px) {
        left: inherit;
        right: .5rem;
      }
    }

    .back {
      left: .5rem;
    }
  }

  &_children {
    height: 100vh;
    overflow-y: scroll;
  }
}