.location_landing--header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .button {
        font-size: 14px;
    }
}

.create-landing__form--wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 64px;
}

.create-landing__form--inputs {
    width: 75%;
    margin-right: 40px;

    h3 {
        font-size: 20px;
        font-weight: $medium;
    }

    .landing-page-upload__image-input {
        border: 4px dashed $schola-mist;
        padding: 40px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        .file-input__label {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
            font-weight: $medium;
            color: $schola-mist;
        }

        .upload-placeholder {
            margin-bottom: 24px;
        }
    }
}

.hero-section, .subsection {
    h3 {
        margin-bottom: 24px;
    }
}

.hero-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.create-landing__form--inputs > * + * {
    margin-top: 64px;
}

.hero-section__left, .subsection__text, .landing-page-upload__image-input {
    width: 400px;
}

.state-dropdown {
    width: 96px;
}

.hero-section__left, .subsection__text {
    .hero-section__upload-wrapper, .validation-message__wrapper {
        margin: 20px 0;
    }
}

.subsection__left, .subsection__img {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.hero-section__left, .subsection__text, .subsection__img > .subsection__img--upload-wrapper {
    margin-right: 72px;
}

.hero-section__upload-wrapper,
.subsection__img--upload-wrapper {
    span {
        display: block;
        font-size: 14px;
        font-weight: $medium;
        margin-bottom: 8px;
    }
}

.landing-page-upload__preview {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;

    img {
        max-width: 120px;
        height: auto;
    }
}

.create-landing__form--initial {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .enable-page {
        margin-left: 40px;
    }
}

.landing-pages-list > * {
    margin: 8px;
}

.landing-pages-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
}

.landing-page--li {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 128px;
    height: 128px;
    font-size: 18px;
    font-weight: $medium;
    border-radius: $card-border-radius;
    box-shadow: $card-box-shadow;
    padding-top: 20px;

    &:hover {
        .edit__landing-page--wrapper {
            background: $primary;
            color: $white;
            border-bottom-right-radius: $card-border-radius;
            border-bottom-left-radius: $card-border-radius;
        }
    }
}

.edit__landing-page,
.disabled-tag,
.landing-page__location {
    text-align: center;
}

.disabled-tag {
    font-size: 14px;
    font-weight: $regular;
    color: $schola-lightgrey;
}

.landing-page__location {
    font-size: 32px;
    color: $schola-gray;
}

.edit__landing-page {
    font-size: 18px;
}

.edit__landing-page--wrapper {
    width: 100%;
    padding: 8px 0;
}
