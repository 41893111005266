.wrapper {
  height: 100%;
}

.mapContainer {
  width: 100%;
  height: 85%;
  padding-top: 1rem;
}

.label {
  margin-bottom: 0.5rem;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}
