
@import '../color-palette';
@import '../variables';
@import '../typography';
#root,
#root > div,
.main-layout {
    min-height: 100vh;
}
.auth0-lock-center {
    padding: 0 !important;
    font-family: $sans;
}
.main-layout {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.main-layout--content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.main-layout__children--admin {
    width: calc(100% - 274px);
    padding: 1.5rem;
}

.main-layout__children {
    z-index: 0; // Allows main content to sit on top of header ribbon
    min-height: calc(100vh - 404px); // 404 = header + footer height mobile/tablet-portrait
    margin-top: -132px;
    margin-bottom: 20px;

    @media (min-width: $tablet-landscape) {
      min-height: calc(100vh - 294px); // 294 = header + footer height tablet-landscape/desktop
      margin-top: -144px;
      margin-bottom: 32px;
    }
}

.container {
    width: 100%;
    max-width: $desktop;
    margin: 0 auto;
    padding-left: 8px;
    padding-right: 8px;

    @media (min-width: $tablet-portrait) {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.paper {
    width: 100%;
    // max-width: 1024px;
    padding: 16px;
    background-color: $white;
    border-radius: $card-border-radius;
    box-shadow: $card-box-shadow;

    @media (min-width: $tablet-portrait) {
        padding: 24px;
    }

    @media (min-width: $tablet-landscape) {
        padding: 30px;
    }
    @media (min-width: 1200px){
            padding: 40px;
    }
}

.paper__heading {
    h2 {
        margin-bottom: 16px;
    }
    h3 {
        margin-bottom: 16px!important;
    }
    h4 {
        margin-bottom: 16px;
    }
    p {
        line-height: 1.25;
    }
}

.paper__footer {
  text-align: center;
}

.paper__divider {
    width: 100%;
    height: 1px;
    background: $gray-200;
    border: none;
    margin: 40px auto;
}

.text-center {
    text-align: center;
}

.main-layout--content {
    .paper {
        max-width: 1200px;
    }
}

.breadcrumb{
  width: 100%;
  position: absolute;
  top: 0;
}

.breadcrumb > span{
  float: left;
  width: 50%;
  height: 8px;
  border-right: 3px solid #ffffff;
}

.breadcrumb > span.active{
    background: $primary;
}