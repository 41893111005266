@use '../../sass/themes';
@use '../../sass/legacy/color-palette' as legacyColorPalette;

@mixin button-disabled {
  color: #c6cad1 !important;
  background-color: #fbfbfb !important;
  border: 1px solid #c6cad1 !important;
  cursor: not-allowed;
  border: none;

  &:hover {
    color: #c6cad1;
    background-color: #fbfbfb;
    border: 1px solid #c6cad1;
    cursor: not-allowed;
    border: none;
  }
}

@mixin buttonSize() {
  font-weight: 600;
  border-radius: 2px;
  transition: all 0.25s ease-out;
  font-size: 16px;
  padding: 12px 16px;

  &.active {
    box-shadow: none;
  }
  &.button--disabled {
    @include button-disabled();
  }
}
// button globals styles
.global {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  transition: all 0.25s ease-out;
  user-select: none;
  @include buttonSize();
  svg {
    display: none;
  }
}
//button element base
.elementbase {
  border: none;
  background-color: white;
  color: legacyColorPalette.$dark-blue-700;
  &:hover {
    background-color: white;
    border: none;
    outline: none;
  }
  &:active {
    background-color: white;
    border: none;
    outline: none;
  }
  &:focus {
    border: none;
    outline: none;
  }
}
//button primary
.primary {
  border: none;
  color: #ffffff !important;
  background-color: legacyColorPalette.$primary-500;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    background-color: legacyColorPalette.$primary-400;
  }
  &:active {
    text-decoration: none;
    background-color: legacyColorPalette.$primary-600;
  }
  &:focus {
    text-decoration: none;
    color: white;
    background-color: legacyColorPalette.$primary-400;
    border: 1px solid #ffffff;
    box-shadow: 0 0 0 1.5px rgba(0, 140, 255, 0.25);
    outline: none;
  }
}
//button secondary
.secondary {
  border: 1px solid legacyColorPalette.$secondary-300;
  color: legacyColorPalette.$secondary-600;
  background-color: white;
  &:hover {
    background-color: legacyColorPalette.$secondary-100;
    border: 1px solid legacyColorPalette.$secondary-300;
  }
  &:active {
    background-color: legacyColorPalette.$secondary-200;
    color: legacyColorPalette.$secondary-700;
  }
  &:focus {
    border: 1px solid legacyColorPalette.$secondary-400;
    color: legacyColorPalette.$secondary-600;
    background-color: white;
    box-shadow: 0 0 0 2px rgba(0, 153, 204, 0.25);
    outline: none;
  }
}
//button ghost
.ghost {
  border: none;
  background-color: transparent;
  color: legacyColorPalette.$secondary-600;
  &:hover {
    background-color: legacyColorPalette.$secondary-100;
    outline: none;
  }
  &:active {
    background-color: legacyColorPalette.$secondary-200;
    color: legacyColorPalette.$secondary-700;
    outline: none;
  }
  &:focus {
    background-color: transparent;
    color: legacyColorPalette.$secondary-600;
    outline: none;
  }
}
//button link
.link {
  border: none;
  background-color: transparent;
  color: legacyColorPalette.$primary-400;
  &:hover {
    border: none;
    outline: none;
    text-decoration: underline;
  }
  &:active,
  &:visited {
    border: none;
    outline: none;
    color: legacyColorPalette.$indigo;
  }
  &:focus {
    border: 1px solid legacyColorPalette.$primary-400;
    outline: none;
  }
}
//button bluelegacy
.bluelegacy {
  border: none;
  color: white;
  background-color: legacyColorPalette.$primary-400;
  &:hover {
    background-color: legacyColorPalette.$primary-500;
  }
  &:active {
    background-color: legacyColorPalette.$primary-500;
    color: legacyColorPalette.$primary-600;
  }
  &:focus {
    color: white;
    background-color: legacyColorPalette.$primary-500;
    border: 1px solid #ffffff;
    box-shadow: 0 0 0 1.5px rgba(0, 140, 255, 0.25);
    outline: none;
  }
}
//button greenlegacy
.greenlegacy {
  border: none;
  color: white;
  background-color: legacyColorPalette.$green-400;
  &:hover {
    background-color: legacyColorPalette.$green-500;
  }
  &:active {
    background-color: legacyColorPalette.$green-500;
    color: legacyColorPalette.$green-600;
  }
  &:focus {
    color: white;
    background-color: legacyColorPalette.$green-500;
    border: 1px solid #ffffff;
    box-shadow: 0 0 0 1.5px rgba(0, 140, 255, 0.25);
    outline: none;
  }
}
//button redlegacy
.danger {
  border: none;
  color: white;
  background-color: legacyColorPalette.$danger-400;
  &:hover {
    background-color: legacyColorPalette.$danger-500;
  }
  &:active {
    background-color: legacyColorPalette.$danger-500;
    color: legacyColorPalette.$danger-600;
  }
  &:focus {
    color: white;
    background-color: legacyColorPalette.$danger-500;
    border: 1px solid #ffffff;
    box-shadow: 0 0 0 1.5px rgba(0, 140, 255, 0.25);
    outline: none;
  }
}
//button disabled
.disabled {
  @include button-disabled();
}

.children {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.children_icon {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    display: block;
    height: 1.5rem;
    flex-basis: 0;
  }
}
.linkbutton {
  width: 100%;
}

.display_block {
  display: block;
  width: 100%;
}
.block_mobile {
  @media (max-width: 480px) {
    display: block;
    width: 100%;
  }
}
