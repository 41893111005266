.layoutv2 {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	.marginTop0 {
		margin-top: 0px !important;
	}
	.menuParentPortal {
		text-align: center;
		width: 75px;
		display: inline-block;
		vertical-align: top;
        @media screen and (max-width: 768px){
            width: auto;
        }
		.menuParentPortalText {
			font-size: 10px;
            @media screen and (max-width: 768px){
                display: none;
            }
		}
	}
	.board-name {
		cursor: pointer;
	}
	.board-school-name {
		min-height: 50px;
	}
	.school-name-compare {
		width: 240px;
		min-height: 48px;
	}
	.school-address-compare {
		min-height: 30px;
	}
	.no-files-content {
		margin-top: 60%;
	}
	.icons {
		.icon-nav {
			font-size: 25px;
			padding: 0 20px;
			&.active {
                @media screen and (min-width: 320px ) and (max-width: 374px){
                    padding: 0 10px;
                }
                @media screen and (min-width: 375px ) and (max-width: 480px){
                    padding: 0 15px;
                }
				i {
					color: $secondary;
				}
			}
		}
	}
	.action-board-click {
		cursor: pointer;
	}
    @media screen and (max-width: 760px){
        .backtoschola-link {
            display: none !important
        }
    }
    @media screen and (max-width: 992px) {
        .backtoschola-link p {
            font-size: 12px !important
        }
    }
    .parent-portal {
        html {
            box-sizing: border-box;
        }
        h4 {
            font-size: 12px;
            font-weight: 700;
        }
        h5 {
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
        }
        h6 {
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
            text-transform: capitalize;
        }
        input {
            border: 1px solid #dddfe7 !important;
        }
        .container-fluid {
            padding-left: 0;
            padding-right: 0;
        }
        .top-bar {
            box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
            background: #fdfdfd;
            padding: 1.5rem 0 1rem 0;
            .icons-menu {
                align-items: center;
            }
            .profile {
                padding-top: 0.5rem;
            }
            h6 {
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 150%;
                padding: 0;
                margin: 0;
            }
            .profile-photo {
                border-radius: 10px;
                overflow: hidden;
                max-height: 46px;
                max-width: 46px;
            }
        }
        .btn-outline-success {
            background: #fff;
            color: $secondary;
            border: 2px solid $secondary;
        }
        main {
            background: #f5f7f9;
            border: 1px solid #ebeff4;
            box-sizing: border-box;
            border-radius: 10px;
            min-height: 500px;
            .board-card {
                background: #fdfdfd;
                box-shadow: 0px 5px 12px rgba(0,0,0,0.08);
                border-radius: 10px;
                .favorite-icon {
                    color: #d3d3d3;
                }
                .favorite-icon-active {
                    color: #f00;
                }
            }
        }
        .modal-content {
            border-radius: unset !important;
            border: none;
            .icon-upload-file {
                height: 200px;
                border: 1.5px dashed #e2e2e2;
                background: #fdfdfd;
                padding: 1.6rem;
            }
        }
        .application-archived-title {
            h6 {
                font-weight: 500 !important;
                font-size: 16px;
                padding: 5px 8px;
                border-radius: 5px 5px 0 0;
                &.tab-active {
                    background: #fff;
                    color: #44464b;
                }
                &.tab-disable {
                    background: #f5f7f9;
                    color: #d3d3d3;
                }
            }
        }
        .icon-upload-text {
            margin-top: 2rem;
            margin-left: 2rem;
        }
        .icon-upload-file {
            i {
                font-size: 2rem;
            }
        }
        .board-nav {
            padding: 0 3rem;
        }
        span {
            &.board-count {
                font-size: 0.75rem;
                margin-top: -12px;
                margin-right: 5px;
                font-weight: 500;
            }
        }
        .favorite-icon {
            .active {
                &:before {
                    color: #f00;
                }
            }
        }
        .favorite-footer-card {
            color: #d3d3d3;
            h4 {
                margin: 0;
                font-weight: normal !important;
            }
        }
        .bg-white {
            background: #fff;
        }
        .bg-red {
            background: #ff5e5e;
        }
        .bg-yellow {
            background: #f8d55c;
        }
        .bg-green {
            background: #b2df8a;
        }
        .bg-low-green {
            background: #c0e2aa;
        }
        .bg-middle-green {
            background: $secondary;
        }
        .bg-full-green {
            background: $secondary;
        }
        .text-grey-1 {
            color: #44464b !important;
        }
        .text-black {
            color: #000 !important;
        }
        .text-lightblack {
            color: #adb1bd !important;
        }
        .bg-lightgrey {
            background: #d3d3d3;
        }
        .text-right {
            text-align: right !important;
        }
        .text-center {
            text-align: center !important;
        }
        .text-left {
            text-align: left !important;
        }
        .text-blue {
            color: $primary;
        }
        .text-red {
            color: #ff5e5e;
        }
        .text-yellow {
            color: #f8d55c;
        }
        .text-green {
            color: $secondary;
        }
        .font-bold {
            font-weight: 700 !important;
        }
        .font-600 {
            font-weight: 600;
        }
        .b-radius-3 {
            border-radius: 3.5px;
        }
        .btn-disable {
            color: #d3d3d3;
            background-color: #ebeff4;
            border-color: #ebeff4;
            font-weight: 700;
            font-size: 0.8rem;
        }
        .btn-active-1 {
            border: 1px solid $secondary;
            box-sizing: border-box;
            border-radius: 3.5px;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            align-items: center;
            text-align: center;
            letter-spacing: 0.02em;
            color: $secondary;
        }
        .btn-active-2 {
            background: $secondary;
            border-radius: 3.5px;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            align-items: center;
            text-align: center;
            letter-spacing: 0.02em;
            color: #fdfdfd;
        }
        .marginbottom5 {
            margin-bottom: 5px;
        }
        .card-img-top {
            height: 170px;
            object-fit: cover;
            object-position: center;
        }
        .breadcrumbs {
            i {
                &.fa {
                    font-size: 0.5rem;
                    margin-top: -0.3rem;
                }
            }
            .profile {
                @media screen and (max-width: 480px){
                    margin-bottom: 1rem;
                }
                span {
                    &.user-mail {
                        text-transform: lowercase;
                        font-size: 14px;
                        font-weight: normal;
                        color: #4a4a4a;
                    }
                }
            }
        }
        .breadcrumbs-submenu-right {
            .icon-map {
                width: 49px;
                height: 49px;
                background: #ebeff4;
                color: #91949f;
                border-radius: 3.5px;
            }
        }
        .board-name-cards {
            .card-title {
                text-transform: uppercase;
                color: $secondary;
                font-size: 11px;
            }
            .card-text {
                color: #adb1bd;
                font-size: 13px;
            }
            .card {
                .favorite-icon {
                    position: absolute;
                    top: 0;
                    right: 1.66rem;
                    width: 25px;
                    height: 25px;
                    background: #fff;
                    i {
                        color: #d3d3d3;
                    }
                }
            }
        }
        .board-compare {
            background: #fff;
            height: 650px;
            border-radius: 10px;
            .stars {
                i {
                    margin-right: 0.3rem;
                    color: #f8d55c;
                }
            }
            .school-photo {
                height: 157px;
                width: 100%;
                border-radius: 10px;
                overflow: hidden;
            }
            p {
                font-size: 13px;
                color: #adb1bd;
                text-transform: capitalize;
            }
        }
        .school {
            width: 274px;
        }
        .school-content-bottom {
            min-height: 250px;
            .border-bottom {
                border-bottom: 1px solid #ebeff4;
            }
        }
        .atts {
            width: 100px;
            @media screen and (min-width: 320px) and (max-width: 374px){
                width: 100px;
                margin-left: 1.5rem !important;
                margin-top: 280px;
            }
            @media screen and (min-width: 375px){
                margin-left: 1.9rem !important;
                margin-top: 300px;
            }
            @media screen and (min-width: 414px ) and (max-width: 480px){
                width: 100px;
                margin-top: 300px;
            }
            @media screen and (min-width: 1024px ){
                width: 100px !important;
                margin-left: 2rem !important;
            }
        }
        .att-name {
            padding-top: 2rem !important;
        }
        .currently-enrollin,
        .type-school,
        .tuition,
        .number-studens {
            margin-bottom: 0.5rem !important;
        }
        .board-compare {
            .scroll {
                height: 650px;
                overflow: scroll;
                width: 254px;
            }
            .smallstar {
                width: 13px;
            }
        }
        @media screen and (min-width: 320px) and (max-width: 374px){
            .board-compare .school-photo{
                height: auto;
                width: 160px;
            }
        }
        .board-compare .scroll{
            width: 160px;
        }
        @media screen and (min-width: 375px){
            .board-compare .school-photo{
                height: auto;
                width: 210px;
            }
            .board-compare .scroll{
                width: 210px;
            }
            }
        @media screen and (min-width: 414px ) and (max-width: 480px){
            .board-compare .school-photo{
                height: 160px;
                width: 240px;
            }
            .board-compare .scroll{
                width: 240px;
            }
            }
        @media screen and (min-width: 768px ){
            .board-compare .school-photo{
                height: 160px;
                width: 240px;
            }
            .board-compare .scroll{
                width: 500px;
            }
        }
        @media screen and (min-width: 1024px ){
            .board-compare .scroll{
                width: 735px;
            }
            }
        @media screen and (min-width: 1200px ){
            .board-compare .scroll {
                width: 968px;
            }
        }
        .main-student-profile,
        .main-student-application-archived,
        .main-student-tour {
            background: #fdfdfd;
            box-shadow: 0px 5px 12px rgba(0,0,0,0.08);
            border-radius: 10px;
            position: relative;
            .btn-danger {
                background: rgba(255,118,118,0.31);
                border-radius: 3.5px;
                color: $primary;
                border: none !important;
            }
            .btn-disable {
                color: #91949f;
            }
        }
        .main-student-application-archived {
            border-radius: 0px 10px 10px 10px;
        }
        .student-photo,
        .student-photo-tour {
            height: 70px;
            width: 70px;
            background: #f5f7f9;
            border-radius: 3.5px;
            font-size: 2rem;
            text-align: center;
        }
        .bars {
            height: 3px;
            width: 25px;
            margin-right: 0.3rem;
        }
        aside {
            background: #fdfdfd;
            border: 1px solid #ebeff4;
            border-radius: 10px;
            .item-student {
                background: unset;
                min-height: 100px;
                &.selected {
                    background: #f5f7f9;
                    border-top: 1px solid #808080;
                }
            }
            .student-photo-container {
                position: relative;
                height: 73px;
            }
            .fa-circle {
                position: absolute;
                top: 0;
                right: 0;
                color: #f00;
                font-size: 1rem;
            }
            .student-name {
                margin-top: 2.5rem;
            }
            .btn-add-student {
                border-radius: 3.5px;
            }
            .btn-parent-action {
                border-radius: 3.5px;
                background: $secondary !important;
                border-color: $secondary !important;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                padding-top: 12px;
                padding-bottom: 12px;
            }
            .three-points-right {
                margin: 1rem 1rem 0 0;
                width: 7%;
                letter-spacing: 2px;
                font-size: 2rem;
                color: #d3d3d3;
                position: relative;
                display: inline-block;
                a {
                    color: #d3d3d3;
                    text-decoration: none;
                }
            }
            .image-not-found {
                width: 117px;
                margin: auto;
            }
        }
        aside .student-photo-container,
        aside .student-photo {
            margin-top: 1.5rem;
            margin-right: 1.5rem;
        }
        .menu-content-three-points,
        .menu-content-dropdown {
            position: relative;
            display: inline-block;
            cursor: pointer;
        }
        .menu-content-dropdown-content {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            padding: 12px 16px;
            z-index: 1;
            right: 0;
        }
        .menu-content-dropdown:hover .menu-content-dropdown-content,
        .menu-content-three-points:hover .menu-content-dropdown-content {
            display: block;
        }
        .status-mark {
            width: 25px;
            height: 3px;
        }
        .status-resheduled {
            p {
                color: #f00 !important;
            }
            .status-mark {
                background: #f00;
            }
        }
        .status-upcoming {
            p {
                color: $secondary !important;
            }
            .status-mark {
                background: $secondary;
            }
        }
        .status-pending {
            p {
                color: #eac032 !important;
            }
            .status-mark {
                background: #eac032;
            }
        }
        .status-complete {
            p {
                color: $primary !important;
            }
            .status-mark {
                background: $primary;
            }
        }
        .tour-student-photo {
            height: 50px;
            width: 50px;
            border-radius: 3.5px;
            overflow: hidden;
        }
        .alerts {
            background: #fdfdfd;
            border: 1px solid #ebeff4;
            box-sizing: border-box;
            border-radius: 10px;
        }
        .alert-icon {
            box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
            border-radius: 10px;
            height: 45px;
            width: 45px;
            img {
                margin-top: 30%;
                margin-left: 30%;
            }
        }
        .alert-icon-reschedule {
            background: #ff5e5e;
        }
        .alert-icon-confirmed {
            background: $secondary;
        }
        .alert-icon-cancelled {
            background: #adb1bd;
        }
        .alert-description {
            p {
                margin: 0.3rem 0;
            }
        }
        .schedule-button {
            border: 1.5px solid #40afd9;
            box-sizing: border-box;
            border-radius: 10px;
            font-weight: 700;
            margin-bottom: 1rem !important;
            font-size: 14px;
            line-height: 150%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.02em;
            color: #40afd9;
            border-radius: 3.5px;
            &.selected {
                background: #40afd9;
                border-radius: 10px;
                border: none;
                color: #fdfdfd;
                border-radius: 3.5px;
            }
            &.disable {
                color: #d3d3d3;
                background-color: #ebeff4;
                border-color: #ebeff4;
            }
        }
        .search-result .modal__content,
        .container .modal__content {
            max-width: 961px !important;
            border-radius: unset;
        }
        .DayPicker-Caption {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 150%;
        }
        .DayPicker-Weekday {
            abbr[title] {
                color: $secondary !important;
                font-weight: 700;
            }
        }
        .DayPicker-Day--selected {
            &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                background-color: $secondary;
            }
        }
        @media screen and ( min-width: 1200px ){
            .DayPicker-Weekday{
                padding: 1rem;
            }
            .DayPicker-Day{
                padding: 1rem!important;
            }
        }
        .account-settings {
            background: #fdfdfd;
            border: 1px solid #ebeff4;
            border-radius: 10px;
            .status-mark {
                margin-bottom: 0.5rem;
                &.active {
                    background: $secondary;
                }
            }
        }
        tatus-mark {
            background: #fff;
        }
        .account-settings-items {
            .item {
                h6 {
                    margin-right: 1rem;
                }
            }
        }
        .main-column,
        .saved-searches {
            background: #fdfdfd;
            border: 1px solid #ebeff4;
            border-radius: 10px;
        }
        .main-column {
            .status-mark {
                margin-bottom: 0.5rem;
                background: #fff;
                &.active {
                    background: $secondary;
                }
            }
            .dont-searches {
                margin-top: 30%;
            }
        }
        .main-column-items {
            .item {
                h6 {
                    margin-right: 1rem;
                }
            }
        }
        .main-column-modal {
            .icon-upload-file {
                width: 100%;
                height: 230px;
            }
        }
        .icon-upload-file {
            .icon {
                width: 51px;
                margin: 1rem auto;
                i {
                    font-size: 3rem;
                }
            }
        }
        
        
    }
    .parent-portal *,
    .parent-portal *:before,
    .parent-portal *:after {
        box-sizing: inherit;
    }
    .parent-portal a,
    .parent-portal i {
        color: #d3d3d3;
    }
    .parent-portal .board-compare h5,
    .parent-portal .board-compare p {
        margin: 0;
        padding: 0;
    }
}
.modal-confim-delete {
	.modal__content {
		z-index: 1000;
		.modal__header {
			display: none !important;
		}
		.modal__children {
			padding: 0;
			.content {
				.header {
					height: 80px;
					box-shadow: 0px 2px 4px rgba(0,0,0,0.05);
					border-radius: 3.5px;
					padding: 15px;
					padding-top: 20px;
					.titles {
						margin-left: 20px;
						float: left;
						.title {
							font-weight: bold;
							font-size: 18px;
							line-height: 150%;
							display: flex;
							align-items: center;
							letter-spacing: 0.02em;
							color: #44464b;
							border-radius: 3.5px;
						}
						.sub-title {
							font-weight: 500;
							font-size: 14px;
							line-height: 150%;
							letter-spacing: 0.02em;
							color: #44464b;
						}
					}
					.close-box {
						float: right;
					}
				}
				.body {
					padding-top: 19px;
					.btn-area {
						margin: auto;
						.btn-white {
							width: 255px;
							height: 45px;
							font-weight: 600;
							font-size: 14px;
							line-height: 150%;
							display: flex;
							align-items: center;
							text-align: center;
							letter-spacing: 0.02em;
							color: $secondary;
							border: 1px solid $secondary;
							box-sizing: border-box;
							border-radius: 3.5px;
							float: left;
							margin: 10px;
							background: #fff;
							.button__children {
								margin: 0 auto;
							}
						}
						.btn-green {
							width: 255px;
							height: 45px;
							font-weight: 600;
							font-size: 14px;
							line-height: 150%;
							display: flex;
							align-items: center;
							text-align: center;
							letter-spacing: 0.02em;
							color: #fff;
							background: $secondary;
							border-radius: 3.5px;
							float: left;
							margin: 10px;
							.button__children {
								margin: 0 auto;
							}
						}
					}
				}
			}
		}
	}
}

.modal-upload-file {
	.modal__content {
		z-index: 1000;
		.modal__header {
			display: none;
		}
	}
}
.form-check {
	.checkbox {
		.checkbox-icon {
			float: left;
		}
		.checkbox-label {
			float: left;
			font-weight: 500;
			font-size: 13px;
			line-height: 150%;
			color: #44464b;
			border-radius: 3.5px;
		}
	}
}
.form-group {
	.geosuggest {
		top: 0px;
	}
	select {
		padding: 0px;
	}
}
.modal-application {
	.modal__content {
		z-index: 1000;
		width: 620px;
		height: 400px;
		.modal__header {
			display: none;
			padding: 0;
		}
		.modal__children {
			padding: 0px;
			.content {
				.header {
					height: 80px;
					box-shadow: 0px 2px 4px rgba(0,0,0,0.05);
					border-radius: 3.5px;
					padding: 15px;
					padding-top: 20px;
					.titles {
						margin-left: 20px;
						float: left;
						.title {
							font-weight: bold;
							font-size: 18px;
							line-height: 150%;
							display: flex;
							align-items: center;
							letter-spacing: 0.02em;
							color: #44464b;
							border-radius: 3.5px;
						}
						.sub-title {
							font-weight: 500;
							font-size: 14px;
							line-height: 150%;
							letter-spacing: 0.02em;
							color: #44464b;
						}
					}
					.close-box {
						float: right;
					}
				}
				.body {
					margin: 0 auto;
					.app-content {
						width: 600px;
						height: 200px;
						padding: 65px;
						overflow: overlay;
						.app-info1 {
							font-weight: 500;
							font-size: 14px;
							line-height: 150%;
							text-align: center;
							letter-spacing: 0.02em;
							color: #44464b;
							border-radius: 3.5px;
						}
						.app-info2 {
							font-weight: 500;
							font-size: 13px;
							line-height: 150%;
							text-align: center;
							color: #91949f;
							border-radius: 3.5px;
						}
					}
					.buttons-content {
						margin: 0 auto;
						width: 525px;
						.btn-green {
							text-decoration: none;
							width: 521px;
							height: 45px;
							background: $secondary;
							border-radius: 3.5px;
							font-weight: 600;
							font-size: 14px;
							line-height: 150%;
							display: flex;
							align-items: center;
							text-align: center;
							letter-spacing: 0.02em;
							color: #fff;
							margin: 10px 0;
							&.unable {
								color: #adb1bd;
								background: #ebeff4;
							}
							.button__children {
								margin: 0 auto;
							}
						}
					}
				}
			}
		}
	}
}
.request-info-modal {
	.modal__header {
		display: flex !important;
		justify-content: space-between !important;
	}
	.modal__content {
		min-width: 80%;
        @media (min-width: 1572px){
            min-width: 50%;
        }
		.request-info-form {
			label {
				font-size: 14px;
				margin-bottom: 0.5rem;
			}
			.prev-school-form__section {
				.school-dropdown__menu {
					position: absolute;
					left: 0;
					z-index: 2;
					border-radius: 3px;
                    box-shadow: 0 2px 12px rgba(0,0,0,.1);
					background: hsla(0,0%,100%,.9);
					padding: 2px 0;
					font-size: 90%;
					width: 100%;
					overflow: auto;
					min-height: 100%;
				}
				.school-dropdown__results {
					padding: 10px;
					cursor: pointer;
					z-index: 4;
				}
				.satisfied-slider-container {
					margin: 40px 0;
					height: 80px;
				}
				.satisfied-slider {
                    @media (max-width: $screen-md){
                        margin-left: 30px !important;
                    }
					.rc-slider-mark-text {
                        @media (max-width: $screen-md){
                            font-size: 10px;
                        }
					}
				}
			}
            .schola-select {
                font-size: 12px;
                color: #c9cdd7 !important;
                padding: 0;
                .schola__value-container {
                    padding: 0 8px;
                    .schola__input {
                        input {
                            height: 30px !important;
                            border: none !important;
                        }
                    }
                }
                .schola__menu {
                    color: #484848 !important;
                }
                .schola__control {
                    box-shadow: none !important;
                    min-height: 33px;
                    border-radius: 3.5px;
                    border: 0.7px solid #dddfe7;
                    .schola__indicator-separator {
                        margin-bottom: 12px;
                        margin-top: 9px;
                    }
                    &:hover {
                        border-color: $secondary !important;
                    }
                }
            }
            .enrollment-interest-form__section {
                padding: 0.6rem 0;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                :nth-child(3),
                :nth-child(6) {
                    margin-right: 0 !important;
                }
                .enrollment-interest-form__small-input,
                .enrollment-interest-form__large-input {
                    width: 100%;
                    width: 30%;
                }
                .validation-message__wrapper {
                    margin-top: 16px;
                    margin-right: 2rem;
                }
                .text-label,
                .dropdown-label {
                    font-weight: 400 !important;
                    font-size: 14px !important;
                    color: #4a4a4a !important;
                    div {
                        font-weight: 400;
                        font-size: 14px;
                    }
                }
            }
            .previous-school {
                padding: 0.7rem;
            }
		}
	}
}
.btn-app-control {
	width: 204px;
	height: 35px;
	background: $secondary;
	border-radius: 3.5px;
	font-weight: bold;
	font-size: 12px;
	line-height: 150%;
	align-items: center;
	text-align: center;
	letter-spacing: 0.04em;
	color: #fdfdfd;
	&.disable {
		background: #ddd;
	}
}
.application {
	select {
		padding: 0px;
		padding-left: 1em;
		color: #91949f;
	}
}
.text-info-light-grey {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 150%;
	color: #91949f;
}
.text-info-dark-grey {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 150%;
	color: #44464b;
	border-radius: 3.5px;
}
.tour-btn-control {
	.btn-green1 {
		width: 150px;
		height: 45px;
		border: 1px solid $secondary;
		box-sizing: border-box;
		border-radius: 3.5px;
		font-weight: 600;
		font-size: 14px;
		line-height: 150%;
		align-items: center;
		text-align: center;
		letter-spacing: 0.02em;
		color: $secondary;
	}
}
.add-board-title {
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	letter-spacing: 0.02em;
	color: #adb1bd;
}
.input-error-empty {
	border: thin solid #f00 !important;
}
.btn-manager-user-function {
	border-color: $secondary !important;
	background: $secondary !important;
}
