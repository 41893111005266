.label {
  padding: 4px 8px;
  border-radius: 2px;
  font-size: 12px;
  width: fit-content;
  border: 1px solid rgba(241, 242, 243, 1);
}

.active {
  border: 1px solid rgba(77, 184, 219, 1);
  background: rgba(230, 245, 250, 1);
  color: rgba(77, 184, 219, 1);
}
.paused {
  border: 1px solid rgba(255, 213, 145, 1);
  background: rgba(255, 247, 230, 1);
  color: rgba(255, 213, 145, 1);
}
.running {
  border: 1px solid rgba(183, 235, 143, 1);
  background: rgba(246, 255, 237, 1);
  color: rgba(183, 235, 143, 1);
}

@mixin grid {
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;
}

.row {
  @include grid;
  font-size: 10px;
  border-bottom: 1px solid rgba(241, 242, 243, 1);
  color: rgba(0, 0, 0, 0.45);
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.campaignsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.campaign {
  @include grid;
}

.campaignTitle {
  color: rgba(0, 0, 0, 1);
}

.additional {
  display: inline-block;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 1rem;
}
.link {
  color: rgba(0, 107, 143, 1) !important;
  cursor: pointer;
}

.wrapperCenter {
  display: grid;
  place-items: center;
}
