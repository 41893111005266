@import 'color-palette.scss';
@import 'typography.scss';
/*
320px — 480px: Mobile devices
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV
*/

$screen-xs: 480px; // 320px480px: Mobile devices
$screen-md: 768px; // 481px768px: iPads, Tablets
$screen-xl: 1200px; // Large devices (laptops/desktops, 992px)

//Legacy vars:
$tablet-portrait: 600px;
$tablet-landscape: 900px;
$desktop: 1200px;

$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$Extra: 800;

/* Lagacy Settings */
$gutter: 1rem;
$card-border-radius: 0.5rem;
$card-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
$fab-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.16);
$box-shadow-onboarding: 1px 0 10px rgba(33, 53, 86, 0.2);

// Medium devices (landscape tablets, 768px)
$screen-xs: 575px; // Small devices (portrait tablets and large phones, 576px)
$screen-sm: 767px; // Medium devices (landscape tablets, 768px)
$screen-md: 991px; // Large devices (laptops/desktops, 992px)
$screen-lg: $desktop - 1; // Extra large devices (large laptops and desktops, 1200px)
/*
$white : #fff;
$gray-100 : #f1f2f7;
$gray-200 : #d1d3dd;
$gray-300 : #b9bcc6;
$gray-400 : #8d919a;
$gray-500 : #6e717a;
$gray-600 : #5a5e69;
$gray-700 : #444952;
$gray-800 : #30343c;
$gray-900 : #1f2228;
$gray-1000 : #090a0d;
$black : #000; */

$fill-border: #dfe4ea;
$text-primary: #44464b;
$text-primary-blue: #0099cc;
$text-secondary: #6c6d70;
$text-placeholder: #adb1bd;
