
.zipcode {

  & label {
    width: 100%;
  }

  &_buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

.success {
  text-align: center;
}

.previousContent {
  max-width: 605px;
}