.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container {
  padding: 24px;
  max-width: 1200px;
  width: 100%;
}

.headingContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
}

.heading {
  font-weight: 700 !important;
  margin-bottom: 1rem;
  font-size: 1.125rem !important;
}

.description {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 24px;
}

.form {
  :global {
    .ant-form-item-label > label {
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
    }
  }
}
.halfWidth {
  flex: 1;
  min-width: 0; // Prevents flex items from overflowing
}

.datePicker {
  width: 100%;
}

.submitButton {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
