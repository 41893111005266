.pagination-controls {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    height: 32px;
    line-height: 32px;

    button {
        border: none;
        background: none;
        padding: 0;
    }

    .page-size__select{
         width: 70px;
         font-size: 14px;
    }
    .page-size__span{
        font-size: 14px;
        padding-left: 10px;
        padding-right: 20px;
    }

    .pagination__count {
        display: block;
        margin-right: 24px;
    }
    .pagination-controls__select{
         width: 70px;
         font-size: 14px;
    } 
    .pagination-controls__arrows {

        button {
            display: inline-block;

            &:nth-child(2) {
                margin-left: 16px;
            }

            .icon {
                transition: all 0.25s ease-in-out;
            }

            &:hover {
            cursor: pointer;

                .icon {
                    fill: $schola-darkgray;
                }
            }
        }
    }
}
