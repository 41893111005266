.inputTitle {
  width: fit-content !important;
  color: #213556 !important;
  font-size: 22px !important;
  line-height: 26.4px;
  font-weight: 600;
  border: none !important;
  background: transparent !important;
  padding: 0 !important;
  border-radius: 0;
  font-family: 'Open Sans', sans-serif;

  &:focus {
    border: 1px solid #006b8f !important;
  }
}
