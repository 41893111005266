.request-to-connect__modal {
    width: 100%;
    padding: 0px !important;

    .modal__content{
        width: 67%;
        background: $white;
        box-shadow: 0 3px 6px 0 rgba(0,154,211,0.08), 0 3px 12px 0 rgba(0,0,0,0.06);
        border-radius: 4px;

        @media (min-width: $tablet-portrait) {
            width: 100%;
        }
        @media (min-width: $tablet-landscape) {
            width: 100%;
        }
        @media (min-width: $desktop) {
            width: 67%;
        }

        .modal__header{

            .modal__close {
                visibility: hidden;
                display: inline-block;
                cursor: pointer;

                .icon {
                    margin: 4px;
                    fill: $schola-mist;
                }
            }
        }
        .modal__children{
            padding: 0px !important;

                @media (min-width: $tablet-portrait) {
                    padding: 0px;
                }

                @media (min-width: $tablet-landscape) {
                    padding: 0px;
                    padding-top: 0; // Close button in title provides padding-top for desktop
                }

                @media (min-width: $desktop) {
                    padding: 0px;
                    padding-top: 0; // Close button in title provides padding-top for desktop
                }
        }
    }

    .requesttoconnect-form{
        width: 100%;

        .breadcrumb{
            width: 100%;
            position: absolute;
            top: 0;
        }

        .breadcrumb > span{
            float: left;
            width: 50%;
            height: 8px;
        }

        .breadcrumb > span.active{
            background: $primary;
            border-right: 3px solid $white;
        }

        .breadcrumb > span.lastactive{
            background: $primary;
            border-right: 3px solid $primary  !important;
        }

        .breadcrumb > span.full_active{
            background: $primary;
            border-right: 3px solid $primary  !important;
        }

        .requesttoconnect__header__main{
            text-align: center;
            padding: 0px;
            margin: 0px;

            .title{
                font-family: $sans;
                color: $primary;
                line-height: 32px;
                font-size: 24px;
                letter-spacing: 0;
            }
        }

        .requesttoconnect--container{
            padding: 40px 32px 32px 64px; 
            margin: 0px;            

            .list--row{
                display: flex;           
                display: -webkit-flex;
                flex-direction: row;     
                -webkit-flex-direction: row;
                flex-grow: 0;            
                -webkit-flex-grow: 0;
                flex-wrap: wrap;         
                -webkit-flex-wrap: wrap;
                width: 100%;
                border-collapse: collapse;

                &.info{
                    color: $schola-gray;
                    padding: 0px;
                    font-size: 12px;
                    .list--cell{
                        text-transform: capitalize;
                        font-weight: $bold;
                    }
                }
            }

            .list--part{
                display: flex;
                flex-grow: 1;            
                -webkit-flex-grow: 1;
                text-overflow: ellipsis;
                flex-direction: column;
                overflow-wrap: normal;
                line-height: 24px;

                &.left{
                    max-width: 40%;
                    overflow-y: auto;
                }
                &.right{
                    max-width: 60%;
                    padding-left: 16px;
                }

                .detail--part{
                    text-overflow: ellipsis;
                    padding-right: 0px;
                    padding-bottom: 20px;
                    flex-direction: column;
                    overflow-wrap: normal;

                    .detail--header{
                        opacity: 0.88;
                        font-family: $sans;
                        font-weight: $bold;
                        font-size: 12px;
                        color: $schola-gray-sub;
                        letter-spacing: 0;
                        line-height: 24px;
                        margin-bottom: 4px;
                    }
                    .detail--info{
                        font-family: $sans;
                        font-weight: $medium;
                        font-size: 14px;
                        color: $schola-gray;
                        letter-spacing: 0;
                        line-height: 24px;

                    }
                }

                .requesttoconnect__flexwrapper {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .validation-message__wrapper{
                        margin-bottom: 24px;
                        margin-top: 0px;

                        .validation-message{
                            font-weight: $medium;
                            font-size: 12px;
                        }

                        .text-label{
                            margin: 0px;
                            opacity: 0.88;
                            font-family: $sans;
                            font-weight: $bold;
                            font-size: 12px;
                            color: $schola-gray-sub;
                            letter-spacing: 0;
                            line-height: 24px;
                        }
                        .text-area{
                            padding: 16px;
                            opacity: 0.88;
                            font-family: $sans;
                            font-size: 14px;
                            letter-spacing: 0;
                            line-height: 24px;
                            resize: none;
                        }
                        .text-input{
                            padding: 4px 16px;
                            opacity: 0.88;
                            font-family: $sans;
                            font-weight: $medium;
                            font-size: 14px;
                            color: $primary;
                            letter-spacing: 0;
                            line-height: 24px;
                            border: 1px solid $schola-border;
                        }
                    }
                }
            }
        }

         .requesttoconnect--footer{
            padding-top: 16px;
            padding-bottom: 0px;
            overflow: auto;

            .loader{
                margin: 0 auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            .requesttoconnect__control{
                float: right;  
                margin-bottom: 0px;

                .btn-next{
                    padding: 8px 33px;
                    background: $primary;
                    border-radius: 24px;
                    width: 122px;
                    min-height : 40px; 
                    font-family: $sans;
                    font-weight: $bold;
                    font-size: 14px;
                    color: $white;
                    letter-spacing: 0;
                    line-height: 24px;     
                }

                .btn-back{
                    padding: 8px 33px;
                    background-color: transparent;
                    border: 1px solid $schola-gray-sub;
                    border-radius: 24px;
                    width: 122px;
                    min-height : 40px; 
                    font-family: $sans;
                    font-weight: $bold;
                    font-size: 14px;
                    color: $schola-gray-sub;
                    letter-spacing: 0;
                    line-height: 24px;
                }
                &.hidden{
                    display: none;
                }
            }
        }

        .card-info {
            background-color: $white;
            text-align: center;
            margin: 0px;
            padding: 0 20px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: .5px;
            text-transform: none;
            margin-bottom: 16px;

            h4{
                margin: 0 5px;
                width: 100%;
            }

            &.success{
                color: #00b349;
            }
            &.error{
                color: #eb0000;
            }
            &.hidden{
                display: none;
            }
        }

         // popup styles

        .payment-form{
            position: relative;
        }

        .popup-payment{
            padding: 0px 32px 24px 48px;
            display: block;
            .popup-payment__header{
                width: 100%;
                color: $primary;
                text-align: center;
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 32px;
                font-family: $sans;
                letter-spacing: 0;
                font-weight: 500;

            }

            //payment form
            .popup-payment__method{
            width: 44%;
            display: inline-block;
            padding-left: 16px;
                .method__title{
                    opacity: 0.72;
                    font-size: 14px;
                    color: $schola-gray-sub;
                    line-height: 18px;
                }

                .StripeElement {
                    width: 50px;
                }

                // .StripeElement--focus {
                //     border-color: #80bdff;
                //     outline: 0;
                //     box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
                // }

                .card-input{
                    height: 32px;
                    width: 240px;
                    border: 1px solid #B0BEC5;
                    font-family: $sans;
                    font-weight: $bold;
                    font-size: 14px;
                    color: #4A4A4A;
                    letter-spacing: 0;
                    line-height: 24px;
                    padding-left: 8px;
                    padding-top: 4px;
                    padding-bottom: 4px;

                    &.half{
                        width: 116px;
                        float: left;
                        &.last{
                            margin-left: 0px;
                        }
                    }
                    &:focus{
                        outline: none;
                    }
                }

                .card-label{
                    display: block;
                    opacity: 0.88;
                    font-size: 12px;
                    color:$schola-gray-sub;
                    line-height: 24px;
                    font-weight: $bold;
                    margin-top: 8px;
                }

                .input--card--save{
                    clear: both;
                    display: block;
                    width: inherit;

                    .checkbox-label{
                        padding-left: 0px;
                        font-family: $sans;
                        font-weight: $medium;
                        font-size: 14px;
                        color: $schola-border;
                        letter-spacing: 0;
                    }
                }
                
                .card__saved_cards{
                    clear: both;
                    &:before{
                    clear: both;
                    }
                    display: block;
                    margin-top: 0px; 
                    .saved_cards__title{
                        font-family: $sans;
                        font-weight: $bold;
                        font-size: 16px;
                        color: $schola-gray;
                        line-height: 24px;  
                        padding-top: 24px;
                        letter-spacing: 0;
                    }

                    .input--holder{
                      margin-top: 12px;
                        display: grid;
                    }
                    
                    .input--card{
                        padding-top: 2px;
                        padding-bottom: 2px;
                        display: inline-block;
                        opacity: 1.0;

                        .card--radio{
                            margin-top:11px;
                            margin-right:0px;
                            margin-bottom:6px;
                            margin-left:2px;                            
                            width: 20px;
                            height: 20px;
                        }

                        .card--image{
                            display: inline-block;
                            padding-top: 0px;
                            padding-bottom: 0px;
                            margin-left: 0px;
                            height: 40px;
                            width : 64px;
                            opacity: 1.0;

                            img{
                                height: 40px;
                                width : 64px;
                            }   
                        }
                        .card--detail{
                            opacity: 1.0;
                            padding-top: 4px;
                            padding-bottom: 4px;
                            margin-left: 0px;
                            text-align: left;
                            font-family: $sans;
                            font-weight: $medium;
                            font-size: 14px;
                            color: $schola-gray;
                            letter-spacing: 0;
                            line-height: 24px;

                        }
                    }
                }

                .input--holder{
                    display: block;
                    margin-top: 38px;
                    input{
                        display: inline;
                        vertical-align: top;
                    }
                    span{
                        margin-left: 10px;
                        display: inline;
                        vertical-align: middle;
                        opacity: 0.72;
                        font-size: 16px;
                        color: $schola-gray;
                        line-height: 24px;
                    }
                    &.date{
                        float: left;
                    }

                    .card--radio{
                        margin-top:4px;
                        margin-right:0px;
                        margin-bottom:4px;
                        margin-left:0px;                            
                        width: 20px;
                        height: 20px;
                    }
                }

                .exp-class{
                    min-height: 64px;
                    width: 116px;
                    display: inline-block;
                    float : left;

                    .validation-message__wrapper{
                        min-height: 32px;

                        .validation-message{
                            margin-top: 0px;
                        }
                    }
                    .card-input{
                        margin-bottom: 4px;
                    }
                }

                .cvv-class{
                    min-height: 64px;
                    width: 116px;
                    display: inline-block;
                    margin-left: 8px;

                    .validation-message__wrapper{
                        min-height: 32px;

                        .validation-message{
                            margin-top: 0px;
                        }
                    }

                    .card-input{
                        margin-bottom: 4px;
                    }
                }

                .card-num-input{
                    height: 32px;
                    width: 240px;
                    border: 1px solid #B0BEC5;
                    font-family: $sans;
                    font-weight: $bold;
                    font-size: 14px;
                    color: #4A4A4A;
                    letter-spacing: 0;
                    line-height: 24px;
                    padding-left: 0px;
                    padding-top: 0px;
                    padding-bottom: 0px;

                    .card-num-class{
                         padding-left: 8px;
                        padding-top: 6px;
                        padding-bottom: 0px;
                        padding-right: 8px;

                        height: 32px;
                        width: 170px;
                        display: inline-block;
                    }
                    .card-type{
                        height: 30px;
                        width: 50px;
                        display: inline-block;
                        margin-left: auto;
                        margin-right: 0px;
                        float: right;
                        vertical-align: top;

                        &.hidden{
                            visibility: hidden;
                        }
                         img{
                                height: 30px;
                                width : 50px;
                            }   
                    }
                }

                .validation-message{
                    font-weight: $medium;
                    font-size: 12px;
                }

            } 

            //order price
            .popup-payment__order{
                vertical-align: top;
                width: 56%;
                display: inline-block;
                .order__title{
                    opacity: 0.72;
                    font-size: 14px;
                    color: $schola-gray-sub;
                    line-height: 18px;
                }
                .requesttoconnect__footer{
                    position: absolute;
                    bottom: 24px;
                    right: 32px;
                    padding-bottom: 0px;
                    overflow: auto;
                    float: right;  
                    margin-bottom: 0px;
                    .btn-back{
                        margin-bottom: 0px;
                        background-color: transparent;
                        border: 1px solid $schola-gray-sub;
                        border-radius: 24px;
                        width: 122px;
                        min-height : 40px; 
                        font-family: $sans;
                        font-weight: $bold;
                        font-size: 14px;
                        color: $schola-gray-sub;
                        letter-spacing: 0;
                        line-height: 24px;
                    }
                }       
                .order__container{
                    text-align: center;
                    box-shadow: 0 3px 6px 0 rgba(0,154,211,0.08), 0 3px 12px 0 rgba(0,0,0,0.06);
                    height: 240px;
                    padding: 24px;
                    margin-top: 38px;
                    .order--heading{
                        font-size: 16px;
                        color: $schola-gray;
                        font-weight: $bold;
                        line-height: 24px;

                    }
                    .order--sub-heading{
                        font-size: 16px;
                        color: $schola-gray-sub;
                        line-height: 24px;
                        margin-top: 24px;
                    }
                    .order--price{
                        font-size: 32px;
                        color: #8AC341;
                        line-height: 48px;
                        margin-bottom: 32px;
                        font-weight: $regular;
                    }
                    .button{
                        border-radius: 24px;
                        height: 40px;
                        width: 262px;
                        padding: 8px;

                        &.hidden{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
