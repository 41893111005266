@use '../../../../../sass/color-palette' as palette;

.card {
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }

  &:hover .bottomBar {
    opacity: 1;
    z-index: 1;
  }
}

.cardHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.divider {
  border: 1px solid #f3f3f3;
}

.cardTitle {
  font-size: 0.875rem;
  color: #6b7280; // text-gray-500
}

.cardValue {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  margin-top: 0.25rem !important;
}

.cardTotal {
  font-size: 0.875rem;
  color: #6b7280; // text-gray-500
  margin-top: 0.25rem;
}

.iconContainer {
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid;
}

.icon {
  font-size: 1rem;
  height: 1.25rem;
  width: 1.25rem;
  display: flex;
  justify-content: center;
}

.percentageContainer {
  position: relative;
  width: 7rem;
  height: 7rem;
}

.percentageSvg {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

.percentageCircle {
  stroke: currentColor;
  stroke-width: 4;
  fill: white;
}

.percentageText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  font-weight: 600;
}

.separator {
  margin: 1rem 0;
}

.trendContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;

  .range {
    color: #5f6a7d;
    font-size: 14px !important;
    text-align: center;
  }
}

.grayContainer {
  background-color: #fcfcfc;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  border: 1px solid darken(#fafafa, 5%);
  border-radius: 8px;
  padding: 0.75rem 0;
}

.trendIcon {
  height: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
}

.bottomBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.25rem;
  opacity: 0;
  transition: opacity 0.3s;
}

.lightGray {
  color: palette.$navy-blue-10; // bg-gray-50
}

.textPrimary {
  color: palette.$primary-400; // text-blue-500
}

.textSuccess {
  color: #10b981; // text-emerald-500
}

.textWarning {
  color: #f97316; // text-orange-500
}

.textDestructive {
  color: palette.$danger-400; // text-red-500
}

.bgPrimary {
  background-color: palette.$primary-400; // bg-blue-500
}

.bgSuccess {
  background-color: #10b981; // bg-emerald-500
}

.bgWarning {
  background-color: #f97316; // bg-orange-500
}

.bgDestructive {
  background-color: palette.$danger-400; // bg-red-500
}

.iconBgPrimary {
  background-color: palette.$primary-100; // bg-blue-50
}

.iconBgSuccess {
  background-color: #ecfdf5; // bg-emerald-50
}

.iconBgWarning {
  background-color: #fff7ed; // bg-orange-50
}

.iconBgDestructive {
  background-color: palette.$danger-100; // bg-red-50
}

.ringPrimary {
  border-color: palette.$primary-400; // ring-blue-500
}

.ringSuccess {
  border-color: #10b981; // ring-emerald-500
}

.ringWarning {
  border-color: #f97316; // ring-orange-500
}

.ringDestructive {
  border-color: palette.$danger-400; // ring-red-500
}
