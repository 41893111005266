.school-feature__slider {
    position: relative;
    margin-top: 15px;
    padding-top: 60px;
}

.school-feature__slider--label {
    font-size: 14px;
    font-weight: $medium;
    position: absolute;
    top: 0;
}

.school-feature__slider--indicator-left {
    font-size: 14px;
    font-weight: $medium;
    color: $gray-400;
    position: absolute;
    left: 0;
    top: 36px;
    user-select: none;
}

.school-feature__slider--indicator-right {
    font-size: 14px;
    font-weight: $medium;
    color: $gray-400;
    position: absolute;
    right: 0;
    top: 36px;
    user-select: none;
}

.rc-slider-track {
    background-color: $primary-blue;
}

.rc-slider-handle {
    background-color: $primary-blue;
    border: solid 2px $primary-blue;
}
