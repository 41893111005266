.selectorBlock {
}

.selector {
  position: relative;
  min-width: 330px;
  width: max-content;

  &_button {
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    border: 1px solid rgba(255, 255, 255, 0.8);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
    font-size: 14px;

    &_label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.droplist {
  border: 1px solid #d8d8d8;
  border-radius: 0.25rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 5px -1px #c6cad1;
  position: absolute;
  top: 2.5rem;
  width: 100%;
  z-index: 1;
  overflow: hidden;

  & li {
    padding: 0.75rem;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background: #f9f9f9;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
.current {
  background: #f9f9f9;
}
