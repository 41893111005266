.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addFilter {
  background: #006b8f !important;
  color: #ffffff;

  &:hover {
    background: #00455c !important;
  }

  &_layout {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.filterWrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 125px;
  gap: 0.5rem;

  &_button {
    background-color: #ef4446;
    color: #ffffff;
    font-size: 12px;
    &:hover {
      background-color: #dc2626;
    }
    /*     position: absolute;
    left: -17px;
    top: 0px;
    bottom: 0; */
    font-size: 14px;
  }
}
.filterWrapperIsStatusFields {
  @extend .filterWrapper;
  grid-template-columns: 1fr;
}

.filter {
  display: grid;
  grid-template-columns: 210px 1fr;
  align-items: center;
  gap: 0.25rem;
}
.filterIsStatusField {
  @extend .filter;
  grid-template-columns: 1fr;
}

.count {
  color: #0099cc;
  font-size: 20px;
}

.filteringBy {
  margin: 1rem 0;
}

.field {
  font-weight: 700;
}

.paramLine {
  margin: 1rem 0;
}

.options {
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

.selectionWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.item {
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  background: #dcf1fa;
  color: #0099cc;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.true {
  background: #99cc00;
  color: #fff;
}

.false {
  background: #ee4266;
  color: #fff;
}
.heading {
  font-size: 1.125rem !important;
  font-weight: 600 !important;
}

.stage {
  @extend .heading;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.caution {
  margin-top: 1rem !important;
  font-size: 0.8rem;
  line-height: 1.3rem;
  color: #ff5758;
}

.language {
  text-transform: capitalize;
}
