.array-input__wrapper {

}

.array-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    // Plain icon using an unstyled button as onClick wrapper
    button {
        appearance: none;
        border: none;
        background: none;
        cursor: pointer;
        padding: 16px;
    }
}

.array-input__add .button .icon {
    background: red;
    display: inline-block;
}

.array-input__add {
    .icon {
        margin-right: 4px;
    }
}
