.dashboard--add-school__card {
  width: 100%;
  max-width: 1024px;
  padding: 40px;
  background-color: $white;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  & > form {
    max-width: 480px;
  }
  h4 {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .dashboard--add-school__card--heading {
    margin-bottom: 24px;
    color: $gray-400;
    font-size: 18px;
  }
  .dashboard--add-school__card--school-type,
  .dashboard--add-school__card--grades-served,
  .dashboard--add-school__card--schola-rating,
  .dashboard--add-school__card--school-info {
    margin-bottom: 48px;
    .geosuggest__label {
      display: block;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    .geosuggest__item {
      padding: 16px;
      line-height: 1.25;
    }
    .geosuggest__suggests {
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    }
    .geosuggest__suggests-wrapper {
      background-color: #fff;
      box-shadow: none !important;
      margin-bottom: 0;
    }
    .geosuggest__suggests--hidden {
      display: none;
    }
    .button {
      height: 56px;
    }
  }
}
.dashboard--add-school__card--school-type {
  width: 480px;
  .dashboard--add-school__card--school-type--inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .dashboard--add-school__card--school-type--option {
      width: 72px;
      margin-right: 16px;
      label {
        height: 100%;
        transition: all 0.5s;
        border-radius: $card-border-radius;
        text-transform: capitalize;
        &:hover {
          background: #f1f1f1;
          cursor: pointer;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .dashboard--add-school__card--school-type--option {
    & > label {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      font-weight: $medium;
      font-size: 14px;
      .lable-info {
        margin: 5px auto;
        max-width: 100%;
        word-wrap: break-word;
      }
      .icon {
        margin-bottom: 4px;
      }
      input {
        margin-top: 8px;
      }
    }
  }
}
.dashboard--add-school__card--schola-rating {
  .dropdown {
    max-width: 96px;
  }
}
