.th-resize-div{    
    top:0;
    right:0;
    width:7px;
    position:absolute;
    cursor: col-resize;
    user-select: none;
    height: 40px;
    padding-right:18px;
    background-repeat:no-repeat;
    background-position-y:bottom;
    background-image:url("https://scholamatch-static.s3-us-west-2.amazonaws.com/resize-icon.png");       
}

