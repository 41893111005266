@import '../../../sass/color-palette';

// Global Button styles
button {
    font-family: inherit;
}
.button--display--block {
    display: block;
    width: 100%;
}
.button--display--block-mobile {
    @media (max-width: 480px) {
        display: block;
        width: 100%;
    }
}

.children-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        margin-right: 5px;
    }
}
/* .children {
    svg {
        display: none;
    }
} */

@mixin buttonSize($size) {

    .button-#{$size}{
        font-weight: 600;
		border-radius: 0.2rem;
        transition: all .25s ease-out;

        @if $size == large {
            font-size: 18px;
			padding:14px 30px;
            svg {
                height: 16px;
            }
        }

        @else if $size == medium {
            font-size: 16px;
            padding: 12px 16px;
            svg {
                height: 12px;
            }
        }
        @else if $size == small {
            font-size: 12px;
            padding: 8px 14px;
            svg {
                height: 10px;
            }
        }
        @else {
            font-size: 16px;
            padding: 12px 16px;
            svg {
                height: 12px;
            }
        }
		svg.carbon {
			height: 100%;
		}
        &.active {
            box-shadow: none;
        }
        &.button--disabled {
            color: $dark-blue-25 !important;
            background-color: $dark-blue-05 !important;
            cursor:not-allowed;
        
            &:hover {
                color: $dark-blue-25 !important;
                background-color: $dark-blue-05 !important;
                cursor: not-allowed !important;
                border:none !important;
            }
        }
    }
}

@mixin buttonTheme( $theme, $color ) {
    .button-#{$theme}-#{$color} {
		// blue legacy or primary
		// contained blue
		&:focus {
			outline: none;
		}
		@if $theme == "contained" and $color == "bluelegacy" {
			border: none !important;
			color: white !important;
			background-color:$primary !important;
			&:focus {
				border: none !important;
				color: white !important;
				background-color:$primary !important;
			}
		}
		// outlined blue
		@if $theme == "outlined" and $color == "bluelegacy" {
			border: 1px solid $primary !important;
			color: $primary !important;
			background-color:white;
			&:focus {
				background-color: #ffffff;
				border: 1px solid $primary;
				box-shadow: 0 0  0 2 #008cff25;
			}
		}
		// transparent blue
		@if $theme == "transparent" and $color == "bluelegacy" {
			border: none !important;
			color: $primary !important;
			background-color:transparent !important;
			padding: 0 !important;&:focus {
				background-color: #ffffff;
				border: 1px solid $primary;
				box-shadow: 0 0  0 2 #008cff25;
			}
		}
		@if $theme == "contained" and $color == "primary" {
			border: none !important;
			color: white !important;
			background-color:$primary !important;
			&:focus {
				border: none !important;
				color: white !important;
				background-color:$primary !important;
			}
		}
		// outlined blue
		@if $theme == "outlined" and $color == "primary" {
			border: 1px solid $primary !important;
			color: $primary !important;
			background-color:white;
			&:focus {
				background-color: #ffffff;
				border: 1px solid $primary;
				box-shadow: 0 0  0 2 #008cff25;
			}
		}
		// transparent blue
		@if $theme == "transparent" and $color == "primary" {
			border: none !important;
			color: $primary !important;
			background-color:transparent !important;
			padding: 0 !important;
			&:focus {
				background-color: #ffffff;
				border: 1px solid $primary;
				box-shadow: 0 0  0 2 #008cff25;
			}
		}
		// contained green
		@if $theme == "contained" and $color == "greenlegacy"  {
			border: none !important;
			color: white !important;
			background-color:$green-legacy !important;
			&:focus {
				border: none !important;
				color: white !important;
				background-color:$green-legacy !important;
			}
		}
		// outlined green
		@if $theme == "outlined" and $color == "greenlegacy"  {
			border: 1px solid $green-legacy !important;
			color: $green-legacy !important;
			background-color:white;
			&:focus {
				background-color: #ffffff;
				border: 1px solid $green-legacy;
				box-shadow: 0 0  0 2 #008cff25;
			}
		}
		// transparent green
		@if $theme == "transparent" and $color == "greenlegacy"  {
			border: none !important;
			color: $green-legacy !important;
			background-color:transparent !important;
			padding: 0 !important;
			&:focus {
				background-color: #ffffff;
				border: 1px solid $green-legacy;
				box-shadow: 0 0  0 2 #008cff25;
			}
		}
		// contained secondary
		@if $theme == "contained" and $color == "secondary" {
			color: white!important;
			background-color:$green-legacy;
			&:hover {
				background-color: $green-legacy;
			}
			&:focus {
				color: white!important;
				background-color:$green-legacy;
			}
		}
		// outlined secondary
		@if $theme == "outlined" and $color == "secondary"  {
			border: 1px solid $green-legacy !important;
			color: $green-legacy !important;
			background-color:white;
			&:hover {
				background-color: rgba(6, 69, 173, .1);
			}
			&:focus {
				background-color: #ffffff;
				border: 1px solid $green-legacy;
			}
			&.button--disabled {
				border: none;
			}
		}
		// transparent secondary
		@if $theme == "transparent" and $color == "secondary"  {
			border: none !important;
			color: $green-legacy !important;
			background-color:transparent !important;
			&:focus {
				border: none !important;
				color: $green-legacy !important;
				background-color:transparent !important;
			}
		}
		// contained redlegacy
		@if $theme == "contained" and $color == "redlegacy" {
			border: none !important;
			color: white!important;
			background-color:$red-legacy;
			&:focus {
				border: none !important;
				color: white!important;
				background-color:$red-legacy;
			}
		}
		// outlined redlegacy
		@if $theme == "outlined" and $color == "redlegacy"  {
			border: 1px solid $red-legacy !important;
			color: $red-legacy !important;
			background-color:white;
			&:focus {
				background-color: #ffffff;
				border: 1px solid $red-legacy;
				box-shadow: 0 0  0 2 #008cff25;
			}
		}
		// transparent redlegacy
		@if $theme == "transparent" and $color == "redlegacy"  {
			border: none !important;
			color: $red-legacy !important;
			background-color:transparent !important;
			&:focus {
				background-color: #ffffff;
				border: 1px solid $red-legacy;
				box-shadow: 0 0  0 2 #008cff25;
			}
		}
		// contained red
		@if $theme == "contained" and $color == "red" {
			border: none !important;
			color: white!important;
			background-color:$red-legacy;
			&:focus {
				border: none !important;
				color: white!important;
				background-color:$red-legacy;
			}
		}
		// outlined red
		@if $theme == "outlined" and $color == "red"  {
			border: 1px solid $red-legacy !important;
			color: $red-legacy !important;
			background-color:white;
			&:focus {
				border: 1px solid $red-legacy !important;
				color: $red-legacy !important;
				background-color:white;
			}
		}
		// transparent red
		@if $theme == "transparent" and $color == "red"  {
			border: none !important;
			color: $red-legacy !important;
			background-color:transparent !important;
			&:focus {
				border: none !important;
				color: $red-legacy !important;
				background-color:transparent !important;
			}
		}
		// contained orange
		@if $theme == "contained" and $color == "orange" {
			border: none !important;
			color: white!important;
			background-color:#fda353;
			&:focus {
				border: none !important;
				color: white!important;
				background-color:#fda353;
			}
		}
		// outlined orange
		@if $theme == "outlined" and $color == "orange"  {
			border: 1px solid #fda353!important;
			color: $red-legacy !important;
			background-color:white;
			&:focus {
				border: 1px solid #fda353 !important;
				color: $red-legacy !important;
				background-color:white;
			}
		}
		// transparent orange
		@if $theme == "transparent" and $color == "orange"  {
			border: none !important;
			color: #95a2ff !important;
			background-color:transparent !important;
			&:focus {
				border: none !important;
				color: #95a2ff !important;
				background-color:transparent !important;
			}
		}
		// contained purple
		@if $theme == "contained" and $color == "purple" {
			border: none !important;
			color: white!important;
			background-color:#95a2ff;
			&:focus {
				border: none !important;
				color: white!important;
				background-color:#95a2ff;
			}
		}
		// outlined purple
		@if $theme == "outlined" and $color == "purple"  {
			border: 1px solid #95a2ff!important;
			color: $red-legacy !important;
			background-color:white;
			&:focus {
				border: 1px solid #95a2ff !important;
				color: $red-legacy !important;
				background-color:white;
			}
		}
		// transparent purple
		@if $theme == "transparent" and $color == "purple"  {
			border: none !important;
			color: #95a2ff !important;
			background-color:transparent !important;
			&:focus {
				border: none !important;
				color: #95a2ff !important;
				background-color:transparent !important;
			}
		}
	}
}

@include buttonSize('small');
@include buttonSize('medium');
@include buttonSize('large');

@include buttonTheme('contained','bluelegacy');
@include buttonTheme('outlined','bluelegacy');
@include buttonTheme('transparent','bluelegacy');

@include buttonTheme('contained','primary');
@include buttonTheme('outlined','primary');
@include buttonTheme('transparent','primary');

@include buttonTheme('contained','greenlegacy');
@include buttonTheme('outlined','greenlegacy');
@include buttonTheme('transparent','greenlegacy');

@include buttonTheme('contained','secondary');
@include buttonTheme('outlined','secondary');
@include buttonTheme('transparent','secondary');

@include buttonTheme('contained','redlegacy');
@include buttonTheme('outlined','redlegacy');
@include buttonTheme('transparent','redlegacy');

@include buttonTheme('contained','red');
@include buttonTheme('outlined','red');
@include buttonTheme('transparent','red');

@include buttonTheme('contained','orange');
@include buttonTheme('outlined','orange');
@include buttonTheme('transparent','orange');

@include buttonTheme('contained','purple');
@include buttonTheme('outlined','purple');
@include buttonTheme('transparent','purple');