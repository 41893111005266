.layout {
  padding-top: 1rem;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  & svg {
    fill: rgba(0, 107, 143, 1);
  }
}

.label {
  display: flex;
  align-items: center;
  gap: 0.35rem;
  font-weight: 600;
}

.link {
  &:hover {
    text-decoration: none;
  }
}

.notification {
  font-size: 12px;
  background: #c11f3f;
  color: #fff;
  padding: 4px 8px;
  border-radius: 100px;
}
