.save-status {
	left: 0;
	right: 0;
	color: #fff;
	font-size: 14px;
	height: 30px;
	text-align: center;
	font-weight: 600;
	padding: 8px;
	position: fixed;
	background-color: #6c757d;
	transform: translateY(-30px);
	box-shadow: 0px 0px 10px 1px rgba(0,0,0,.3);
	transition: all .1s ease-in-out;
	opacity: 0;
	z-index: 999;
}
.save-status.show {
	top: 0;
	opacity: 1;
	transition-delay: .1s;
	transform: translateY(0);
}
.save-status.show.saved {
	transition-delay: .1s;
	background-color: $secondary;
}

.save-status.show.failed {
	transition-delay: .1s;
	background-color: #f4364c;
}

.school-feature__section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 660px;
    margin-bottom : 40px;
}

.school-feature__section__header {
    width: 100%;
    margin-bottom : 36px;
}

.school-feature__question {
    width: 45%;
    margin-bottom: 20px;

    &:nth-child(even) {
        margin-right: 10%;
    }
}

.school-feature__question label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    font-weight: $medium;
    margin-bottom: 4px;
    font-size: 14px;
    .icon {
        width: 20px;
        height: 20px;
    }
}

.school-feature__question--description {
    max-width: 200px;
    color: $white;
}

// TODO: Move to global select styling, holding off for now
// because I don't know how it will affect other pages
.school-feature__question select {
    width: 100%;
}

.__react_component_tooltip.show {
    opacity: 1 !important;
    border: none;
}

.__react_component_tooltip.type-light.border {
    border: none !important;
}

.__react_component_tooltip.type-light.border {
    background: $schola-purple;
}

.__react_component_tooltip.type-light.border.place-top:before {
    border-top: 8px solid $schola-purple !important;
}

.__react_component_tooltip.type-light.place-top:after {
    border: none !important;
}

.section-title {
    color: #0299D3;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 17px;
}
.right-content{
    width: 34%;
    float: right;
}
.is-top {
    margin-top: 10px;
    width: 80%;
    display: inline-block !important;

    input[type="checkbox"]  {
        display: none;
    }

    .checkbox-icon {
        display: inline-block;
        line-height: 14px !important;
        .icon {
            width: 14px;
            height: 14px;
        }
    }
    .checkbox-label{
        font-size: 14px;
        font-weight: 600;
        position: relative;
        line-height: 14px;
        //left -220px;  
    }
}
.is-top2 {
    display inline-block
    .icon {
        width: 14px;
        height: 14px;
    }
}
.connect-school-form {
	.section-title {
		color: #44464b !important;
	}
	.form-content {
		padding: 25px;
		border-radius: 3.5px;
		background-color: #fff;
		-webkit-box-shadow: 0 9px 12px 1px rgba(0,0,0,0.02), 0 3px 16px 2px rgba(0,0,0,0.12), 0 5px 6px -3px rgba(0,0,0,0.2);
		box-shadow: 0 9px 12px 1px rgba(0,0,0,0.02), 0 3px 16px 2px rgba(0,0,0,0.12), 0 5px 6px -3px rgba(0,0,0,0.2);
		.buttons-container {
			text-align: center;
			padding-top: 10px;
			.theme-button {
				font-size: 12px;
				width: 100%;
				margin: 0 auto 20px;
				display: block;
				&:last-child {
					margin-bottom: 0;
				}
				&.disabled {
					opacity: 0.5;
					cursor: not-allowed;
				}
			}
		}
	}
}
@media (min-width: 992px) {
    .connect-school-form{
        top: 80px;
        position: -webkit-sticky;
        position: sticky;
    }
}