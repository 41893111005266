.socialMediaGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: .75rem;
}

.socialMediaInput {
  width: 100%;
  display: flex;
  align-items: center;
  gap: .5rem;

  & svg {
    font-size: 1rem;
  }
}