// *******************************************
// *******************************************

// Base icon styles

// *******************************************
// *******************************************

.icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: transparent;
    fill: $black;
    pointer-events: none;

    svg {
        width: 100%;
        height: 100%;
    }
}

// Sizes
.icon--xs {
  width: 7px;
  height: 7px;
}
.icon--xsmall {
  width: 12px;
  height: 12px;
}

.icon--small {
  width: 16px;
  height: 16px;
}

.icon--medium {
  width: 24px;
  height: 24px;
}

.icon--large {
  width: 32px;
  height: 32px;
}

.icon--xlarge {
  width: 40px;
  height: 40px;
}

// Rotation
.icon--rotate-45 {
  transform: rotate(45deg);
}

.icon--rotate-90 {
  transform: rotate(90deg);
}

.icon--rotate-180 {
  transform: rotate(180deg);
}

.icon--rotate-270 {
  transform: rotate(270deg);
}

// Colors
.icon--primary {
  fill: $primary;
}

.icon--secondary {
  fill: $secondary;
}

.icon--dark {
  fill: $schola-gray;
}

.icon--light {
   fill: $schola-lightgrey;
}

.icon--mist {
   fill: $schola-mist;
}

.icon--black {
  fill: $black;
}

.icon--white {
  fill: $white;
}
