@use "sass:map";
@use "sass:math";
@import 'color-palette';

@mixin theme-aware($key, $color) {
    @each $theme-name, $theme-color in $themes {
        .theme-#{$theme-name} & {
            #{$key}: map-get(map-get($themes, $theme-name), $color) !important;
        }
    }
}

// mixin receibe rule property and valud like pixel for return property like rem
@mixin toRem($property, $value ) {
    $remvalue : math.div($value, 16);
    #{$property}: $remvalue+rem ;
}

@mixin panel {
    background-color: #fff;
    border-radius: 16px;
    overflow: hidden;
}
@mixin panel6px {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 11px 0 $dark-blue-25;
    overflow: hidden;
}

@mixin planHeader() {
    text-align: center;
    .plan-name{
        font-size:20px;
        margin-top:47px;
        margin-bottom:26px;
        font-weight:700;
    }
    .plan-price{
        font-size: 48px;
        margin-bottom: 8px;
        @include theme-aware('color', 'primary');
        font-weight: 600;
    }
    .plan-caption{
       font-size:14px;
       margin-bottom:20px;
        @include theme-aware('color', 'dark-blue-50');
    }
    .plan-caption-annually {
        @include theme-aware('color', 'dark-blue-75');
        font-size: 12px;
        margin-bottom: 24px;
        font-weight: 400;
    }
}
@mixin lead_vignette($color){
    width: .5rem;
    height: .5rem;
    border-radius: 2px;
    background-color: $color;
}
@mixin option_type {
    display:flex;
    gap: 1rem;
    align-items: center;
    padding:1rem;
    width: 100%;
    background-color: #ffffff;
    cursor:pointer;
}

//Override colors from schola-components (new colors) temps
.button-small-primary,
.button-medium-primary,
.button-large-primary {
    @include theme-aware('background-color', 'primary');
}

p.font-14-dark-blue-50 {
    font-size: 14px !important;
    @include theme-aware('color', 'dark-blue-50');
    margin-bottom: 0;
}
p.font-14-dark-blue-75 {
    font-size: 14px !important;
    @include theme-aware('color', 'dark-blue-75');
    margin-bottom: 0;
}
.switch .slider,
.switch input:checked + .slider {
    @include theme-aware('background', 'dark-blue-100');
}