
h2 {
    &.title {

      margin-bottom: 72px;
      font-size: 32px;
      font-weight:700;
      text-align: center;
      @media (min-width: 768px) {
        font-size: 28px;
      }
  }
}


.gridWrapper {
  display: grid;
  grid-template-columns: 140px 1fr;
  
  @media (min-width: 768px) {
    grid-template-columns: 240px 1fr;
  }
}
.featuresRow {
  display: grid;
  grid-template-rows: 110px repeat(8, 100px);
}

.itemsGrid {
  display: grid;
  grid-template-columns: repeat(3, 80%);
  grid-template-rows: 110px repeat(8, 100px);
  overflow-x: scroll;
  scroll-snap-type: x mandatory;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    overflow-x: auto;
  }  
}

.item {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: grid;
  place-items: center;

  @media screen and (min-width: 525px) {
    font-size: 14px;
    padding: 0 .25rem;
  }
}

.background {
  background: rgba(231, 239, 242, 0.48);
}


.planName {
  border-radius: 6px 6px 0px 0px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  padding: 0 .25rem;
  height: 100%;
  display: grid;
  place-items: center;
  scroll-snap-align: center;

  & img {
    width: 80%;
  }
  
  @media screen and (min-width: 525px) {
    font-size: 20px;
    line-height: 28px;
    padding: 1rem .25rem;

    & img {
      width: 70%;
    }
  }
}

.featureName {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: grid;
  place-items: center;

  @media screen and (min-width: 525px) {
    font-size: 18px;
    line-height: 26px;
  }
}

.claim_button {
  background: #006B8F !important;
  border-radius: 8px;
  margin: 1rem 0.2rem;
}

.scheduleContainer {
  display: flex;
  justify-content: center;
  margin-top: 2.2rem;
}

.legend {
  font-weight: 400;
  font-size: 12px;
  line-height: 170%;
  text-align: center;
  color: #5F6A7D;
}