@use '../themes';
@import '../color-palette';
@import '../variables';

.schola-table {
  width: 100%;
  background: $white;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 2px;
  /* box-shadow: $card-box-shadow; */
  table-layout: fixed;

  tr:first-child th:first-child {
    border-top-left-radius: 4px;
  }

  tr:first-child th:last-child {
    border-top-right-radius: 4px;
  }

  thead {
    background: themes.$primary;
    font-weight: $medium;
    color: $white;
    font-size: 14px;
    text-transform: uppercase;
  }

  thead tr th {
    padding: 20px 16px;
    text-align: left;

    &:first-child {
      padding: 16px 16px 16px 32px;
    }
  }

  tbody tr td {
    padding: 16px 16px;
    line-height: 24px;

    &:first-child {
      padding: 16px 16px 16px 32px;
    }
  }

  tfoot tr td {
    padding: 20px 32px;
    text-align: right;
  }

  th,
  td {
    vertical-align: middle;
    word-wrap: break-word;
  }

  tbody tr {
    border-bottom: solid 1px $gray-200;
  }

  tbody tr:hover {
    background-color: #f5f5f5;
  }
}

.checkbox-column {
  width: 48px;
}
