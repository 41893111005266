// icons
.iconContainer {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

// icon color prop
.blue {
  background: #e6f7ff;
  color: #1890ff;
}

.green {
  background: #f0fdf4;
  color: #1ba34a;
}

.purple {
  background: #faf5ff;
  color: #9333ea;
}

// size prop
.default {
  width: 4rem;
  height: 4rem;

  .icon {
    font-size: 1.5rem;
  }
}

.small {
  width: 2.5rem;
  height: 2.5rem;

  .icon {
    font-size: 1rem;
  }
}
