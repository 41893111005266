//------ selector styles
.selector {
  position: relative;
  width: auto;
  cursor: pointer;
}

.display {
  font-size: 14px;
  border: 1px solid #c6cad1;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.active {
  border: 1px solid #0099cc;
}

.placeholder {
  padding: 0.5rem;
}

.icon {
  padding: 0.5rem;
}

.droplist {
  border: 1px solid #d8d8d8;
  border-radius: 0.25rem;
  overflow: hidden;
  position: absolute;
  background: #fff;
  box-shadow: 0px 0px 5px -1px rgba(198, 202, 209, 1);
  min-width: 100%;
  z-index: 10;
  top: 45px;
  max-height: 370px;
  overflow-y: auto;
}

.item {
  font-size: 14px;
  display: flex;
  align-items: center;

  &:hover {
    background: #f9f9f9;
  }

  &_radio {
    opacity: 0;
    width: 0;
  }
}
.label {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  margin-bottom: 0 !important;
}

.check {
  color: #0099cc;
  padding-left: 0.5rem;
}

.selected {
  background: #f9f9f9;
}

.clearButton {
  font-size: 12px;
  color: #6c6d70;
  text-align: center;
  border-top: 1px solid #c6cad1;
  width: 100%;
  padding: 0.5rem 0;
}
