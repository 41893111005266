@import '../../../sass/color-palette';

/* Base */

select,
.dropdown {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;

    .dropdown-label {
        display: block;
        width: 100%;
        font-size: 14px;
        margin-bottom: 8px;
    }
}


select,
.dropdown-select {
    color: #6e717a !important;
    display: block;
    font-size: .85rem;
    line-height: 20px;
    color: inherit;
    padding: 1px 32px 1px 8px !important;
    border-radius: 3.5px !important;
    border: 1px solid #dddfe7 ;
    background: none;
    background-repeat: no-repeat;
    background-image: url('https://scholamatch-static.s3-us-west-2.amazonaws.com/chevron-down-solid-grey.svg');
    background-size: 16px 16px;
    background-position: right 12px center;
    height: 35px !important;
    // Reset default select attributes
    appearance: none;
    // Hack for IE10+ to remove the default dropdown arrow they add
    &::-ms-expand {
        display: none;
    }
    &:focus {
        outline: none;
    width: 100%;
    }
}

// Disabled cursor on parent label and all children elements
.dropdown--disabled,
.dropdown--disabled * {
    cursor: not-allowed !important;
    opacity: 0.75;
}

.dropdown--disabled .dropdown-select {
    background: $navy-blue-05;
}

.dropdown-remove-btn {
  background-color: $primary;
  color: white;
  padding: 2px 10px;
  border-radius: 0 0 5px 5px;
  cursor: pointer;
}