@import '../../../../../../sass/variables';
@import '../../../../../../sass/color-palette';

.container {
  width: 100%;
}

.select {
  width: 180px;
}

.button {
  background-color: #006d8f;

  &:hover {
    background-color: darken(#006d8f, 5%);
  }

  color: $white;
}
