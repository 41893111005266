.layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem;
  height: 75%;

  @media screen and (min-width: 975px) {
    flex-direction: row;
    gap: 1rem;
  }
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item {
  display: flex;
  align-items: center;
  gap: 0.35rem;
  // margin-bottom: 0.5rem;

  & svg {
    width: 10px;
  }

  @media screen and (min-width: 1080px) {
    & svg {
      width: 12px;
    }
  }

  & b {
    font-weight: 700;
  }
}

.label {
  color: #00000073;
}

.hours {
  font-size: 16px !important;
  font-weight: 700;

  @media screen and (min-width: 1080px) {
    font-size: 20px !important;
  }
}
