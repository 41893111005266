.app-header {
	height: 55px;
	padding: 15px 10px 0;
	position: relative;
	z-index: 7;
	.mobile-drawer {
		display: flex;
		justify-content: flex-end;
		.mobile-drawer__menu-button {
			color: $secondary;
			font-size: 25px;
			padding: 0;
			cursor: pointer;
			border: none;
			background-color: transparent;
			&:focus {
				outline: none;
			}
		}
		.main-navbar--mobile {
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			position: fixed;
			-webkit-transform: translateX(-100%);
			transform: translateX(-100%);
			-webkit-transition: -webkit-transform 0.2s ease-in-out;
			transition: -webkit-transform 0.2s ease-in-out;
			transition: transform 0.2s ease-in-out;
			transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
			background-color: #fff;
			z-index: 9;
			&.open {
				-webkit-transform: translateX(0);
				transform: translateX(0);
				& + div {
					display: block;
				}
			}
			.admin-buttons {
				padding: 0 24px;
				.theme-button {
					width: auto !important;
					padding: 11px 16px;
				}
			}
			.menu-drawer-heading {
				padding: 15px;
				display: flex;
				justify-content: space-between;
				.close-drawer {
					color: $secondary;
					font-size: 35px;
					padding: 0;
					border: 0;
					cursor: pointer;
					background: transparent;
				}
			}
			.menu-drawer-list {
				padding: 10px 0;
				a {
					color: #fff;
					font-size: 18px;
					text-align: center;
					font-weight: 600;
					padding: 10px;
					display: block;
					text-decoration: none;
					-webkit-transition: background-color 0.2s ease-in-out;
					transition: background-color 0.2s ease-in-out;
					&:hover {
						color: #0299d3;
						background-color: #fff;
					}
				}
			}
		}
	}
	.main-navbar--desktop {
		display: none;
		.admin-buttons {
			display: inline-block;
			.theme-button {
				width: auto !important;
				padding: 11px 40px;
			}
		}
	}
	.schola-logo {
		width: 123px;
		display: block;
		img {
			max-width: 100%;
		}
	}
	&.solid-bar {
		height: 100px;
		padding-top: 0;
		@media (min-width:945px){
			height: 76px;
			padding-top: 18px;
		}
		.mobile-drawer {
			.main-navbar--buttons {
				.theme-button {
					font-size: 12px;
					font-weight: 600;
					text-align: center;
					margin-left: 15px;
					display: block;
					margin: 13px auto;
					margin-top: 24px;
					width: 100%;
                    @media(min-width: 768px){
                        width: 184px}
				}
			}
		}
	}
	.logout-button-mobile {
		margin-right: 1rem;
	}
}
@media (min-width: 992px){
    .app-header {
        .mobile-drawer {
            display: none;
        }
        .main-navbar--desktop {
            height: 40px;
            display: block;
            margin-left: 160px !important;
            .main-navbar--desktop__list {
                li {
                    a {
                        color: #fff;
                        font-size: 12px;
                        font-weight: 600;
                        text-decoration: none;
                        padding: 13px;
                        float: right;
                        position: relative;
                        &:before {
                            content: '';
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 2px;
                            display: block;
                            position: absolute;
                            opacity: 0;
                            background-color: #fff;
                            -webkit-transition: opacity 0.2s ease-in-out;
                            transition: opacity 0.2s ease-in-out;
                        }
                        &:hover {
                            &:before {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        .schola-logo {
            float: left;
            margin: initial;
        }
        &.solid-bar {
            .main-navbar--desktop {
                margin-left: 220px;
                .main-navbar--desktop__list {
                    li {
                        a {
                            float: left;
                        }
                    }
                }
                .main-navbar--buttons {
                    float: right;
                    .theme-button {
                        font-size: 12px;
                        font-weight: 600;
                        text-align: center;
                        line-height: 15px;
                        width: 184px;
                        margin-left: 15px;
                    }
                    .link-blue-text {
                        a {
                            color: #09c;
                        }
                    }
                    .buttons-link {
                        margin-left: 1rem;
                        a {
                            span {
                                &.button-children {
                                    color: #fff !important;
                                }
                            }
                            &.button--theme--outline {
                                padding: 10px 16px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
.main-navbar--buttons{
    .buttons-link{
        .button--size--small{
            @media (max-width: 992px){
                width: 100% !important
            }
        }
    }
}
@media (min-width: 1200px){
    .app-header .main-navbar--desktop{
        margin-left: 220px !important;
    }
}