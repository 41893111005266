
.campaign-estimator-page {

    .ace--body__admin {
        width: 100%;
        margin: 0;
        text-align: left;

        p {
            font-size: 16px;
        }
    }

    .campaign-form {
        display: block;
        margin: 0 auto;

        .input--wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .ce-number-input__wrapper {
            width: 100%;
            @media (min-width: 900px) {
                width: 160px;
            }
        }

        .dropdown {
            width: 100%;
            @media (min-width: $tablet-landscape) {
                width: 360px;
            }
        }

        .ace-form__content {
            margin: 40px 0;
        }

        .campaign-icon {
            width: 100%;
            height: auto;
            margin-bottom: 24px;
        }
    }
}

.ce-number-input input {
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    color: inherit;
    padding: 12px 16px;
    border: solid 1px #b1c1c5;
    border-radius: 2px;
    cursor: inherit;
    background: none;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: right 12px center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.campaign-results {
    width: 100%;
}

.campaign-results__top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;

    .school-header > * + * {
        margin-top: 16px;
    }

    .num-students {
        text-align: center;

        span {
            font-size: 42px;
            font-weight: $medium;
        }

        h3 {
            font-size: 20px;
        }
    }

}

// Admin is not responsive at all in the first place, and the graph component
// is not either. So it was decided with pm that at a certain breakpoint
// when things start to leak out of their containers, the graph will hide completely.
.campaign-graph {
    margin-bottom: 48px;

    @media (max-width: 1360px) {
        display: none;
    }
}

.scenario-cards__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 48px;

    .scenario-card {
        width: 455px;
        border-radius: $card-border-radius;
        box-shadow: $card-box-shadow;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: center;
        margin-bottom: 24px;
        margin-right: 24px;

        .scenario-card__left {
            padding: 24px;
            text-align: center;
            background-color: $schola-seablue;
            color: $white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            span {
                font-size: 48px;
                font-weight: $medium;
            }
        }

        .duration-longest {
            background-color: $secondary;
        }

        .chosen {
            background-color: $schola-purple;
        }



        .scenario-card__right {
            padding: 24px;
            padding-left: 16px;
            font-size: 16px;

            .chosen-duration,
            .recommended-duration {
                font-size: 20px;
                font-weight: $medium;
            }

            .chosen-duration {
                color: $schola-purple;
            }

            .recommended-duration {
                color: $secondary;
            }

            .details {

                & > * + * {
                    margin-top: 16px;
                }

                div {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                }

                .icon {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                }
            }

        }

        .scenario-card__top-right {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    .order-chosen {
        order: -2;
    }

    .order-longest {
        order : -1;
    }
}

.bottom-copy {
    width: 100%;
    max-width: 958px;
    text-align: center;
    margin-bottom: 40px;
}

.chart-tooltip {
    background: $white;
    border-radius: $card-border-radius;
    box-shadow: $card-box-shadow;
    padding: 16px;
}

.student-avail {
    margin-bottom: 64px;

    h3 {
        margin-bottom: 24px;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.studentAvailability--false {
    padding: 24px;
    line-height: 1.25;
}
