.background {
  background: rgba(230, 245, 250, 1);
  display: grid;
  place-items: center;
  padding: 1rem 0.5rem;
  text-align: center;
  border-radius: 0.25rem;
  font-size: 12px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.button {
  padding: 0.5rem 0.7rem;
  border-radius: 0.25rem;
  font-size: 0.7rem;
  font-weight: 600;

  &:hover {
    text-decoration: none;
  }
}

.upgrade {
  background: #006b8f;
  color: #ffffff;
}

.viewProfile {
  border: 1px solid #c6cad1;
  background: #fff;
  color: rgba(0, 0, 0, 0.851) !important;
}

.text {
  line-height: 1.3rem;

  & b {
    font-weight: 700;
  }
}
