@import '../../../../../sass/variables';
@import '../../../../../sass/color-palette';

$border-color: #d1d5db; // ejemplo de valor
$popover-bg: #ffffff; // ejemplo de valor
$popover-fg: #000000; // ejemplo de valor
$shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1); // ejemplo de valor
$muted-color: #e5e7eb; // ejemplo de valor

.select {
  min-width: 200px;
  height: 40px;

  :global {
    .ant-select-selector {
      height: 40px !important;
      padding: 4px 16px !important;
      border: 1px solid $dark-blue-15 !important;
      border-radius: 8px !important;
      background-color: $white !important;

      &:hover {
        border-color: $dark-blue-25 !important;
      }

      .ant-select-selection-search {
        height: 38px;

        input {
          height: 38px !important;
        }
      }

      .ant-select-selection-item {
        line-height: 32px !important;
        color: $dark-blue-100;
        font-size: 14px;
        font-weight: 500;
      }

      .ant-select-selection-placeholder {
        line-height: 32px !important;
        color: $dark-blue-50;
        font-size: 14px;
      }
    }

    .ant-select-focused {
      .ant-select-selector {
        border-color: $navy-blue-75 !important;
        box-shadow: 0 0 0 2px rgba($navy-blue-50, 0.1) !important;
      }
    }

    .ant-select-open {
      .ant-select-selector {
        border-color: $navy-blue-75 !important;
      }
    }
  }
}

.dropdown {
  padding: 4px;
  border-radius: 8px;
  border: 1px solid $dark-blue-15;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

  :global {
    .ant-select-item {
      min-height: 32px;
      padding: 8px 12px;
      border-radius: 4px;
      color: $dark-blue-100;
      font-size: 14px;
      transition: all 0.2s ease;

      &-option-selected {
        background-color: $navy-blue-05;
        color: $navy-blue-100;
        font-weight: 500;
      }

      &-option-active {
        background-color: $navy-blue-02;
      }
    }

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.icon {
  color: $dark-blue-50;
  font-size: 12px;
  transition: transform 0.2s ease;

  :global(.ant-select-open) & {
    transform: rotate(180deg);
  }
}

// Responsive styles
@media (max-width: 768px) {
  .select {
    min-width: 160px;
  }
}

// Dark mode support (if needed)
:global(.dark-theme) {
  .select {
    :global {
      .ant-select-selector {
        background-color: $dark-blue-05 !important;
        border-color: $dark-blue-25 !important;
      }

      .ant-select-selection-item {
        color: $white;
      }

      .ant-select-selection-placeholder {
        color: $dark-blue-50;
      }
    }
  }

  .dropdown {
    background-color: $dark-blue-05;
    border-color: $dark-blue-25;

    :global {
      .ant-select-item {
        color: $white;

        &-option-selected {
          background-color: rgba($navy-blue-75, 0.2);
        }

        &-option-active {
          background-color: rgba($navy-blue-75, 0.1);
        }
      }
    }
  }
}

.selectTrigger {
  width: 100%;
}

.selectContent {
  position: relative;
  z-index: 50;
  max-height: 32rem;
  min-width: 8rem;
  overflow: hidden;
  border-radius: 0.375rem;
  border: 1px solid $border-color;
  background-color: $popover-bg;
  color: $popover-fg;
  box-shadow: $shadow-md;
}

.selectLabel {
  padding: 0.375rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
}

.selectItem {
  position: relative;
  display: flex;
  width: 100%;
  cursor: default;
  user-select: none;
  align-items: center;
  border-radius: 0.375rem;
  padding: 0.375rem 0.5rem;
  font-size: 0.875rem;
  outline: none;
}

.selectItemIndicator {
  position: absolute;
  left: 0.5rem;
  display: flex;
  height: 0.875rem;
  width: 0.875rem;
  align-items: center;
  justify-content: center;
}

.selectSeparator {
  margin: 0.25rem 0;
  height: 1px;
  background-color: $muted-color;
}

.scrollButton {
  display: flex;
  cursor: default;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
}

.icon {
  height: 1rem;
  width: 1rem;
}
.ocapity50 {
  opacity: 0.5;
}
