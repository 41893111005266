.container {
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0px 1px 1px 0px #c6cad1b2;
  overflow: hidden;
}
.header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 1rem 1rem 0rem;

  svg {
    width: 12px;
    height: 12px;
  }
}
.title {
  // Body/regular;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
}

.body {
  padding: 1rem;
}

.link {
  display: flex;
  align-items: flex-end;
  gap: 0.25rem;

  &:hover {
    text-decoration: none;
  }

  .label {
    font-size: 12px;
  }
}
