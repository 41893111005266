.main-layout--terms .main-header {
    height: 76px !important;
}

.main-layout--terms .main-layout__children {
    margin-top: 24px;
    margin-bottom: 24px;
}

.terms {

  margin-top: 76px;

  h1 {
    text-align: center;
    text-decoration: underline;
  }

  h3 {
    margin-top: 10px;
  }

  p {
    margin-top: 20px;
  }

  ul {
    list-style-type: disc;
    margin-left: 18px;

    li {
      line-height: 20px;
    }
  }


}
