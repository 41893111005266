.relationship-manager {
  .head-title {
    h1 {
      margin-bottom: 1rem !important;
      @media (min-width: 768px) {
        margin-bottom: 2rem !important;
      }
    }
  }
  .create-campaign {
    @media (max-width: 480px) {
      width: unset;
    }
    span.text-create-campaign {
      @media (max-width: 512px) {
        display: none;
      }
    }
  }
  .live-head-title {
    .head-buttons {
      position: relative;
      .menu-create-upload-leads {
        width: 227px;
        height: 82px;
        background: #fff;
        box-shadow: 0 4px 12px rgba(101, 107, 118, 0.2);
        position: absolute;
        top: 45px;
        border-radius: 3.5px;
        z-index: 9;
        right: 0;
        ul {
          padding-top: 8px;
          li {
            &.item {
              display: flex;
              padding-top: 9px;
              padding-bottom: 9px;
              padding-left: 16px;
              font-size: 14px;
              i {
                color: #adb1bd;
              }
              &:hover {
                background-color: $fill-modal;
                font-weight: 600;
              }
              i,
              img {
                margin-right: 0.5rem !important;
              }
            }
          }
        }
      }
    }
  }
  /* .table-responsive {
    min-height: 300px;
    overflow-y: visible;
    overflow-y: visible;
    @media (min-width: 1024px) {
      overflow-x: visible;
      overflow-y: visible;
    }
  } */
}
.tabs-navigation {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  overflow-x: auto;
  @media (max-width: 1160px) {
    max-width: 1160px;
  }
  .tab-item {
    background-color: #ebeff4;
    font-size: 14px;
    font-weight: 500 !important;
    display: flex;
    align-items: center;
    border-radius: 4px 4px 0 0;
    padding: 12px 16px;
    padding-bottom: 8px;
    height: 42px;
    margin-right: 0.5rem;
    cursor: pointer;
    .leads-total {
      padding: 2px 4px;
      border-radius: 10px;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
    }
  }
  .tab-item-active {
    @extend .tab-item;
    background-color: #fff;
    box-shadow: 0 4px 15px rgba(101, 107, 118, 0.3);
    padding-bottom: 12px;
    height: 46px;
    cursor: default;
    p {
      font-weight: 600 !important;
    }
    &.potencial-leads {
      .leads-total {
        background: $secondary-purple;
        color: #fff;
        padding: 0 7px;
      }
    }
    &.incoming-leads {
      .leads-total {
        background: $secondary-orange;
        color: #fff;
        padding: 0 7px;
      }
    }
    &.application-sent {
      .leads-total {
        background: $secondary-yellow;
        color: #fff;
        padding: 0 7px;
      }
    }
    &.application-received {
      .leads-total {
        background: $secondary-blue;
        color: #fff;
        padding: 0 7px;
      }
    }
    &.waitlisted {
      .leads-total {
        background: $secondary-teal;
        color: #fff;
        padding: 0 7px;
      }
    }
    &.accepted {
      .leads-total {
        background: $secondary-purple2;
        color: #fff;
        padding: 0 7px;
      }
    }
    &.archived {
      .leads-total {
        background: $text-placeholder;
        color: #fff;
      }
    }
  }
  .potencial-leads {
    i {
      color: $secondary-purple;
    }
  }
  .incoming-leads {
    i {
      color: $secondary-orange;
    }
  }
  .application-sent {
    i {
      color: $secondary-yellow;
    }
  }
  .application-received {
    i {
      color: $secondary-blue;
    }
  }
  .waitlisted {
    i {
      color: $secondary-teal;
    }
  }
  .accepted {
    i {
      color: $secondary-purple2;
    }
  }
  .archived {
    i {
      color: $text-placeholder;
    }
  }
}
.head-buttons {
  .button-add-lead,
  .head-button {
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  .button-calendly-header {
    border: none !important;
    text-align: left;
    p {
      color: #6c6d70;
      font-weight: 400;
    }
    span {
      color: $text-primary;
      font-weight: 600;
    }
    i {
      margin-left: 1rem;
      font-size: 18px;
      color: $primary;
    }
  }
}
.paper {
  background: #fff;
  padding: 24px;
  min-height: 500px;
  box-shadow: 0px 4px 15px rgba(101, 107, 118, 0.1);
}
.tabs-container {
  @extend .paper;
  .tab-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 24px;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  .icon-order-columns {
    i {
      font-size: 14px;
      color: $text-placeholder;
      margin-right: 0.5rem;
      &:hover {
        color: $primary !important;
        font-weight: bold;
      }
    }
  }
  .tab-buttons {
    position: relative;
    button {
      &.edit-columns {
        margin-right: 12px;
        &:hover {
          background: $fill-blue-hover !important;
        }
        @media (max-width: 768px) {
          margin-bottom: 16px;
        }
      }
    }
    .button[disabled] {
      cursor: default !important;
    }
    @media (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .button--size--small {
      @media (max-width: 768px) {
        min-width: auto;
        text-align: left;
      }
    }
    .edit-columns-options {
      width: 187px;
      background: #fff;
      box-shadow: 0 4px 12px rgba(101, 107, 118, 0.2);
      position: absolute;
      top: 45px;
      border-radius: 3.5px;
      z-index: 9;
      left: 0;
      .checkbox-options {
        height: 313px;
        padding-top: 14px;
        overflow-y: scroll;
        .form-group {
          padding-left: 14px;
          &:hover {
            background-color: $fill-hover;
          }
          .form-check {
            display: flex;
            label {
              font-size: 14px;
              font-weight: 400;
              width: 100%;
              text-align: left;
            }
          }
        }
      }
      .filters-buttons {
        height: 45px;
        display: flex;
        justify-content: space-between;
        box-shadow: 2px 0px 6px rgba(101, 107, 118, 0.2);
        padding: 0 12px;
        button {
          font-size: 14px;
          color: $text-primary;
          font-weight: 400;
        }
      }
    }
  }
  .tab-content .table-leads,
  .tab-content .table {
    width: 100%;
    height: 100%;
  }
  .tab-content {
    .table-leads {
      border-collapse: separate;
    }
    tbody {
      tr {
        border-bottom: 1px solid #dfe4ea;
        &:hover {
          background: #f9fdff;
        }
        &.new-lead-tr {
          background-color: #e7f0f9;
        }
      }
    }
    thead {
      font-weight: 600;
      font-size: 14px;
      .checkbox-column {
        width: 30px;
      }
      .actions-column {
        width: 35px;
      }
      .actions-column.col-sticky {
        right: 0;
        position: sticky !important;
        background-color: white;
        border-left: 2px solid rgba(0, 0, 0, 0.1);
        padding-left: 12px !important;
      }
      tr {
        padding: 16px 24px;
        border-bottom: 1px solid $fill-border;
        th {
          padding: 24px 0;
          font-size: 14px;
          color: #44464b;
          &.checkbox-column {
            min-width: 40px;
          }
        }
      }
      th {
        padding: 17px 0 !important;
        border-bottom: 1px solid $fill-border;
        vertical-align: middle;
        span {
          line-height: 24px;
        }
      }
    }
    td {
      font-size: 14px;
      line-height: 16px;
      padding: 17px 0;
      overflow-wrap: anywhere;
      vertical-align: middle;
      border-bottom: 1px solid $fill-border;
    }
    // .sel-checkbox {
    //     text-align: center;
    // }
    // .sel-checkbox.new-lead {
    //     padding-top: 22px;
    //     padding-bottom: 0px;
    // }
    .pags {
      margin-top: 30px;
      justify-content: space-between !important;
      .displaying-num {
        p {
          font-size: 14px;
          color: $text-disabled;
        }
        .active {
          color: $text-primary-blue;
        }
        .total-records {
          color: $text-primary;
        }
      }
    }
  }
}
.duplicate-campaign-modal,
.modal-batch-edit,
.modal-edit-columns,
.modal-move-lead,
.modal-close-confirmation,
.modal-create-import-campaigns,
.email-layouts-modal,
.other-campaign-modal,
.modal-add-new-parent,
.modal-register-student,
.add-testimonial-modal,
.modal-export-lead,
.modal_selectapplication {
  .modal__header {
    display: none;
  }
  .modal__children {
    padding: 0;
  }
  .icon-unlock {
    background: #f2fbff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .modal-header {
    height: 70px;
  }
  .content {
    height: 437px;
    padding: 20px 24px;
  }
  .checkboxes-columns {
    .form-check {
      margin-bottom: 16px;
    }
  }
}
.modal-edit-columns {
  .content,
  .modal-body {
    height: 400px;
    overflow-y: auto;
    padding: 2rem 0;
  }
  .modal-footer {
    justify-content: flex-end;
  }
}

.modal-create-import-campaigns {
  .modal-header {
    min-height: 85px;
  }
}
/* .modal-delete-note {
	.content {
		height: 170px;
        @media (min-width: 1024px){
            width: 400px !important
        }
	}
} */
.modal-move-lead {
  .modal__content {
    @media (min-width: 1024px) {
      width: 400px !important;
    }
  }
  .content {
    height: 263px;
    .form-check {
      margin-bottom: 16px;
      input {
        margin-right: 16px;
      }
      span {
        margin-left: 10px;
      }
      .form-check-label {
        display: flex;
        align-items: center;
      }
    }
  }
}
.modal-edit-note {
  .message-name-date-options {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    padding-top: 1rem;
  }
  .modal-footer {
    box-shadow: none;
  }
}
.modal-create-import-campaigns {
  .modal-header {
    justify-content: space-around;
  }
  .modal__content {
    .content {
      height: 100%;
      min-height: 150px;
    }
    @media (min-width: 1024px) {
      width: 700px !important;
    }
  }
}
.modal-close-confirmation {
  .modal__content {
    .content {
      height: 100%;
    }
    @media (min-width: 1024px) {
      width: 480px !important;
    }
  }
  .dropdown-select {
    padding-left: 1rem;
  }
  .content {
    height: 200px;
  }
}
.modal-batch-edit {
  .modal__content {
    overflow-y: unset !important;
    width: 450px !important;
  }
  .content {
    //min-height: 200px;
    height: auto;
  }
}
.modal-export-lead {
  .modal__content {
    width: 480px !important;
  }
}
.modal_selectapplication {
  .schola-modal-child {
    padding: 0;
    .schola-modal-child-header,
    .schola-modal-child-close {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .modal-body {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}
.modal-export-lead,
.other-campaign-modal {
  .schola-modal-child {
    padding: 0;
    .modal-header {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}
.modal-export-lead {
  .schola-modal-child-header,
  .schola-modal-child-close {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .modal-body {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.form-group {
  &.radio-buttons {
    label {
      margin-left: 11px;
      font-size: 14px;
      color: $text-primary;
    }
  }
  .content {
    height: 200px;
  }
}
.actions-points {
  position: relative;
  display: flex !important;
  height: 100%;
  align-items: center;
}
.actions-points.col-sticky {
  right: 0;
  position: sticky !important;
  background-color: white;
  border-left: 2px solid rgba(0, 0, 0, 0.1);
}
.actions-content-menu {
  width: 245px;
  background: #fff;
  box-shadow: 0 4px 12px rgba(101, 107, 118, 0.2);
  position: absolute;
  border-radius: 3.5px;
  z-index: 9;
  right: 55px;
  margin-top: -1rem;
  ul {
    padding-top: 16px;
    padding-bottom: 16px;
    hr {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    li {
      &.item {
        display: flex;
        padding: 10px 16px;
        font-size: 14px;
        i {
          color: #adb1bd;
        }
        &:hover {
          background-color: $fill-modal;
          font-weight: 600;
        }
        i,
        img {
          margin-right: 16px !important;
        }
      }
    }
  }
  .content {
    height: 200px;
  }
}
.notes-options-menu {
  @extend .actions-content-menu;
  width: 140px;
  right: 0;
}

.modal-learn-importing-contacts,
.modal-create-import-campaigns {
  .modal__content,
  .schola-modal-child {
    width: 90% !important;
    border-radius: 4px;
    @media (min-width: 768px) {
      width: 700px !important;
    }
  }
  .modal__header {
    display: none;
  }
  .modal__children {
    padding: 0;
  }
  .modal-header {
    padding: 24px;
    border-bottom: 1px solid $fill-border;
  }
  .modal-footer {
    padding: 24px;
    border-top: 1px solid $fill-border;
  }
  .content {
    display: flex;
    //flex-direction: column;
    .modal-aside {
      min-width: 166px;
      padding: 24px;
      background: $fill-hover;
      border-right: 1px solid $fill-border;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        margin-bottom: 16px;
      }
      .active {
        font-size: 14px;
        color: $text-primary-blue;
        font-weight: 500;
      }
    }
    .modal-main-content {
      padding: 24px 36px;
      height: 437px;
      overflow-y: scroll;
      .upload-csv-file {
        ul {
          padding-left: 1rem;
          li {
            list-style: disc !important;
            margin-bottom: 16px;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
      button {
        border: 1px solid $primary;
        color: $primary;
        font-size: 14px;
        text-transform: capitalize;
        margin-top: 1rem;
      }
      .inputs-heads-csv {
        margin-top: 36px;
        font-size: 12px;
        border-bottom: 1px solid $fill-border;
        padding-bottom: 8px;
      }
      .inputs-csv {
        font-size: 14px;
        padding: 11px;
        align-items: center;
        border-bottom: 1px solid $fill-border;
      }
      .schola__control {
        &:hover {
          border-color: $primary !important;
        }
      }
    }
  }
}
.modal-learn-importing-contacts {
  .schola-modal-child {
    padding: 0;
    border-radius: 1.5rem;
    .schola-modal-child-header,
    .schola-modal-child-close {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
}
.rm-school-leads-warning__modal {
  .schola-modal-child {
    padding: 0;
    border-radius: 1.5rem;
    .schola-modal-child-header,
    .schola-modal-child-close {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
  .modal-content {
    border-radius: 1.5rem;
  }
}
.modal-learn-importing-contacts {
  .content {
    padding: 0;
  }
}
.rm-archive-modal {
  .modal__content {
    width: 90% !important;
    border-radius: 4px;
    @media (min-width: 768px) {
      width: 600px !important;
    }
  }
  .modal__header {
    display: none;
  }
  .modal__children {
    padding: 0;
  }
  .modal-header {
    padding: 24px;
    border-bottom: 1px solid $fill-border;
  }
  .modal-footer {
    padding: 24px;
    border-top: 1px solid $fill-border;
  }
  .content {
    padding: 10px;
  }
}
.toast-notification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: fixed !important;
  width: 300px;
  min-height: 85px;
  background: #fff;
  box-shadow: 0px 4px 12px rgba(101, 107, 118, 0.2);
  border-radius: 3.5px;
  right: 45px;
  top: 10px;
  .toast-buttom-line {
    height: 5px;
    width: 300px;
    &.success {
      background: $secondary;
    }
    &.error {
      background: #ff5757;
    }
    &.warning {
      background: #f0c52e;
    }
    &.info {
      background: $primary;
    }
  }
  .toast-icon {
    &.success {
      color: $secondary;
    }
    &.error {
      color: #ff5757;
    }
    &.warning {
      color: #f0c52e;
    }
    &.info {
      color: $primary;
    }
  }
}
.breadcrumb-create-campaign {
  @media (width: 768px) {
    margin-bottom: 24px;
  }
}

.pdf-file-icon-side-card {
  background: #e74c3c;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  width: 50px;
  height: 65px;
  text-align: center;
  vertical-align: middle;
  line-height: 45px;
}

.modal-copy-link {
  .modal__content {
    width: 480px !important;
  }
  .modal__children {
    padding: 0;
  }
  h3 {
  }
}

.rm-delete-modal {
  .modal__header {
    display: flex;
    justify-content: space-between;
  }
  .modal__children {
    padding-top: 0;
  }
  .modal__title {
    color: #212529 !important;
  }
  .modal__content {
    width: 550px !important;
  }
}
.csv-file-icon-side-card {
  background: #f1c40f;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  width: 50px;
  height: 65px;
  text-align: center;
  vertical-align: middle;
  line-height: 45px;
}

.modal-copy-link {
  .modal__content {
    width: 480px !important;
  }
  .modal__children {
    padding: 0;
  }
  h3 {
  }
}

.rm-delete-modal {
  .modal__header {
    display: flex;
    justify-content: space-between;
  }
  .modal__children {
    padding-top: 0;
  }
  .modal__title {
    color: #212529 !important;
  }
  .modal__content {
    width: 550px !important;
  }
}
.rm-toggle {
  .switch input:checked + .slider:before {
    transform: translateX(16px);
  }
}

.expiration-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  gap: 4px;

  width: 124px;
  height: 24px;

  background: #e7eff2;
  border-radius: 4px;

  //label
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #0a4b61;
  margin-left: 12px;
}

.expiration-warning {
  background-color: #feefd8;
  color: #644718;
}

.expiration-urgent {
  background-color: #fbe9ed;
  color: #560e1c;
}

.expiration-expired {
  background-color: #f1f2f3;
  color: #a3aab5;
}

.contact-badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  gap: 8px;

  width: 100px;
  height: 32px;

  /* Light/Primary/500---Text-Icon-Default-Link */

  background: #006b8f;
  border-radius: 8px;

  color: #fff;
  margin-left: 12px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.contact-expired {
  background-color: #f1f2f3;
  color: #a3aab5;
}

.contact-expired-td {
  filter: blur(6px);
}

.header-upgrade {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5px 8px 2.5px;
  gap: 8px;

  width: 75px;
  height: 24px;

  background: linear-gradient(98.54deg, #b0ffec 7%, #afd9ff 130.51%);
  border-radius: 4px;
  margin-left: 8px;
}

.badge-new {
  text-align: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  /* Navy-blue/100 */

  color: #ffffff;

  width: 43px;
  height: 20px;

  /* Navy-blue/05 */

  background: #5a97d5;
  border-radius: 6px;

  margin: 0px 16px;
}
.panel-messages {
  background: #fff;
  height: calc(100vh - 75px);
  width: 560px;
  position: fixed;
  padding: 1.5rem;
  right: 0;
  top: 75px;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0px 2px 6px rgba(101, 107, 118, 0.2);
}

.modal-leads-related {
  .modal__content {
    width: 100% !important;
    .modal__children {
      padding-left: 0px;
      padding-right: 0px;
      select {
        background-image: unset;
      }
      .btn-view {
        margin-right: 1rem;
        background-color: #0099cc;
        color: white;
      }
    }
  }
  .leads-related-school {
    font-weight: bold;
  }
  .small {
    font-size: 10px;
  }
}

.badgeScore {
  width: 30px;
  color: white;
  border-radius: 50%;
  text-align: center;
  padding: 3px;
  font-weight: 600;
  font-size: 12px;
  display: block;
  width: 25px;
  height: 25px;
  line-height: normal;
  &.a-plus {
    background-color: #00c853;
  }
  &.a {
    background-color: #4caf50;
  }
  &.a-minus {
    background-color: #81c784;
  }
  &.b-plus {
    background-color: #29b6f6;
  }
  &.b {
    background-color: #64b5f6;
  }
  &.b-minus {
    background-color: #90caf9;
  }
  &.c-plus {
    background-color: #ffd54f;
  }
  &.c {
    background-color: #ffc107;
  }
  &.c-minus {
    background-color: #ffe082;
  }
  &.d-plus {
    background-color: #ffb74d;
  }
  &.d {
    background-color: #ff9800;
  }
  &.d-minus {
    background-color: #f57c00;
  }
  &.f {
    background-color: #9e9e9e;
  }
}
.z-index-9 {
  z-index: 9;
}
