.selectorContainer {
  min-height: 33px;
  padding: .25rem .75rem;
  border: 1px solid #dddfe7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;

  
  &:focus-within {
    border: 1px solid 006B8F;    
  }


  &_buttons {
    display: flex;
    align-items: center;
    gap: .5rem;
  }
}

.chips {
  display: inline-flex;
  gap: 0.5rem;
  min-width: 90%;
  flex-wrap: wrap;

  position: relative;
  min-height: 33px;
  
}

.inputRef {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  cursor: text;
}

.chip {
  font-size: .75rem;
  margin: .25rem 0;
  border: 1px solid #E7EFF2;
  background: #F7FDFF;
  width: max-content;
  padding: .2rem;
  border-radius: .25rem;
  display: flex;
  align-items: center;
  gap: .25rem;

  position: relative;
  z-index: 10;

  & svg {
    cursor: pointer;
  }
}

.dropList {
  background: #FFFFFF;
  border: 1px solid rgba(47, 72, 113, 0.1);
  box-shadow: 0px 2px 6px rgba(33, 53, 86, 0.08);
  border-radius: 8px;
  position: absolute;
  top: 106%;
  left: 0;
  z-index: 1;
  width: 100%;

  max-height: 200px;
  overflow-y: scroll;

  & li {
    

    &:hover {
      background: #F2F5FB;
    }

    & button {
      padding: 1rem;
      cursor: pointer;
      width: 100%;
      justify-content: initial;
    }
  }
}

.arrow {
  cursor: pointer;
  height: 100%;
}