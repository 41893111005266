@use '../../../../../sass/color-palette' as palette;

.cardTableCampains {
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}
.heading {
  font-weight: 700 !important;
  margin-bottom: 1rem;
  font-size: 1.125rem !important;
}

.periodSelect {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0.5rem;
  height: 40px;

  span {
    font-size: 14px;
  }
}
.button {
  background-color: #006d8f;
  color: #ffffff;
  height: 40px;
  padding: 0.5rem 1rem;
  font-size: 14px;

  &:hover {
    background-color: darken(#006d8f, 5%);
  }
}
