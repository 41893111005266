.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.percentage {
  font-size: 19px !important;
  font-weight: 700;
}

.chip {
  border: 1px solid rgba(0, 0, 0, 0.85);
  font-size: 12px;
  font-weight: 700;
  border-radius: 12px;
  padding: 4px 14px;
}

.edit {
  display: flex;
  align-items: center;
  gap: 0.35rem;
  font-size: 10px;
}

.bar {
  margin-top: 0.5rem;
  background: rgba(153, 214, 235, 1);
  height: 8px;
  width: 100%;
  border-radius: 8px;
}

.progress {
  background: rgba(0, 0, 0, 0.85);
  border-radius: 8px;
  height: inherit;
}

.gradesContainer {
  width: 615px;
}

.grade {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(87px, 128px));
  align-items: center;
  margin: 0.5rem 0;

  justify-content: space-evenly;
  justify-items: center;
  align-items: center;

  & input {
    width: 50px !important;
    text-align: center;

    &:disabled {
      color: #dddfe7;
      cursor: not-allowed;
    }
  }

  .checkbox {
    cursor: pointer;
    accent-color: #0099cc;
    width: 1rem;
    height: 1rem;
  }
}

.currentInput {
  display: flex;
  flex-direction: column;
  align-items: center;

  .warning {
    color: #c11f3f;
    font-size: 10px;
  }
}

.asmLabel {
  display: inline-flex;
  gap: .35rem;
  & svg {
    color: rgba(0, 0, 0, 0.4);
  }
}

.tooltip {
  width: 210px;
  line-height: 1.3rem;

  & b {
    font-weight: 700;
  }
}
