@import '../../../sass/variables';

.dashboard-v2 {
  .list--header--control {
    margin-bottom: 1.5rem !important;
    button {
      &.button--color--secondary {
        max-height: 40px !important;
        padding: 10px 15px 10px 15px !important;
        margin-left: 16px !important;
      }
      &.active {
        span {
          &.button__children {
            span {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}
.dashboard {
  .dash__header {
    color: $schola-gray;
    margin: 22px auto 30px 24px;
  }
  .dash__container {
    background-color: $white;
    border-radius: $card-border-radius;
  }
  .map {
    position: relative;
    .map__header--main {
      padding: 15px 25px;
      border-bottom: 1.3px solid #dddfe7;
      position: relative;
      .panel-label {
        color: #6c6d70;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.6px;
        text-transform: uppercase;
      }
    }
    .map__divider {
      margin: 0px;
      border-top: 1px solid $schola-hr-color;
    }
    .map__header--sub {
      color: $schola-gray-sub;
      font-weight: $bold;
      font-size: 16px;
      line-height: 24px;
      padding: 0px 32px;
      overflow: auto;
      border-bottom: 1px solid $schola-hr-color;
    }
    .map__address {
      padding-top: 7px;
      @media (min-width: 992px) {
        float: left;
      }
      span {
        font-size: 12px;
        display: block;
        &:first-child {
          color: #94959a;
          font-weight: 300;
        }
        &:last-child {
          color: #6c6d70;
          font-weight: 400;
        }
      }
      .address__result {
        color: $primary;
        padding-bottom: 19px;
        font-size: 14px;
        font-weight: $medium;
        line-height: 18px;
      }
    }
    .map__miles {
      margin-top: 10px;
      margin-bottom: 15px;
      @media (min-width: 992px) {
        float: right;
        margin-top: 24px;
      }
      button {
        border-width: 1px;
      }
      .button--color--primary {
        color: #fff;
        border-color: $secondary;
        background-color: $secondary;
      }
      .btn-mile {
        font-size: 10px;
        width: 87px;
        min-width: initial;
        height: 25px;
        padding: 0;
        @media (min-width: 992px) {
          min-width: 140px;
        }
        &.button-inactive {
          color: $secondary;
          background-color: transparent;
          border-color: $secondary;
        }
      }
    }
    .map__content {
      color: $schola-gray-sub;
      display: flex;
      clear: both;
      height: 408px;
      position: relative;
      .map__filters {
        flex-basis: 28%;
        .filter-list {
          span {
            #filter__sections {
              .filter__type {
                height: 200px;
                overflow-y: scroll;
                padding: 15px 5px;
                border-bottom: 1.3px solid #dddfe7;
                position: relative;
              }
            }
          }
          .checkbox {
            .checkbox-label {
              color: #707174;
              font-size: 12px;
              font-weight: 300;
              cursor: pointer;
            }
          }
        }
        &.hidden {
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          z-index: 1000;
          width: 23%;
          background-color: #fff;
        }
      }
      .map__container {
        flex: 1 1 auto;
        .map {
          height: 100%;
        }
      }
      .map__container--inner {
        width: 100%;
      }
      .map--inner {
        height: 408px;
      }
    }
    .map-filter--hours {
      position: absolute;
      top: 9px;
      right: 8px;
      background-color: $white;
      height: 32px;
      .map-filter_link {
        line-height: 32px;
        padding: 0 8px;
        border-right: 1px solid $schola-hr-color;
        opacity: 0.88;
        font-weight: $bold;
        color: #90a4ae;
        cursor: pointer;
        &.active {
          color: $primary;
          border-bottom: 2px solid;
        }
      }
    }
  }
  .dash__list {
    background-color: $white;
    border-radius: $card-border-radius;
    margin: 16px 0;
    .list__search {
      padding: 25px;
      .list__container--main {
        .list--container {
          .list--row {
            &.header {
              .list--cell {
                font-weight: 500;
                text-transform: initial;
                color: #6c6d70;
                font-size: 11px;
                text-align: left;
                line-height: 15px;
              }
            }
          }
        }
        .no_results {
          color: #6c6d70;
          font-size: 12px;
          text-align: left;
          padding: 18px 25px;
          text-align: center;
        }
      }
    }
    .list--row {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .list--header {
      color: #6c6d70;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.6px;
      text-transform: uppercase;
      padding-bottom: 1rem;
    }
    .list__connections {
      .list__container--main {
        .list--container {
          .list--row {
            &.header {
              .list--cell {
                font-weight: 500;
                text-transform: initial;
                color: #6c6d70;
                font-size: 11px;
                text-align: left;
                line-height: 15px;
              }
            }
          }
        }
        .no_results {
          color: #6c6d70;
          font-size: 12px;
          text-align: left;
          padding: 18px 25px;
          text-align: center;
        }
      }
    }
  }
}
#filter__sections {
  .filter__type {
    .filter__type--title {
      color: #6c6d70;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }
  }
  .filter__categories {
    .filter__categories--title {
      color: #6c6d70;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }
  }
}
.connections-filter_link {
  line-height: 32px;
  padding: 0 32px;
  opacity: 0.88;
  font-weight: $bold;
  color: #90a4ae;
  cursor: pointer;
  &.active {
    color: $primary;
    border-bottom: 2px solid;
  }
}
.table-live-search-content {
  max-height: 300px;
  overflow-y: auto;
}
.table-live-search-details {
  width: 100%;
}
.list--row {
  &.header {
    padding-top: 1rem !important;
  }
  .location {
    width: 30%;
  }
  .distance {
    width: 10%;
  }
  .grade {
    width: 10%;
  }
  .top-feature {
    width: 15%;
  }
  .sub-feature {
    width: 15%;
  }
  .connect {
    width: 15%;
  }
  .days {
    width: 5%;
  }
  .price {
    width: 5%;
  }
}
