.box {
  border-radius: 2px;
  box-shadow: 0px 1px 1px 0px #c6cad1b2;
  color: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_title {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.turquoise {
  background: linear-gradient(83.37deg, #14c2c2 0%, rgba(20, 194, 194, 0.6) 100%);
}
.blue {
  background: linear-gradient(263.37deg, rgba(0, 153, 204, 0.7) 0%, #0099cc 72.4%);
}
.green {
  background: linear-gradient(263.37deg, rgba(2, 182, 116, 0.7) 0%, #02b674 72.4%);
}
